import React, { useState, useContext, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Calendar from "../calendar/components/main";
import { MobileView, isMobile, isTablet, isMobileOnly } from "react-device-detect";
import Box from '@material-ui/core/Box';
import { makeStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import CalendarIcon from '../../assets/icons/calendarDark.png';
import SteeringWheelIcon from '../../assets/icons/wheelDark.png';
import { connect } from "react-redux";
import { setPageID } from "../calendar/store/actions";
import { PAGE_INDEX } from "../calendar/components/constant";
import StateManagementContext from '../../context/StateManagementContext';
import { cancelAllTimeoutsAndIntervals } from '../../helpers/Helpers'
import { slideInDown, slideOutUp } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import { Portal } from "@material-ui/core";
import AnimateHeight from 'react-animate-height';
import DefaultCar from '../../assets/img/defaultcar.png';

const styles = {
    slideInDown: {
        animation: 'x .5s',
        animationName: Radium.keyframes(slideInDown, 'slideInDown')
    },
    slideOutUp: {
        animation: 'x .5s',
        animationName: Radium.keyframes(slideOutUp, 'slideOutUp')
    }
}

const useStyles = makeStyles(theme => ({
    paper: {
        height: 55,
        cursor: 'pointer',
    },
    dividerColor: {
        backgroundColor: '#e6e6e6',
        marginRight: 30
    },
    dividerColorOpen: {
        backgroundColor: '#e6e6e6',
        marginRight: 30
    },
    hide: {
        display: 'none'
    },
    rotate: {
        transform: 'rotate(90deg)'
    },
    items: {
        cursor: 'pointer',
        paddingTop: 8,
    },
    text: {
        fontFamily: 'bmwnextli',
        fontSize: '1rem'
    },
    subtext: {
        marginTop: -10,
        fontSize: '.9rem',
        fontFamily: 'bmwnextbo'
    },
    modelImageContainer: {
        position: "relative",
        left: 50,
        cursor: 'pointer'
    }
}));

const customStyles = {
    content: {
        width: "375px",
        height: "667px",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        outline: 'none'
    },
    overlay: { zIndex: 11 }
};

function CarPaper(props) {
    const [open, setOpen] = React.useState(false);
    const [animateOpen, setAnimateOpen] = React.useState(false);
    const [calendarIsOpen, setIsOpen] = useState(false);
    const [showStepper, setShowStepper] = useState(false)
    const [height, setHeight] = useState(0)
    const state = useContext(StateManagementContext)
    const classes = useStyles()




    async function openCalendar() {
        state.setFetchCal(true)
        state.setHasFetchedTS(true)
        state.flowSetSingleBooking(false)
        state.flowSetUser({ id: state.currentUser.id })
        state.flowSetDepartment({ id: state.currentUser.departmentId })
        state.flowSetCar({ id: props.car.id })
        state.flowSetTestDrive(false)
        state.flowSetFinished(false)
        if (isMobile && !isTablet) {
            setShowStepper(false)
            state.flowSetUser({ id: state.currentUser.id })
            state.flowSetDepartment({ id: state.currentUser.departmentId })
            state.flowSetCar({ id: props.car.id })
            state.flowSetTestDrive(false)
            state.flowSetFinished(false)
            setIsOpen(true);
            props.openCal()
            props.onStartCalendar(null)
        } else {
            props.startFlow(false)
        }
    }

    function openDriversNote() {
        state.flowSetSingleBooking(false)
        setShowStepper(true)
        if (state.external) {
            state.flowSetUser(null)
            state.flowSetSingleBooking(false)
            state.flowSetDepartment({ id: props.car.departmentId })
        } else {
            state.flowSetUser({ id: state.currentUser.id })
            state.flowSetDepartment({ id: state.currentUser.departmentId })
        }
        state.flowSetCar({ id: props.car.id })
        state.flowSetTestDrive(true)
        state.flowSetFinished(false)
        if (isMobileOnly) {
            setIsOpen(true);
            props.openCal()
            props.onOpenDriversNote(props.car.licensePlate === "" || !props.car.isRegistered && props.car.licensePlate === "")
        } else {
            props.startFlow(true)
        }
    }

    function closeModal() {
        setIsOpen(false)
        //cancelAllTimeoutsAndIntervals()
        state.flowClear()
        state.setFetchCal(false)
        state.setHasFetchedTS(false)
        state.setChooseNewTime(false)
    }

    const MobileCalendarView = (
        <MobileView>{calendarIsOpen && <Calendar maximumStep={0} noHeightDiffer={false} showStepper={showStepper} close={closeModal} car={props.car} />}</MobileView>
    );

    const handleDrawer = () => {
        if (state.external) {
            openDriversNote()
        } else {
            setOpen(!open)
        }

    }

    React.useEffect(() => {
        if (open) {
            setHeight('auto')
        } else {
            setHeight(0)
        }
    }, [open])

    return (
        <React.Fragment>
            <Portal>
                {MobileCalendarView}
            </Portal>
            <div onClick={handleDrawer} className={classes.modelImageContainer}>
            {console.log(props.car.variantPicture)}
                {props.car.variantPicture.includes("null") ?
                    <img src={DefaultCar} alt={props.car.id} height="90" width="190" />
                    :
                    <img src={props.car.variantPicture} alt={props.car.id} height="90" width="190" />
                }

            </div>
            <div style={{ backgroundColor: 'white' }}>
                <Paper elevation={0} style={{ width: '90%', zIndex: 2 }} >
                    {props.topdivider === 1 ? <Divider classes={{ root: classes.dividerColor }} /> : null}
                    <Box display="flex" component="div" style={{ alignItems: "center" }} onClick={handleDrawer} className={classes.paper}>
                        <Box p={1} flexGrow={1} >
                            <div className={classes.text} style={props.mini ? { fontFamily: 'minireg', fontSize: 18 } : { fontFamily: 'bmwnextthin', fontSize: 18 }}>
                                {props.text}
                            </div>
                        </Box>
                    </Box>
                </Paper>
            </div>
            <div>
                <AnimateHeight
                    duration={500}
                    height={height}
                >
                    <div>
                        <Divider classes={{ root: classes.dividerColorOpen }} />
                        <Box onClick={openDriversNote} className={classes.items} display="flex" component="div" style={{ alignItems: "center" }}>
                            <Box p={2} style={{ marginTop: -4, padding: 12 }}>
                                <img alt="steeringwheel" height="24" width="24" src={SteeringWheelIcon}></img>
                            </Box>
                            <Box p={1} flexGrow={1} >
                                <div className={classes.subtext}>
                                    Opret køreseddel
                            </div>
                            </Box>
                        </Box>
                        <Divider classes={{ root: classes.dividerColorOpen }} />
                        <Box onClick={openCalendar} className={classes.items} display="flex" component="div" style={{ alignItems: "center", marginTop: 2 }}>
                            <Box p={2} style={{ marginTop: -4, padding: 12 }}>
                                <img alt="calendar" height="24" width="24" src={CalendarIcon}></img>
                            </Box>
                            <Box p={1} flexGrow={1} >
                                <div className={classes.subtext}>
                                    Åben kalender
                            </div>
                            </Box>
                        </Box>
                    </div>
                </AnimateHeight>
                <Divider classes={{ root: classes.dividerColorOpen }} />
            </div>
        </React.Fragment >
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onOpenDriversNote: (testPlate, availAbleNow) => {
            if (testPlate) {
                dispatch(setPageID(PAGE_INDEX.CHOOSE_LICENSE_PLATE))
            } else if (availAbleNow) {
                dispatch(setPageID(PAGE_INDEX.PICTURE))
            } else {
                dispatch(setPageID(PAGE_INDEX.DAY_VIEW_2_2))
            }
        },

        onStartCalendar: () => dispatch(setPageID(PAGE_INDEX.DAY_VIEW_1_2))
    };
};

export default connect(null, mapDispatchToProps)(CarPaper);