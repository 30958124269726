import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UploadImg from '../../assets/icons/upload.png';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
    span: {
        position: 'relative',
        top: -6,
        left: 12
    }
}));

export default function CustomFileUpload(props) {
    const [files, setFiles] = React.useState([])
    const classes = useStyles();

    const handleChange = (e) => {

        // get the files
        let files = e.target.files;

        // Process each file
        var allFiles = [];
        for (var i = 0; i < files.length; i++) {

            let file = files[i];

            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {

                // Make a fileInfo Object
                let fileInfo = {
                    name: file.name,
                    type: file.type,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file: file,
                };

                // Push it to the state
                allFiles.push(fileInfo);

                // If all files have been proceed
                if (allFiles.length == files.length) {
                    // Apply Callback function
                    if (props.multiple) props.onDone(allFiles);
                    else props.onDone(allFiles[0]);
                }

            } // reader.onload

        } // for

    }

    return (
        <div className={classes.root}>
            <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                type="file"
                onChange={handleChange}
                multiple={props.multiple}
                style={{cursor: 'pointer'}} 
            />
            <label style={{cursor: 'pointer'}} htmlFor="contained-button-file">
                <img style={{cursor: 'pointer'}} src={UploadImg} alt="upload" height="24" width="24"/>
                <span style={{cursor: 'pointer'}} className={classes.span}>{props.text}</span>
            </label>
        </div>
    );
}

CustomFileUpload.defaultProps = {
    multiple: false,
};