import React, { useContext } from 'react';
import CustomButton from '../../customComponents/CustomButton'
import CustomButtonTrans from '../../customComponents/CustomButtonTrans'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import CustomTextField from '../../customComponents/CustomTextField';
import DepartmentSelector from '../../utilComponents/DepartmentSelector';
import datafacade from '../../../datafacade/datafacade';
import CustomToggleButton from '../../customComponents/CustomToggleButton';
import { ToggleButtonGroup } from '@material-ui/lab';
import StateManagementContext from '../../../context/StateManagementContext';

const useStyles = makeStyles(theme => ({
    formGrid: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: -30
    },
    form: {
        width: '400px',
        '@media (max-width: 1023px)': {
            width: '70%'
        },
        '@media (max-width: 768px)': {
            width: '80%'
        },
    },
    submitButton: {
        margin: theme.spacing(4, 0),
        // width: '90%'
    },
    input: {
        // margin: theme.spacing(2, 0)
    },
}));

const StyledToggleButtonGroup = withStyles(theme => ({
    root: {
        alignItems: 'center',
        // 'margin': theme.spacing(6, 0)
    },
    grouped: {
        '&:not(:first-child)': {
            'border': '1px solid #E6E6E6',
            'width': 142,
            position: 'relative',
            left: '40px'
            // position: 'relative',
            // right: '-40px',
        },
        '&:first-child': {
            'border': '1px solid #E6E6E6',
            'width': 142,
            // position: 'relative',
            // left: '-40px'
        },
    },
}))(ToggleButtonGroup);


export default function SettingsDialog(props) {
    const classes = useStyles();
    const [role, setRole] = React.useState('admin');
    const [initError, setInitError] = React.useState(false);
    const [fnameError, setFnameError] = React.useState(false);
    const [lnameError, setLnameError] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);
    const [phoneError, setPhoneError] = React.useState(false);
    const [depError, setDepError] = React.useState(false)
    const state = useContext(StateManagementContext)
    const [user, setUser] = React.useState({
        department: { id: "" },
        initials: "",
        firstName: "",
        lastName: "",
        email: "",
        role: "admin",
        phoneNumber: "+45",
        active: true
    })

    const handleRole = (event, newRole) => {
        if (newRole !== null) {
            setRole(newRole);
            user.role = newRole
        }
    };

    const clearUser = () => {
        setUser({
            department: { id: "" },
            initials: "",
            firstName: "",
            lastName: "",
            email: "",
            role: "admin",
            phoneNumber: "+45"
        })
    }

    const handleChange = e => {
        const { name, value } = e.target
        setInitError(false)
        setFnameError(false)
        setLnameError(false)
        setEmailError(false)
        if (name === "phoneNumber") {
            let val = value
            const countryCode = "+45"
            if (value.substring(0, 3) !== countryCode) {
                val = countryCode
            }
            setUser({
                ...user,
                [name]: val
            })
        } else {
            setUser({
                ...user,
                [name]: value
            })
        }
    }


    const setDepartment = (departmentId) => {
        setUser({
            ...user,
            department: { id: departmentId }
        })
    }

    const handleSubmit = async (e) => {
        if (user.initials === "") {
            setInitError(true)
        } else if (user.firstName === "") {
            setFnameError(true)
        } else if (user.lastName === "") {
            setLnameError(true)
        } else if (user.email === "" || !user.email.includes('@')) {
            setEmailError(true)
        } else if (user.department.id === "") {
            setDepError(true)
        } else if (user.phoneNumber === "") {
            setPhoneError(true)
        } else {
            e.preventDefault()
            try {
                const userWithId = await datafacade.addNewUser(user)
                state.addUserToList(userWithId)
                props.handleClose()
                clearUser()
            } catch (error) {
                console.log("error")
            }
        }
    }

    return (
        <div>
            <Grid container spacing={8}>
                <Grid item md={4}>
                    <CustomTextField required error={initError} name="initials" fullWidth label="Initialer" value={user.initials} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField required error={fnameError} name="firstName" fullWidth label="Fornavn" value={user.firstName} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField required error={lnameError} name="lastName" fullWidth label="Efternavn" value={user.lastName} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField required error={emailError} name="email" fullWidth label="Email" value={user.email} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField required error={phoneError} name="phoneNumber" fullWidth label="Telefon" value={user.phoneNumber} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <StyledToggleButtonGroup value={role} exclusive onChange={handleRole}>
                        <CustomToggleButton value="admin">
                            Administrator
                        </CustomToggleButton>
                        <CustomToggleButton value="user">
                            Bruger
                        </CustomToggleButton>
                    </StyledToggleButtonGroup>
                </Grid>
                <Grid item md={4}>
                    <DepartmentSelector className={classes.input} departmentError={depError} setDep={setDepartment} />
                </Grid>
            </Grid>
            <Grid container spacing={4} style={{ marginTop: 50 }}>
                <Grid item md={6} style={{ textAlign: "right" }}>
                    <CustomButtonTrans onClick={props.handleClose}>
                        Fortryd
                    </CustomButtonTrans>
                </Grid>
                <Grid item md={6} style={{ textAlign: "left" }}>
                    <CustomButton onClick={handleSubmit}>
                        Opret
                    </CustomButton>
                </Grid>
            </Grid>
        </div>
    );
}