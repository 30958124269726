import React, { useRef, useEffect, useState } from 'react'
import useLibCameraPhoto from './useLibCameraPhoto'
import CloseIcon from '../../../../../assets/icons/Close48.png';
import './styles.css'

export default function Camera(props) {
    const [dataUri, setDataUri] = useState('');
    const [mounted, setMounted] = useState(false)
    const videoRef = useRef()


    const [
        mediaStream,
        cameraStartError,
        cameraStopError,
        getDataUri
    ] = useLibCameraPhoto(videoRef, props.idealFacingMode, props.idealResolution, props.isMaxResolution);

    useEffect(() => {
        if (mediaStream) {
            if(!mounted) setMounted(true)
            if (typeof props.onCameraStart === 'function') {
                props.onCameraStart(mediaStream);
            }
        } else {
            if (typeof props.onCameraStop === 'function') {
                props.onCameraStop();
            }
        }
    }, [mediaStream]);

    useEffect(() => {
        props.setInterId(setInterval(() => {
            handleTakePhoto()
        }, props.delay))
    }, [])

    function handleTakePhoto() {
        const configDataUri = {
            sizeFactor: props.sizeFactor,
            imageType: props.imageType,
            imageCompression: props.imageCompression,
            isImageMirror: props.isImageMirror
        };

        let dataUri = getDataUri(configDataUri);

        if (typeof props.onTakePhoto === 'function') {
            props.onTakePhoto(dataUri);
        }

        setDataUri(dataUri);
        // setIsShowVideo(false);
        // setIsShowVideo(true);
    }

    function closeCamera() {
        props.handleShowCamera(false)
        props.goToManual()
    }

    // useEffect(() => {
    //     if (cameraStartError) {
    //         setCameraStartDisplayError(`${cameraStartError.name} ${cameraStartError.message}`);
    //         if (typeof props.onCameraError === 'function') {
    //             props.onCameraError(cameraStartError);
    //         }
    //     }
    // }, [cameraStartError]);

    // useEffect(() => {
    //     if (cameraStopError) {
    //         printCameraInfo(cameraStopError.message);
    //     }
    // }, [cameraStopError]);


    return (
        <div className="react-html5-camera-photo react-html5-camera-photo-fullscreen">
            <video ref={videoRef} autoPlay muted="muted" playsInline></video>
            {
                //<div className="overlay"></div>
            }
            {
                mounted ? <div className="closeBtn" onClick={closeCamera}><img src={CloseIcon} alt="close" height="45" width="45"></img></div> : null
            }
        </div>
    )
}