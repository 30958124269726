import React from "react";
import CarIcon from '../../../../assets/icons/viewAppointmentIcons/car.svg'
import CalendarIcon from '../../../../assets/icons/viewAppointmentIcons/calendar.svg'
import { connect } from "react-redux";
import moment from "moment";
import { setPageID } from "../../store/actions";
import Facade from "../../../../datafacade/datafacade";
import StateManagementContext from '../../../../context/StateManagementContext';
import Field from '../subcomponents/calendar/Field';
import {
  PAGE_INDEX,
  DATE_FORMAT,
} from "../constant";
import CustomButton from '../../../customComponents/CustomButton';
import CustomButtonTrans from '../../../customComponents/CustomButtonTrans';
import BackCalendar from "../subcomponents/button/BackCalendar";

class NewAppointment extends React.Component {
  static contextType = StateManagementContext
  state = {
    start: "",
    end: "",
    subject: "",
    error: {
      start: false,
      end: false,
      subject: false,
      fetchError: false
    }
  }

  componentDidMount = () => {
    const { selectedDate, appointmentTime } = this.props
    const yr = moment(selectedDate, DATE_FORMAT).year();
    const mon = moment(selectedDate, DATE_FORMAT).month();
    const day = moment(selectedDate, DATE_FORMAT).date();
    if (Object.keys(appointmentTime).length) {
      const start = `${yr}-0${mon + 1}-${day < 10 ? "0" + day : day}T${appointmentTime.startTime.replace("-", "")}`
      const end = `${yr}-0${mon + 1}-${day < 10 ? "0" + day : day}T${appointmentTime.endTime.replace("-", "")}`
      this.setState({ start, end })
    } else {
      const start = moment().format("YYYY-MM-DDTHH:mm")
      const end = moment().add(30, "minute").format("YYYY-MM-DDTHH:mm")
      this.setState({ start, end })
    }
  }

  addAppointment = async (e) => {
    const state = this.context
    state.flowSetSingleBooking(false)
    let now = moment().valueOf() - 60000
    let start = moment(this.state.start, "YYYY-MM-DDTHH:mm").valueOf() + 1
    let end = moment(this.state.end, "YYYY-MM-DDTHH:mm").valueOf()
    if (this.state.subject === '') {
      this.setState({ error: { subject: true } })
    } else if (end <= start) {
      this.setState({ error: { fetchError: true } })
    } else if (start < now) {
      this.setState({ error: { fetchError: true } })
    } else if (end <= now) {
      this.setState({ error: { fetchError: true } })
    } else {
      state.flowSetTime(start, end)
      state.flowSetSubject(this.state.subject)
      let req = state.flow
      delete req.customer
      try {
        const booking = await Facade.addBooking(req)
        state.addBooking(booking)
        this.props.desktop ? this.props.handleClose() : this.props.gotoDayView()
      } catch (err) {
        this.setState({
          error: {
            fetchError: true
          }
        })
      }
    }
  }

  onChange = (e) => {
    const { name, value } = e.target
    this.setState({ error: { start: false, end: false, subject: false } })
    this.setState({ [name]: value })
  }

  render() {

    const HeaderView = <BackCalendar onClick={ () => {this.props.desktop ? this.props.handleClose() : this.props.gotoDayView()}} width={327} background="#E22718" title={this.state.subject === "" ? "Emne" : this.state.subject.length > 20 ? this.state.subject.substring(0, 17) + "..." : this.state.subject} />
    const { car } = this.props

    return (
      <div className="p-4">
        {HeaderView}
        <div style={{ margin: 5, marginTop: 20, paddingBottom: 10 }}>
          <textarea maxLength={145} placeholder="Emne tekst" onBlur={() => window.scroll(0, 0)} name="subject" onChange={this.onChange} value={this.state.subject} className="subject-textarea" rows={3} cols={35} />
          <Field icon={CalendarIcon} backgroundColor="#FFFFFF">
            <div style={this.state.error.fetchError ? { color: '#E22718' } : null}>Start: <input style={this.state.error.fetchError ? { color: '#E22718' } : null} onBlur={() => window.scroll(0, 0)} name="start" className="calendar-datepicker" type="datetime-local" onChange={this.onChange} value={this.state.start} /></div>
            <div style={this.state.error.fetchError ? { color: '#E22718' } : null}>Slut: <input style={this.state.error.fetchError ? { color: '#E22718' } : null} onBlur={() => window.scroll(0, 0)} name="end" className="calendar-datepicker" type="datetime-local" onChange={this.onChange} value={this.state.end} /></div>
          </Field>
          <Field icon={CarIcon} backgroundColor="#E6E6E6">
            {`${car.manufacturer} ${car.model} ${car.variant} ${car.equipment}`}
            <br />
            {car.licensePlate}
          </Field>

          <div className="calendar-btn-group">
            <div className="calendar-btn top-btn">
            <CustomButton style={{ width: 155, height: 70}} onClick={this.addAppointment}>Gem</CustomButton>
            <CustomButtonTrans style={{ width: 147, height: 70, marginLeft: 15, border: '2px solid' }} onClick={() => {this.props.desktop ? this.props.handleClose() : this.props.gotoDayView()}}>Annuller</CustomButtonTrans>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appointmentTime: state.appointmentTime,
    selectedDate: state.selectedDate,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    gotoDayView: () => dispatch(setPageID(PAGE_INDEX.DAY_VIEW_1_2))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAppointment);
