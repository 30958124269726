import React from 'react';
import CustomButton from '../components/customComponents/CustomButton';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import Logo from '../assets/img/BMW_White_Logo.svg';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CustomTextField from '../components/customComponents/CustomTextField';
import datafacade from '../datafacade/datafacade';


const styles = theme => ({
  '@global': {
    body: {
      'background': `url(https://tds.jan-nygaard.dk/td/images/internal.jpg) no-repeat center center fixed`,
      '-webkit-background-size': 'cover',
      '-moz-background-size': 'cover',
      '-o-background-size': 'cover',
      'background-size': 'cover',
      'overflow': 'hidden'
    },
  },
  logo: {
    float: "right",
    position: "absolute",
    right: 118,
    top: 25,
    '@media (max-width: 767px)': {
      top: 25,
      right: 25,
    }
  },
  subtitle: {
    '@media (max-width: 767px)': {
      marginTop: 30,
    },
  },
  paper: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#FFFFFF'
  },
  form: {
    width: '80%', // Fix IE 11 issue.
    '@media (min-width: 600px)': {
      marginTop: 50,
      width: "100%",
    },
    '@media (max-width: 767px)': {
      marginTop: 0,

      height: '70vh'
    },
    color: '#FFFFFF'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  footerLogo: {
    position: "fixed",
    bottom: 0,
    right: 0,
  },
  link: {
    color: "#FFFFFF",
  },
});

class ForgotPass extends React.Component {
  constructor(props) {
    super(props)
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      email: "",
      codeSent: false
    }
  }

  handleFormSubmit(e) {
    this.setState({ codeSent: true })
    datafacade.resetPassword(this.state.email)
    setTimeout(() => { this.props.history.replace("/login") }, 5000)
  }

  handleChange(e) {
    let nam = e.target.name;
    let val = e.target.value;
    this.setState({ [nam]: val });
  }

  handleRedirectLogin = () => {
    this.props.history.replace('/login');
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <img src={Logo} alt="Jan Nygaard AS" className={classes.logo} />
        {this.state.codeSent ?
          <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
              <Typography style={{ marginTop: 20 }} variant="h4">
                Koden er nu sendt
          </Typography>
              <form className={classes.form}>
                <Typography style={{ marginTop: 30 }} variant="subtitle1">
                  Hvis e-mailadressen findes i systemet, vil du modtage en ny kode på SMS indenfor kort tid.
                </Typography>
                <div style={{textAlign: "center", marginTop: 50}}>
                  <CustomButton
                    fullWidth
                    onClick={this.handleRedirectLogin}>Tilbage til Login</CustomButton>
                </div>
              </form>
            </div>
          </Container>
          :
          <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
              <Typography style={{ marginTop: 20 }} variant="h4">
                GLEMT PASSWORD
          </Typography>
              <form className={classes.form}>
                <div className={classes.subtitle}>
                  <Typography variant="subtitle1">
                    Skriv din e-mailadresse nedenfor. Hvis e- mailadressen findes i systemet, vil du modtage en ny kode på SMS.
              </Typography>
                </div>
                <div style={{ marginTop: 30 }}>
                  <CustomTextField
                    theme="white"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={this.handleChange}
                    value={this.state.email} />
                </div>
                <div style={{ textAlign: "center" }}>
                  <CustomButton
                    style={{ marginTop: 25 }}
                    fullWidth
                    onClick={this.handleFormSubmit}>Send kode</CustomButton>

                  <Grid container>
                    <Grid item xs style={{ marginTop: 20 }}>
                      <Link className={classes.link} href="/login" variant="body2">
                        Tilbage til login
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              </form>
            </div>
          </Container>
        }
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ForgotPass);