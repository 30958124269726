import React, { useContext, useState, useEffect } from 'react';
import CustomButton from '../customComponents/CustomButton'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography, Hidden } from '@material-ui/core';
import BackIcon from '../../assets/icons/BackGrey.png';
import CustomTextField from '../customComponents/CustomTextField';
import { connect } from "react-redux";
import { setPageID } from "../calendar/store/actions";
import { PAGE_INDEX } from "../calendar/components/constant";
import StateManagementContext from '../../context/StateManagementContext';
import Calendar from "../calendar/components/main";
import { MobileView, isMobile, isTablet, isIPad13, isMobileOnly } from "react-device-detect";
import { slideInDown, slideOutUp } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import '../calendar/components/main.css';
import moment from 'moment';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import DesktopFlow from '../desktopflow/DesktopFlow';

const styles = {
  slideInDown: {
    animation: 'x .5s',
    animationName: Radium.keyframes(slideInDown, 'slideInDown')
  },
  slideOutUp: {
    animation: 'x .5s',
    animationName: Radium.keyframes(slideOutUp, 'slideOutUp')
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '35px',
    paddingBottom: '30px'
  },
  submitButton: {
    marginTop: '15px',
    width: '260px'
  },
  input: {
    paddingTop: '32px',
    textAlign: 'center',
    margin: 'auto',
    width: '300px'
  },
  typo: {
    fontFamily: 'bmwnextthin',
    fontSize: 22
  },
  backHeader: {
    marginBottom: 20,
    padding: 26,
    marginLeft: -32,
    marginRight: -32,
    marginTop: -32,
    width: '100vw',
    boxShadow: '0px 0px 4px 2px #4F4F4F'
  },
  plateContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '22vh',
    ['@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-device-pixel-ratio: 2)']: {
      height: '15vh'
    },
    [theme.breakpoints.up(700)]: {
      height: '100%'
    },
  },
  dummybox: {
    height: 59
}
}));


function DriversNote(props) {
  const classes = useStyles();
  const [manuModelError, setManuModelError] = React.useState(false);
  const [licensePlateError, setLicensePlateError] = React.useState(false);
  const [timeError, setTimeError] = React.useState(false);
  const [calendarIsOpen, setIsOpen] = useState(false);
  const [mounted, setMounted] = useState(false)
  const state = useContext(StateManagementContext)
  const [flow, setFlow] = useState(false)
  const [endTime, setEndTime] = useState(moment().add(30, 'minutes').format("YYYY-MM-DDTHH:mm"))
  const [car, setCar] = React.useState({
    manuModel: "",
    licensePlate: "",
  })


  const handleChange = e => {
    setManuModelError(false)
    setLicensePlateError(false)
    setTimeError(false)
    const { name, value } = e.target
    setCar({
      ...car,
      [name]: value
    })
  }

  const handleTimeChange = e => {
    setManuModelError(false)
    setLicensePlateError(false)
    setTimeError(false)
    const end = moment(e.target.value, "YYYY-MM-DDTHH:mm")
    setEndTime(end)
  }


  function openDriversNote(clickedPlate) {
    let isRegistered = false
    if (car.licensePlate.length >= 7) isRegistered = true
    if (!clickedPlate) state.flowSetTestPlate(car.licensePlate)
    const c = {
      manufacturer: car.manuModel.split(" ")[0],
      model: car.manuModel.split(" ")[1],
      licensePlate: car.licensePlate,
      isRegistered: isRegistered
    }
    const start = moment().valueOf()
    const end = moment(endTime).valueOf()
    if (end <= start) {
      setTimeError(true)
    } else {
      state.flowSetUser({ id: state.currentUser.id })
      state.flowSetDepartment({ id: state.currentUser.departmentId })
      state.flowSetCar(c)
      state.flowSetTestDrive(true)
      state.flowSetFinished(false)
      state.flowSetSingleBooking(true)
      state.flowSetTime(start, end)
      if (isMobile && !isTablet) {
        setIsOpen(true);
        props.openCal()
        props.onClickAddAppointment()
      } else {
        setFlow(true)
      }
    }
  }

  function closeModal() {
    setCar({
      manuModel: "",
      licensePlate: "",
    })
    props.handleOpen(!props.open);
    state.flowClear()
    setIsOpen(false);
  }

  const MobileCalendarView = (
    <MobileView>{calendarIsOpen && <Calendar driversNote={true} noHeightDiffer={false} showStepper={true} maximumStep={1} close={closeModal} car={car} />}</MobileView>
  );

  const close = () => {
    setCar({
      manuModel: "",
      licensePlate: "",
    })
    props.handleOpen(!props.open);
  }


  const handlePassOn = (e) => {
    if (car.manuModel === '') {
      setManuModelError(true)
    } else if (car.licensePlate === '') {
      setLicensePlateError(true)
    } else {
      openDriversNote(false)
    }
  }

  const handleMounted = (setMount) => {
    if (props.openSubMenu) {
      setTimeout(() => {
        setMounted(setMount)
      }, 600)
    }
  }

  const onClickLicensePlate = (e) => {
    if (car.manuModel === '') {
      setManuModelError(true)
    } else {
      const { id } = e.target
      state.flowSetTestPlate(id)
      openDriversNote(true)
    }
  }

  useEffect(() => {
    handleMounted(true)
  }, [props.openSubMenu])


  const Plates = (
    <div className={classes.plateContainer}>
      <GridList style={{ width: 235, margin: 'auto' }} cellHeight={65} cols={2}>
        {state.plates.map((p, idx) => {
          return (
            <GridListTile style={{ marginTop: idx === 0 || idx === 1 ? 0 : -5 }} key={p} xs={6}><div id={p} onClick={onClickLicensePlate} className="plate-slot">{p}</div></GridListTile>
          )
        })}
      </GridList>
    </div>
  )

  return (
    <React.Fragment>
      {isMobile && props.open && mounted ?
        <StyleRoot>
          <div style={mounted ? styles.slideInDown : styles.slideOutUp}>
            <Grid container className={classes.backHeader}>
              <Grid item xs={3} style={{ marginTop: -6 }}>
                <img onClick={close} src={BackIcon} style={{ marginBottom: -10 }} alt={'close'} height={23} width={23} />
              </Grid>
              <Grid style={{ textAlign: 'center', marginTop: -3 }} item xs={6}>
                <Typography variant="inherit" style={{ fontFamily: 'bmwnextbo', borderBottom: '3px solid #1b69d4', paddingLeft: 10, paddingRight: 10, paddingBottom: 24, fontSize: 16 }}>Opret køreseddel</Typography>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </div>
        </StyleRoot>
        : isMobileOnly ? <div className={classes.dummybox}></div> : null}
      {(flow && isTablet) || (flow && isIPad13) ?
        <DesktopFlow isNote={true} noLicense={true} flow={() => { setFlow(false) }} handleOpen={props.handleOpen} />
        :
          <Grid container spacing={0} style={{ textAlign: "center" }}>
            <Hidden smDown>
              <Grid item xs={12}>
                <div className="progress-header">
                  <span>Opret køreseddel</span>
                </div>
                <hr style={{ borderTop: '1px solid #E6E6E6', marginLeft: 56, marginRight: 56 }} />
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Grid item xs={1}></Grid>
            </Hidden>
            <Grid item xs={12} sm={5}>
              <div style={{ paddingBottom: 16, paddingTop: isMobileOnly ? 0 : 16 }}><CustomTextField style={{ width: 280 }} onBlur={() => window.scroll(0, 0)} required type="text" error={manuModelError} name="manuModel" fullWidth label="Mærke og model" value={car.manuModel} onChange={handleChange} /></div>
              <div style={{ paddingBottom: 16, paddingTop: 16 }}><CustomTextField style={{ width: 280, marginTop: -7 }} onBlur={() => window.scroll(0, 0)} required type="text" error={licensePlateError} name="licensePlate" fullWidth label="Prøveskilt" value={car.licensePlate} onChange={handleChange} /></div>
              <div style={{ paddingBottom: 16, paddingTop: 16 }}><CustomTextField style={{ width: 280 }} error={timeError} type="datetime-local" onBlur={() => window.scroll(0, 0)} required fullWidth label="Sluttidspunkt" defaultValue={endTime} onChange={handleTimeChange} /></div>
            </Grid>
            <Grid item xs={12} sm={5}>
              <div style={{ fontFamily: 'bmwnextbo', fontSize: 18, paddingTop: 10, paddingBottom: 16 }}>Senest anvendte</div>
              {Plates}
            </Grid>
            <Hidden smDown>
              <Grid item xs={1}></Grid>
            </Hidden>
            <Grid item xs={12} style={{ marginTop: 30 }}>
              <CustomButton fullWidth onClick={handlePassOn}>
                Fortsæt
              </CustomButton>
            </Grid>
          </Grid>
      }
      <div>
        {MobileCalendarView}
      </div>
    </React.Fragment>
  );
}

// <Grid container
//           spacing={0}
//           direction="column"
//           className={classes.root}>
//           <Grid item xs={12} style={{ textAlign: 'center', marginTop: -70 }}>
//             <form noValidate autoComplete="off">
//               <div className={classes.input}>
//                 <CustomTextField style={{ width: 280 }} onBlur={() => window.scroll(0, 0)} required type="text" error={manuModelError} name="manuModel" fullWidth label="Mærke og model" value={car.manuModel} onChange={handleChange} />
//               </div>
//               <div className={classes.input}>
//                 <CustomTextField style={{ width: 280, marginTop: -7 }} onBlur={() => window.scroll(0, 0)} required type="text" error={licensePlateError} name="licensePlate" fullWidth label="Prøveskilt" value={car.licensePlate} onChange={handleChange} />
//               </div>
//               <div className={classes.input}>
//                 <CustomTextField style={{ width: 280 }} error={timeError} type="datetime-local" onBlur={() => window.scroll(0, 0)} required fullWidth label="Sluttidspunkt" defaultValue={endTime} onChange={handleTimeChange} />
//               </div>
//               <div style={{ marginTop: 40 }}>
//                 <div style={{ fontFamily: 'bmwnextbo', fontSize: 18, textAlign: 'center', marginBottom: 10 }}>Senest anvendte</div>
//                 {Plates}
//               </div>
//               <div style={{ textAlign: 'center' }}>
//                 <CustomButton className={classes.submitButton} fullWidth onClick={handlePassOn}>
//                   Fortsæt
//                 </CustomButton>
//               </div>
//             </form>
//           </Grid>
//         </Grid>

const mapDispatchToProps = dispatch => {
  return {
    onClickAddAppointment: () => dispatch(setPageID(PAGE_INDEX.TERMS_AND_CONDITIONS))
  };
};

export default connect(null, mapDispatchToProps)(DriversNote);