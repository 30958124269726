import React, { useState, useContext, useEffect } from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import CustomButton from '../../customComponents/CustomButton';
import CustomButtonTrans from '../../customComponents/CustomButtonTrans';
import StateManagementContext from '../../../context/StateManagementContext';
import Facade from '../../../datafacade/datafacade'
import { cancelAllTimeoutsAndIntervals } from '../../../helpers/Helpers';
import { isTablet, isIPad13, isBrowser } from 'react-device-detect';

export default function Time(props) {
    const [mount, setMount] = useState(false)
    const [error, setError] = useState(null)
    const state = useContext(StateManagementContext)

    const handleClose = () => {
        if (!state.external) {
            props.flow()
        }
        props.handleOpen(false)
        state.setChooseNewTime(false)
        state.flowClear()
        //cancelAllTimeoutsAndIntervals()
    }

    const fetchBooking = async () => {
        props.handleShowNav(false)
        state.setChooseNewTime(false)
        state.flowSetFinished(false)
        try {
            props.handleGoBackToFetch(false)
            let flow = state.flow
            const booking = await Facade.addBooking(flow)
            setError(false)
            if (!state.external)
                state.addBooking(booking)
        } catch (err) {
            setError(true)
            props.setHeader("Beklager")
            state.setChooseNewTime(true)
        }
    }

    const fetchTabletBooking = async () => {
        props.handleShowNav(false)
        if (!state.external) {
            state.flowSetFinished(true)
        } else {
            state.flowSetFinished(false)
        }
        if (state.flow.toFinish) {
            try {
                delete state.flow.selectedTime
                delete state.flow.toFinish
                // delete state.flow.user
                const booking = await Facade.finishBooking(state.flow)
                setError(false)
                state.addBooking(booking)
                state.flowClear()
                props.setHeader("Bekræftelse")
                state.setChooseNewTime(false)
            } catch (err) {
                setError(true)
                props.setHeader("Beklager")
                state.setChooseNewTime(true)
            }
        } else {
            try {
                const booking = await Facade.addBooking(state.flow)
                setError(false)
                state.addBooking(booking)
                state.flowClear()
                props.setHeader("Bekræftelse")
                state.setChooseNewTime(false)
            } catch (err) {
                setError(true)
                props.setHeader("Beklager")
                state.setChooseNewTime(true)
            }
        }
    }

    const onNewTime = () => {
        state.setChooseNewTime(true)
        props.setCurStep(0)
        props.handleGoBackToFetch(true)
        setError(false)
        setMount(false)
    }

    useEffect(() => {
        setTimeout(() => setMount(true), 1000)
    }, [])

    useEffect(() => {
        if (mount) {
            if (isIPad13 || isTablet) {
                fetchTabletBooking()
            } else {
                fetchBooking()
            }
        }
        // eslint-disable-next-line
    }, [mount])

    return (
        <React.Fragment>
            {error === null ?
                <Grid item xs={12} style={{ textAlign: "center", marginTop: 150 }}>
                    <CircularProgress color="secondary" />
                </Grid>
                :
                state.external ?
                    <React.Fragment>
                        <Grid item xs={12} className="desktop-header desktop-head-cal"><div className="desktop-head-cal" style={{ fontSize: 20 }}>{error ? "" : ""}<br />{error ? "Vælg venligst et andet tidspunkt" : `Din prøvekørsel er nu registreret`}</div></Grid>
                        <Grid item xs={12} className="desktop-header desktop-head-cal"><div style={{ fontFamily: 'bmwnextli', fontSize: 16 }}>{error ? "" : ["Alt er klar og det sidste vi mangler er, at aflæse dit kørekort.", <br />, "Alt det finder vi ud af når du ankommer til prøvekørslen.", <br />, <br />, "Vi glæder os til at tage imod dig!"].map((t, id) => <React.Fragment key={t + id}>{t}</React.Fragment>)}</div></Grid>
                        <Grid item xs={12}>
                            {error ?
                                <div style={{ marginTop: 30 }}>
                                    <CustomButton onClick={onNewTime}>Nyt tidspunkt</CustomButton>
                                    <div style={{ marginTop: 15 }}>
                                        <CustomButtonTrans onClick={handleClose}>Luk</CustomButtonTrans>
                                    </div>
                                </div>
                                :
                                <div style={{ marginTop: 30 }}>
                                    <CustomButton onClick={handleClose}>Luk</CustomButton>
                                </div>
                            }
                        </Grid>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Grid item xs={12} className="desktop-header desktop-head-cal"><div className="desktop-head-cal" style={{ fontSize: 20 }}>{error ? "" : ""}<br />{error ? "Det valgte tidspunkt er ikke længere ledigt." : isTablet || isIPad13 ? "" : `Prøvekørsel er nu registreret`}</div></Grid>
                        <Grid item xs={12} className="desktop-header desktop-head-cal desktop-done-text"><div style={{ fontFamily: 'bmwnextli', fontSize: 16 }}>{error ? "Vælg venligst et nyt tidspunkt" : isTablet || isIPad13 ? ["Prøvekørslen er nu oprettet og et link til køresedlen", <br />, "er sendt til det oplyste telefonnummer"].map((t, id) => <React.Fragment key={t + id}>{t}</React.Fragment>) : ["Prøvekørslen er nu gemt i bilens kalender", <br />, "og kan færdiggøres på en smartphone."].map((t, id) => <React.Fragment key={t + id}>{t}</React.Fragment>)}</div></Grid>
                        <Grid item xs={12}>
                            {error ?
                                <div style={{ marginTop: 40, paddingBottom: 100 }}>
                                    <CustomButton onClick={onNewTime}>Nyt tidspunkt</CustomButton>
                                    <div style={{ marginTop: 15 }}>
                                        <CustomButtonTrans onClick={handleClose}>Luk</CustomButtonTrans>
                                    </div>
                                </div>
                                :
                                <React.Fragment>
                                    <div style={{ marginTop: 90 }}>
                                        <CustomButton onClick={handleClose}>Luk</CustomButton>
                                    </div>
                                </React.Fragment>
                            }
                        </Grid>
                    </React.Fragment>
            }
        </React.Fragment>
    )
}