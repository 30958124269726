import React from "react";
import MainHeader from "../subcomponents/header/MainHeader";
import { setDate, setPageID } from "../../store/actions";
import moment from 'moment'
import { connect } from "react-redux";
import { PAGE_INDEX, FLOW_MODES as modes, PAGE_INDEX_EXTERNAL } from "../constant";
import { getCurDate } from "../utils";
import StateManagementContext from '../../../../context/StateManagementContext';

const SHORT_WEEKS = ["Man", "Tir", "Ons", "Tor", "Fre", "Lør", "Søn"];
const MONTHS = ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"];

class MonthView extends React.Component {
  static contextType = StateManagementContext
  state = {
    hasMore: true,
    months: [],
    dateObject: moment()
  };


  componentDidMount() {
    let months = [getCurDate().month];
    this.setState({ months: months });
  }

  checkFlowisOne() {
    const { mode } = this.props;
    return mode === modes.flow1;
  }

  weekdayshortname = () => {
    const weekdays = SHORT_WEEKS.map(day => {
      return (
        <td key={day} className="mobile-weekdays">
          {day}
        </td>
      );
    });
    return <table className="mobile-weekdays-short"><tbody><tr className="mobile-week-row">{weekdays}</tr></tbody></table>
  }

  setGlobalDate = (e) => {
    const stateContext = this.context
    const { id } = e.target
    let dateArr = id.split("-")
    let yr = dateArr[0]
    let mo = dateArr[1]
    let d = dateArr[2]
    mo++
    if (mo === 13) mo = 12
    if (mo === 0) mo = 1
    const date = moment(`${yr}-${mo}-${d}`, "YYYY-MM-DD")
    const { setDate, goToDayPageFlow1, goToDayPageFlow2, goToDayPageFlow2External } = this.props;
    setDate(date);
    if (stateContext.external) {
      goToDayPageFlow2External()
    } else {
      if (this.checkFlowisOne()) goToDayPageFlow1();
      else goToDayPageFlow2();
    }
  }

  firstDayOfMonth = (dateObject) => {
    let firstDay = moment(dateObject)
      .startOf("month")
      .format("d");
    return firstDay;
  };

  getWeeksInMonth = (dateObject) => {
    let weekIndices = [];

    let date = moment(dateObject.format("YYYY-MM-DD"), "YYYY-MM-DD")
    let date1 = moment(dateObject.format("YYYY-MM-DD"), "YYYY-MM-DD")

    let firstDayOfMonth = date1.startOf("month")


    while (firstDayOfMonth.month() === date.month()) {
      weekIndices.push(firstDayOfMonth.isoWeek())
      firstDayOfMonth.add(1, "weeks")
    }

    if (date.endOf("month").isoWeek() !== weekIndices[weekIndices.length - 1]) {
      weekIndices.push(date.endOf("month").isoWeek())
    }

    return weekIndices;
  }

  daysInMonth = (dateObject) => {
    return dateObject.daysInMonth()
  }

  createMonths = (numOfMonth) => {
    let months = []
    let dateObject = moment(this.state.dateObject, "YYYY-MM-DD")
    for (let i = 0; i < numOfMonth; i++) {
      months[i] = this.createMonth(dateObject)
      dateObject = dateObject.add(1, 'months')
    }
    return months
  }

  createMonth = (dateObject) => {
    let blanks = [];
    let firstWeekDay = this.firstDayOfMonth(dateObject)
    if (firstWeekDay === "0") {
      firstWeekDay = 7
    }
    for (let i = 1; i < firstWeekDay; i++) {
      blanks.push(
        <td key={i * 1000} className="">{""}</td>
      );
    }



    let daysInMonth = [];
    for (let d = 1; d <= this.daysInMonth(dateObject); d++) {
      let today = false
      let selected = false
      let now = moment()
      let selectedDate = moment(this.props.selectedDate, "YYYY-MM-DD")
      if (dateObject.year() === now.year() && dateObject.month() === now.month() && d === now.date()) {
        today = true
      }
      if (dateObject.year() === selectedDate.year() && dateObject.month() === selectedDate.month() && d === selectedDate.date()) {
        selected = true
      }
      daysInMonth.push(
        <td key={d} className={today ? `mobile-days mobile-today ${selected ? "mobile-selected" : ""}` : `mobile-days ${selected ? "mobile-selected" : ""}`}>
          <div id={`${dateObject.year()}-${dateObject.month()}-${d}`} onClick={(e) => { this.setGlobalDate(e) }} className={today ? "mobile-today-text" : ""}>{d}</div>
        </td>
      );
    }

    let weekNumbers = this.getWeeksInMonth(dateObject)

    var totalSlots = [...blanks, ...daysInMonth];
    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row); // if index not equal 7 that means not go to next week
      } else {
        rows.push(cells); // when reach next week we contain all td in last week to rows 
        cells = []; // empty container 
        cells.push(row); // in current loop we still push current row to new container
      }
      if (i === totalSlots.length - 1) { // when end loop we add remain date
        rows.push(cells);
      }
    });
    return (
      <div key={MONTHS[dateObject.month()]}>
        <div className="mobile-month-header">{MONTHS[dateObject.month()]}</div>
        <table className="mobile-calendar-table">
          <tbody>
            {rows.map((d, i) => {
              return (
                <React.Fragment key={212934 + i}>
                  {i > 0 ? <tr><td colSpan="7"><div key={d * i + 3432} className="mobile-weekno-line"><div key={i * d + 3232} className="mobile-weeno">{weekNumbers[i - 1]}</div></div></td></tr> : null}
                  <tr className="mobile-days-in-month" key={i}>{d}</tr>
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    )

  }


  render() {

    const HeaderView = <MainHeader title="Kalender" underline={true} />;

    return (
      <div className="p-4 vh-100 overflow">
        {HeaderView}
        {this.weekdayshortname()}
        <div className="mobile-calendar">
          {this.createMonths(12)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    mode: state.mode,
    selectedDate: state.selectedDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDate: (date) => dispatch(setDate(date)),
    goToDayPageFlow1: () => dispatch(setPageID(PAGE_INDEX.DAY_VIEW_1_2)),
    goToDayPageFlow2: () => dispatch(setPageID(PAGE_INDEX.DAY_VIEW_2_2)),
    goToDayPageFlow2External: () => dispatch(setPageID(PAGE_INDEX_EXTERNAL.DAY_VIEW_2_2)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthView);
