import React from 'react';
import CustomButton from '../components/customComponents/CustomButton';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Logo from '../assets/img/BMW_White_Logo.svg';
import Auth from '../auth/Auth';
import LinProgress from '../components/utilComponents/LinProgress';
import CustomTextField from '../components/customComponents/CustomTextField';
import { Typography } from '@material-ui/core';
import Datafacade from '../datafacade/datafacade';
import StateManagementContext from '../context/StateManagementContext';


const styles = theme => ({
  '@global': {
    body: {
      'background': `url(https://tds.jan-nygaard.dk/td/images/internal.jpg) no-repeat center center fixed`,
      '-webkit-background-size': 'cover',
      '-moz-background-size': 'cover',
      '-o-background-size': 'cover',
      'background-size': 'cover',
      'overflow': 'hidden'
    }
  },
  main: {
    '@media (max-height: 700px)': {
      marginTop: -50
    }
  },
  header: {
    marginBottom: 40
  },
  headtitle: {
    'color': '#FFFFFF'
  },
  subtitle: {
    'color': '#FFFFFF'
  },
  link: {
    color: "#FFFFFF",
  },
  logo: {
    float: "right",
    position: "absolute",
    top: 25,
    right: 118,
    '@media (max-width: 767px)': {
      top: 25,
      right: 25,
    }
  },
  paper: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    marginTop: -20,
    width: '80%', // Fix IE 11 issue.
    '@media (min-width: 700px)': {
      width: '100%'
    },
    '@media (max-width: 767px)': {
      height: '70vh'
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class Login extends React.Component {
  static contextType = StateManagementContext
    state = {
      email: "",
      password: "",
      loading: false,
      error: false
    }


  UNSAFE_componentWillMount() {
    this.Auth = new Auth();
    if (this.Auth.loggedIn())
      this.props.history.replace('/workspace');
  }

  componentDidMount = async () => {
    const state = this.context
    const cars = await Datafacade.getAllCars()
    this.sortCarsBySeries(cars)
    state.setCars(cars)
  }

  sortCarsBySeries = (cars) => {
    cars.sort((a, b) => (a.model > b.model) ? 1 : -1)
  }

  setInitialState = async (state, email) => {
    state.setHasFetched(true)
    state.setExternalLead(false)
    const user = await Datafacade.getUserDetails(email)
    state.setCurrentUser(user)
    const departments = await Datafacade.getAllDepartments()
    state.setDepartments(departments)
    const usersInDep = await Datafacade.getAllUsersFromDepartment(user.departmentId)
    state.setUsers(usersInDep)
    const plates = await Datafacade.getPlates(user.departmentId)
    state.setPlates(plates)
    this.setState({
      loading: false,
      error: false
    })
    this.props.history.replace('/workspace');
  }

  handleFormSubmit = (e) => {
    const state = this.context
    this.setState({
      loading: true,
      error: false
    })
    e.preventDefault();
    this.Auth.login(this.state.email, this.state.password)
      .then(res => {
        this.setInitialState(state, this.state.email)
      })
      .catch(err => {
        this.setState({
          loading: false,
          error: true
        })
      })
  }

  handleChange = (e) => {
    let nam = e.target.name;
    let val = e.target.value;
    this.setState({ [nam]: val });
  }


  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <img src={Logo} alt="Jan Nygaard AS" className={classes.logo} />
        {this.state.loading ? <LinProgress /> : null}
        <Container className={classes.main} component="main" maxWidth="xs">
          <div className={classes.paper}>
            <div className={classes.header}>
              <Typography className={classes.headtitle} variant="h2">
                VELKOMMEN
              </Typography>
              <Typography className={classes.subtitle} variant="h5" style={{ marginTop: 15, marginLeft: 5 }}>
                BMW Test Drive
            </Typography>
            </div>
            <form autoComplete="false" className={classes.form}>
              <CustomTextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                type="email"
                onChange={this.handleChange}
                value={this.state.email}
                theme="white"
              />
              <CustomTextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={this.handleChange}
                value={this.state.password}
                theme="white"
              />
              <Grid container style={{ marginTop: 15, textAlign: "center" }}>
                <Grid item xs={12}>
                  <Link className={classes.link} href="/forgotpassword" variant="body2">
                    <Typography variant="body2">Problemer med adgangskoden?</Typography>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ color: '#e22718', paddingTop: 20 }}>{this.state.error ? "Email eller password var forkert. Prøv igen." : '\u00A0'}</div>
                </Grid>
              </Grid>
              <div style={{ textAlign: "center" }}>
                <CustomButton
                  style={{ marginTop: 30 }}
                  fullWidth
                  onClick={this.handleFormSubmit}
                >

                  Login
            </CustomButton>
              </div>
            </form>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Login);