// export const API_URL = "http://localhost:8080/JanNygaardRest/api/"
// export const NOTE_URL = "http://localhost:8080/JanNygaardRest/testdrive?command=render&path="

// production test url
// export const API_URL = "https://tds.jan-nygaard.dk/testdrivetest/api/"
// export const NOTE_URL = "https://tds.jan-nygaard.dk/testdrivetest/testdrive?command=render&path="

// production url
export const API_URL = "https://tds.jan-nygaard.dk/testdrive/api/"
export const NOTE_URL = "https://tds.jan-nygaard.dk/testdrive/testdrive?command=render&path="

//API key should come from DB
//API Production
export const VISION_URL = "https://eu-vision.googleapis.com/v1/images:annotate?key=AIzaSyAIAGW-g2loXD9rL2s7dgcMxJmuzWu6vbQ"

export function toISOLocal(d) {
  var z = n => ('0' + n).slice(-2);
  var zz = n => ('00' + n).slice(-3);
  var off = d.getTimezoneOffset();
  var sign = off < 0 ? '+' : '-';
  off = Math.abs(off);

  return d.getFullYear() + '-'
    + z(d.getMonth() + 1) + '-' +
    z(d.getDate()) + 'T' +
    z(d.getHours()) + ':' +
    z(d.getMinutes()) + ':' +
    z(d.getSeconds()) + '.' +
    zz(d.getMilliseconds()) +
    sign + z(off / 60 | 0) + ':' + z(off % 60);
}

export function isToday(someDate) {
  const today = new Date()
  return someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
}

export function isBefore(someDate) {
  const today = new Date()
  return someDate.getTime() < today.getTime()
}

export function isNow(someDate) {
  const today = new Date()
  return someDate.getMinutes() == today.getMinutes() &&
    someDate.getHours() == today.getHours() &&
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
}

export function isBeforeOpening() {
  const today = new Date()
  const opening = new Date()
  opening.setHours(9)
  opening.setMinutes(0)
  opening.setSeconds(0)
  opening.setMilliseconds(0)
  return today.getTime() < opening.getTime()
}

export function cancelAllTimeoutsAndIntervals() {
  const maxIntervalId = setInterval(() => { })
  const maxTimeoutId = setTimeout(() => { })
  for (let i = 0; i < maxIntervalId; i++) {
    clearInterval(i)
  }
  for (let i = 0; i < maxTimeoutId; i++) {
    clearTimeout(i)
  }
}