import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2';

const makeSet = (data, labels) => {
    const d = {
        labels: labels,
        datasets: [
        ]
    };
    data.dataset.forEach(stat => {
        const set = createDataset(stat, makeColor())
        d.datasets.push(set)
    })
    return d
}

const createDataset = (stat, color) => {
    const set = {
        label: stat.label,
        fill: false,
        lineTension: 0.1,
        backgroundColor: color.backgroundColor,
        borderColor: color.borderColor,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: color.pointBorderColor,
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: color.pointHoverBackgroundColor,
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: stat.data
    }
    return set
}

const options = {
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                userCallback: function(label, index, labels) {
                    // when the floored value is the same as the value we have a whole number
                    if (Math.floor(label) === label) {
                        return label;
                    }

                },
            }
        }],
    },
    maintainAspectRatio: false
}

const makeColor = () => {
    const color = `rgba(${getRandomNum()},${getRandomNum()},${getRandomNum()},`
    const obj = {
        backgroundColor: color + "0.4)",
        borderColor: color + "1)",
        pointBorderColor: color + "1)",
        pointHoverBackgroundColor: color + "1)",
    }

    return obj
}

const getRandomNum = () => {
    return Math.floor(Math.random() * (256)) + 0
}

export default function LineChart(props) {
    const [data, setData] = useState({
        labels: [],
        datasets: []
    })

    const setState = () => {
        const d = makeSet(props.data, props.labels)
        setData(d)
    }

    useEffect(() => {
        if (props.data !== null) {
            setState()
        } else {
            setData({
                labels: [],
                datasets: []
            })
        }
    }, [props.data])

    return (
        <div>
            <Line
                width={100}
                height={300}
                options={options}
                data={data} />
        </div>
    )
}