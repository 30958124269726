import { makeStyles, Grid, CircularProgress, Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import CustomTextField from '../../customComponents/CustomTextField';
import moment from 'moment';
import facade from '../../../datafacade/datafacade';
import MultiSelector from './MultiSelector';
import LineChart from './LineChart';
import RangePicker from './RangePicker';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '30px',
        '@media (max-width: 767px)': {
            marginTop: '60px'
        },
    },
    header: {
        fontFamily: 'bmwnextthin',
        textAlign: "center",
        fontSize: 25,
    },
    mainChart: {
        paddingTop: 50,
        paddingLeft: 25,
        paddingRight: 25
    },
    loadingCirc: {
        textAlign: 'center',
        marginTop: 100
    }
}))

const prepareData = (rawData, labels, timeFormat) => {
    let statObj = {
        labels: rawData.labels,
        dataset: []
    }
    const data = []
    rawData.data.forEach(rd => {
        const numberOfTestDrives = []
        labels.forEach(label => {
            let hasTestDrive = false
            rd.stats.forEach(stat => {
                const date = moment(stat[1], timeFormat).format(formatTimeForMoment(timeFormat))
                if (date === label) {
                    numberOfTestDrives.push(stat[0])
                    hasTestDrive = true
                }
            })
            if (!hasTestDrive) {
                numberOfTestDrives.push(0)
            }
        })
        const obj = {
            label: rd.label,
            data: numberOfTestDrives
        }
        data.push(obj)
    })
    statObj.dataset = data
    return statObj
}

const formatTimeForMoment = (timeFormat) => {
    switch (timeFormat) {
        case "YYYY, MM, DD, HH, mm":
            return "HH:00";
        case "YYYY, MM, DD":
            return "DD-MM-YYYY";
        case "YYYY, MM":
            return "MM-YYYY";
        case "YYYY":
            return "YYYY";
    }
}

export default function Statistic(props) {
    const classes = useStyles()
    const [labels, setLabels] = useState([])
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()
    const [filteredData, setFilteredData] = useState({ labels: [], dataset: [] })
    const [filter, setFilter] = useState([]);
    const [filterValues, setFilterValues] = useState({
        users: [],
        departments: []
    })

    const getData = async (dates) => {
        if (dates.start.isBefore(dates.end) || dates.start.isSame(dates.end)) {
            setLoading(true)
            const stats = await facade.getStats(dates.start.valueOf(), dates.end.valueOf())
            setData(stats)
            setFilters(stats)
            setLabels(stats.labels)
            setLoading(false)
        }
    }


    const setFilters = (stats) => {
        const departments = stats.departments.map(d => d.label)
        const users = stats.users.map(u => u.label)
        const arr = departments.concat(users)
        setFilterValues({
            ...filterValues,
            users: arr,
            departments: departments
        })
        setFilterVal(departments)
    }

    const setFilterVal = (val) => {
        setFilter(val)
    }

    const handleChangeFilter = (id) => {
        if (filter.length > 0) {
            let dataObj = {
                labels: data.labels
            }
            const users = data.users.filter(u => filter.includes(u.label))
            setLoading(true)
            const departments = data.departments.filter(d => filter.includes(d.label))
            dataObj.data = users.concat(departments)
            const d = prepareData(dataObj, data.labels, data.timeFormat)
            setFilteredData(d)
            setLoading(false)
        } else {
            setFilteredData(null)
        }
    }

    useEffect(() => {
        handleChangeFilter()
    }, [filter, labels])

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Grid container spacing={2}>
                    <Grid item xs={3}><MultiSelector filter={filter} setFilter={setFilterVal} label="Filter" values={filterValues.users} /></Grid>
                    {
                    //<Grid item xs={3}><MultiSelector filter={filter} setFilter={setFilterVal} label="Afdelinger" values={filterValues.departments} /></Grid>
                    }
                    <Grid item xs={6}></Grid>
                    <Grid item xs={3}><RangePicker callback={getData} /></Grid>
                    <Grid item xs={12}><Divider/></Grid>
                </Grid>

            </div>
            <div className={classes.mainChart}>
                <Grid container spacing={0}>
                    {loading ?
                        <Grid item xs={12}>
                            <div className={classes.loadingCirc}><CircularProgress /></div>
                        </Grid>
                        :
                        <React.Fragment>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={10}>
                                <LineChart labels={labels} data={filteredData} />
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </React.Fragment>
                    }
                </Grid>
            </div>
        </div>
    )
}