import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useEffect } from 'react';
import Appointment from '../../calendar/components/pages/view-appointment'

export default function FormDialog(props) {
    const [open, setOpen] = React.useState(props.open);


    const handleClose = () => {
        setOpen(false);
        props.appView(false)
    };

    useEffect(() => {
        setOpen(props.open)
    }, [props])

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <div className="appointment-dialog">
                    <Appointment continueFlow={props.continueFlow} desktop={true} onClose={handleClose} />
                </div>
            </Dialog>
        </div>
    );
}
