import React, {useState, useContext, useEffect} from 'react'
import { Grid } from '@material-ui/core'
import DesktopCalendar from '../subcomponents/DesktopCalendar'
import DesktopTimeSlots from '../subcomponents/DesktopTimeSlots'
import moment from 'moment'
import StateManagementContext from '../../../context/StateManagementContext'

export default function Time(props) {
    const [selectedDate, setSelectedDate] = useState(moment(new Date(), "YYYY-MM-DD").hour(0).minute(0).second(0).millisecond(0))
    const state = useContext(StateManagementContext)
    const setDate = (date) => {
        setSelectedDate(moment(new Date(date), "YYYY-MM-DD").hour(0))
    } 

    return (
        <React.Fragment>
            <Grid item xs={6} id="desktop-cal">
                <DesktopCalendar setDate={setDate} />
            </Grid>
            <Grid item xs={6} id="desktop-slots">
                <DesktopTimeSlots goBackToFetch={props.goBackToFetch} carid={state.flow.car.id} setMaxStep={props.setMaxStep} setCurStep={props.setCurStep} setComponent={props.setComponent} date={selectedDate} />
            </Grid>
        </React.Fragment>
    )
}