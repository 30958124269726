import React from "react";
import SimpleViewSlider from "react-view-slider/simple";
import MonthView from "./pages/month-view";
import DayView from "./pages/day-view";
import ManualCustomer from "./pages/manual-customer";
import SearchCustomer from "./pages/search-customer";
import LicensePlate from "./pages/license-plate";
import Picture from "./pages/picture";
import TermsConditions from "./pages/terms-conditions";
import Signature from "./pages/signature";
import Success from "./pages/success";
import LastInformation from "./pages/last-information";
import NewAppointment from "./pages/new-appointment";
import AppointmentView from "./pages/view-appointment";
import { connect } from "react-redux";
import { FLOW_MODES } from "./constant";
import { setPageID } from "../store/actions";
import StateManagementContext from '../../../context/StateManagementContext';
import {
  PAGE_INDEX_EXTERNAL
} from "./constant";
import "./main.css";
import { slideInUp, slideOutDown } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import ProgressBar from "../../utilComponents/Progress/ProgressBar";
import { isTablet } from "react-device-detect";

const styles = {
  slideIn: {
    animation: 'x .5s',
    animationName: Radium.keyframes(slideInUp, 'slideInUp')
  },
  slideOutDown: {
    animation: 'x .5s',
    animationName: Radium.keyframes(slideOutDown, 'slideOutDown')
  }
}

class Calendar extends React.Component {
  constructor(props) {
    super(props)
    this.mainRef = React.createRef();
    this.state = {
      maximumStep: this.props.maximumStep,
      currentStep: this.props.maximumStep,
      mobileStepper: ['1', '2', '3', '4', '5', '6', '7'],
      headers: ["Tidspunkt", "Vilkår & betingelser", "Oplysninger", "Underskrift", "Opretter prøvekørsel.."],
      tempHeader: "",
      showStepper: false,
      close: false,
      showNav: true,
      minimumStep: 0,
    }
  }

  static contextType = StateManagementContext

  componentDidMount = () => {
    if (this.props.driversNote) {
      this.setState({ minimumStep: 1 })
      const mainRef = this.mainRef.current
      if (window.devicePixelRatio > 2) {
        mainRef.style.height = "78%";
      } else {
        mainRef.style.height = "87%";
      }
    }
    this.setState({ showStepper: this.props.showStepper })
    this.setState({ close: false })
  }

  setStepper = (maximumStep) => {
    this.setState({ maximumStep })
  }

  setCurrentStep = (currentStep) => {
    this.setState({ currentStep })
  }

  showStepper = (showStepper) => {
    this.setState({ showStepper })
  }

  close = () => {
    this.setState({ close: true })
    setTimeout(() => { this.props.close() }, 500)
  }

  setTempHeader = (header) => {
    this.setState({ tempHeader: header })
  }

  setHideNav = (hideNav) => {
    this.setState({ showNav: !hideNav })
  }

  render() {
    const { pageID, driversNote } = this.props;
    const callback = (id) => {
      if (id < this.state.currentStep) this.setCurrentStep(id)
      switch (id) {
        case 0:
          this.props.slots()
          break;
        case 1:
          this.props.termsAndConditions()
          break;
        case 2:
          this.props.lastInformation()
          break;
        case 3:
          this.props.signature()
          break;

      }
    }

    const pages = [
      <MonthView setStepper={this.setStepper} setCurrentStep={this.setCurrentStep} />,
      <MonthView setStepper={this.setStepper} setCurrentStep={this.setCurrentStep} />,
      <DayView setMainHigh={this.setMainHigh} mode={FLOW_MODES.flow2} car={this.props.car} setCurrentStep={this.setCurrentStep} setStepper={this.setStepper} />,
      <TermsConditions setTempHeader={this.setTempHeader} setStepper={this.setStepper} setCurrentStep={this.setCurrentStep} />,
      <LastInformation setTempHeader={this.setTempHeader} close={this.props.close} setCurrentStep={this.setCurrentStep} setStepper={this.setStepper} />,
      <Signature setStepper={this.setStepper} setCurrentStep={this.setCurrentStep} />,
      <Success setTempHeader={this.setTempHeader} setHideNav={this.setHideNav} mainRef={this.mainRef} close={this.close} setCurrentStep={this.setCurrentStep} setStepper={this.setStepper} />
    ];

    return (
      <StyleRoot>
        <div>
          <div className="backdrop" onClick={this.close}></div>
          <div ref={this.mainRef} className="main" style={this.state.close ? styles.slideOutDown : styles.slideIn}>
          {pageID === 1 ? null : 
            <div style={{ marginLeft: 30, marginRight: 30 }}>
              <ProgressBar
                headers={this.state.headers}
                tempHeader={this.state.tempHeader}
                callback={callback}
                maximumStep={this.state.maximumStep}
                curStep={this.state.currentStep}
                setTempHeader={this.setTempHeader}
                showNav={this.state.showNav}
                minimumStep={this.state.minimumStep}
              />
            </div>
          }
            <div className="pages">
              <SimpleViewSlider>
                <div key={pageID}>{pages[pageID]}</div>
              </SimpleViewSlider>
            </div>
          </div>
        </div>
      </StyleRoot>
    );
  }
}

const mapStateToProps = state => {
  return {
    pageID: state.pageID
  };
};

const mapDispatchToProps = dispatch => {
  return {
    slots: () => dispatch(setPageID(PAGE_INDEX_EXTERNAL.DAY_VIEW_2_2)),
    lastInformation: () => dispatch(setPageID(PAGE_INDEX_EXTERNAL.LAST_INFORMATIONS)),
    termsAndConditions: () => dispatch(setPageID(PAGE_INDEX_EXTERNAL.TERMS_AND_CONDITIONS)),
    signature: () => dispatch(setPageID(PAGE_INDEX_EXTERNAL.SIGNATURE)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
