import React from "react";
import CustomTextField from '../../../customComponents/CustomTextField';
import CustomButton from '../../../customComponents/CustomButton';
import CustomButtonTrans from '../../../customComponents/CustomButtonTrans';
import facade from '../../../../datafacade/datafacade';
import { connect } from "react-redux";
import { setPageID } from "../../store/actions";
import StateManagementContext from '../../../../context/StateManagementContext';
import {
  PAGE_INDEX,
  HEADERS
} from "../constant";
import { Grid, Hidden } from "@material-ui/core";
import { isTablet, isIPad13, isMobileOnly } from "react-device-detect";

class SearchCustomer extends React.Component {
  static contextType = StateManagementContext
  state = {
    cpr: "",
    driverLicense: "",
    error: {
      cprError: false,
      licenseError: false
    },
    disabled: false,
  }

  // UNSAFE_componentWillMount = () => {
  //   const stateContext = this.context
  //   if (stateContext.flow.customer.personalData !== undefined) {
  //     const consentGiven = stateContext.flow.customer.personalData
  //     this.setState({ disabled: !consentGiven })
  //   }
  // }

  handleChange = (e) => {
    if (this.state.error.cprError) this.setState({ error: { cprError: false } })
    if (this.state.error.licenseError) this.setState({ error: { licenseError: false } })
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  onSubmit = () => {
    if (this.state.cpr.length < 10 || /\D/.test(this.state.cpr)) {
      this.setState({ error: { cprError: true } })
    } else if (this.state.driverLicense.length < 8 || /\D/.test(this.state.driverLicense)) {
      this.setState({ error: { licenseError: true } })
    } else {
      const stateContext = this.context
      stateContext.flowSetCustomer({
        firstName: "",
        license: null,
        driverLicenseNumber: "",
        cpr: "",
        isSubscribed: stateContext.flow.customer.isSubscribed,
        personalData: stateContext.flow.customer.personalData,
        termsOfUse: stateContext.flow.customer.termsOfUse
      })
      stateContext.flowSetCpr(this.state.cpr)
      stateContext.flowSetDriverLicense(this.state.driverLicense)
      if (isTablet || isIPad13) {
        this.props.setMaxStep(3)
        this.props.setCurStep(3)
      } else {
        this.props.setCurrentStep(3)
        this.props.setStepper(3)
        this.props.onClickSearchCustomer()
      }
    }
  }

  onClickManual = () => {
    if (this.state.driverLicense.length < 8 || /\D/.test(this.state.driverLicense)) {
      this.setState({ error: { licenseError: true } })
    } else {
      const stateContext = this.context
      if (stateContext.flow.customer.firstName !== undefined) {
        stateContext.flowSetDriverLicense(this.state.driverLicense)
      } else {
        let customer = { firstName: "", driverLicenseNumber: this.state.driverLicense }
        stateContext.flowSetCustomer(customer)
      }
      if (isTablet || isIPad13) {
        this.props.setComponent(4)
      } else {
        this.props.setStepper(2)
        this.props.onClickManualMode()
      }
    }
  }


  render() {

    return (
      <div style={{ paddingLeft: 63, paddingRight: 63, paddingTop: 33 }}>
        <Grid container spacing={isTablet || isIPad13 ? 4 : 2} style={{ textAlign: "center" }}>
          <Grid item xs={12}>
            <CustomTextField disabled={this.state.disabled} style={{ width: isMobileOnly ? "" : 313 }} error={this.state.error.cprError} type="tel" onBlur={() => window.scroll(0, 0)} name="cpr" onChange={this.handleChange} value={this.state.cpr} fullWidth label="CPR-Nummer" />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField style={{ width: isMobileOnly ? "" : 313 }} required error={this.state.error.licenseError} type="tel" onBlur={() => window.scroll(0, 0)} name="driverLicense" onChange={this.handleChange} value={this.state.driverLicense} fullWidth label="Kørekortnummer" />
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: isMobileOnly ? 'center' : 'right' }}>
            <CustomButton disabled={this.state.disabled} fullWidth onClick={this.onSubmit} style={{ textAlign: isTablet || isIPad13 ? 'right' : 'center' }}>
              Søg
            </CustomButton>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: isMobileOnly ? 'center' : 'left' }}>
            <CustomButtonTrans fullWidth onClick={this.onClickManual} style={{ textAlign: isTablet || isIPad13 ? 'left' : 'center', border: '2px solid' }}>
              Udfyld oplysninger
            </CustomButtonTrans>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClickSearchCustomer: () => dispatch(setPageID(PAGE_INDEX.LAST_INFORMATIONS)),
    onClickManualMode: () => dispatch(setPageID(PAGE_INDEX.ENTER_CUSTOMER_MANUALLY))
  };
};

export default connect(null, mapDispatchToProps)(SearchCustomer);
