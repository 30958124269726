import React from "react";
import { connect } from "react-redux";
import { setPageID } from "../../store/actions";
import {
  PAGE_INDEX,
} from "../constant";
import CustomButton from '../../../customComponents/CustomButton';
import CustomTextField from '../../../customComponents/CustomTextField';
import StateManagementContext from '../../../../context/StateManagementContext';
import { isTablet, isIPad13 } from "react-device-detect";
import { Grid, Hidden } from "@material-ui/core";

class ManualCustomer extends React.Component {
  static contextType = StateManagementContext

  state = {
    tempCust: {
      fullName: "",
      address: "",
      city: ""
    },
    error: {
      fullNameError: false,
      addressError: false,
      cityError: false,
    }
  }

  componentDidMount = () => {
    const state = this.context
    if (state.flow.customer.firstName !== "") {
      const tempCust = {
        fullName: `${state.flow.customer.firstName} ${state.flow.customer.lastName}`,
        address: `${state.flow.customer.address === undefined ? "" : state.flow.customer.address}`,
        city: `${state.flow.customer.zip  === undefined ? "" : state.flow.customer.zip + " " + state.flow.customer.city}`
      }
      this.setState({ tempCust })
    }
  }

  handleChange = e => {
    this.setState({ error: { fullNameError: false, addressError: false, cityError: false } })
    let { name, value } = e.target;
    this.setState({
      tempCust: {
        ...this.state.tempCust,
        [name]: value
      }
    });
  }

  onSubmit = e => {
    if (this.state.tempCust.fullName === "") {
      this.setState({ error: { fullNameError: true } })
    } else if (this.state.tempCust.address === "") {
      this.setState({ error: { addressError: true } })
    } else if (this.state.tempCust.city === "") {
      this.setState({ error: { cityError: true } })
    } else {
      const tempCust = this.state.tempCust
      const fullName = tempCust.fullName.split(" ")
      const fullCity = tempCust.city.split(" ")
      const stateContext = this.context
      stateContext.flowSetCpr("")
      if (stateContext.currentEvent.customer !== undefined) {
        stateContext.flowSetAddress(tempCust.address, fullCity[0], fullCity[1])
      } else {
        const customer = {
          firstName: fullName[0],
          lastName: fullName.slice(1).join(" "),
          address: tempCust.address,
          zip: fullCity[0],
          city: fullCity[1],
          driverLicenseNumber: stateContext.flow.customer.driverLicenseNumber,
          isSubscribed: stateContext.flow.customer.isSubscribed,
          personalData: stateContext.flow.customer.personalData,
          termsOfUse: stateContext.flow.customer.termsOfUse
        }

        stateContext.flowSetCustomer(customer)
      }
      if (isTablet || isIPad13) {
        this.props.setCurStep(3)
        this.props.setMaxStep(3)
      } else {
        this.props.setStepper(3)
        this.props.setCurrentStep(3)
        this.props.onClickContinue()
      }
    }
  }

  render() {
    const InputsView = (
      <div className="input-fields p-3 text-center">
        <div style={{ padding: 10 }}>
          <CustomTextField required error={this.state.error.fullNameError} onBlur={() => window.scroll(0, 0)} name="fullName" value={this.state.tempCust.fullName} onChange={this.handleChange} fullWidth label="Fulde navn" />
        </div>
        <div style={{ padding: 10 }}>
          <CustomTextField required error={this.state.error.addressError} onBlur={() => window.scroll(0, 0)} name="address" value={this.state.tempCust.address} onChange={this.handleChange} fullWidth label="Gadenavn og husnummer" />
        </div>
        <div style={{ padding: 10 }}>
          <CustomTextField required error={this.state.error.cityError} onBlur={() => window.scroll(0, 0)} name="city" value={this.state.tempCust.city} onChange={this.handleChange} fullWidth label="Postnummer og by" />
        </div>
      </div>
    );

    const ButtonView = (
      <div className="text-center mt-3 btn-group" style={{ marginTop: 20 }}>
        <div>
          <CustomButton fullWidth onClick={this.onSubmit}>
            Fortsæt
        </CustomButton>
        </div>
      </div>
    );

    return (
      <div style={{ paddingLeft: 63, paddingRight: 63, paddingTop: 33 }}>
        <Grid container spacing={isTablet || isIPad13 ? 4 : 2} style={{ textAlign: "center" }}>
          <Hidden smDown><Grid item sm={3}></Grid></Hidden>
          <Grid item xs={12} md={6}>
            <CustomTextField required error={this.state.error.fullNameError} onBlur={() => window.scroll(0, 0)} name="fullName" value={this.state.tempCust.fullName} onChange={this.handleChange} fullWidth label="Fulde navn" />
          </Grid>
          <Hidden smDown><Grid item sm={3}></Grid></Hidden>
          <Hidden smDown><Grid item sm={3}></Grid></Hidden>
          <Grid item xs={12} md={6}>
            <CustomTextField required error={this.state.error.addressError} onBlur={() => window.scroll(0, 0)} name="address" value={this.state.tempCust.address} onChange={this.handleChange} fullWidth label="Gadenavn og husnummer" />
          </Grid>
          <Hidden smDown><Grid item sm={3}></Grid></Hidden>
          <Hidden smDown><Grid item sm={3}></Grid></Hidden>
          <Grid item xs={12} md={6}>
            <CustomTextField required error={this.state.error.cityError} onBlur={() => window.scroll(0, 0)} name="city" value={this.state.tempCust.city} onChange={this.handleChange} fullWidth label="Postnummer og by" />
          </Grid>
          <Hidden smDown><Grid item sm={3}></Grid></Hidden>
          <Grid item xs={12} style={{ marginTop: 30 }}>
            <CustomButton fullWidth onClick={this.onSubmit}>
              Fortsæt
            </CustomButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

// <div className="p-4">
// {InputsView}
// {ButtonView}
// </div>

const mapDispatchToProps = dispatch => {
  return {
    onClickContinue: () => dispatch(setPageID(PAGE_INDEX.LAST_INFORMATIONS))
  }
};

export default connect(null, mapDispatchToProps)(ManualCustomer);
