import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const stylesTrans = () => ({
  root: {
    fontWeight: 'bold',
    fontFamily: 'bmwnextbo',
    fontSize: 15,
    'outline': '0',
    "-webkit-box-shadow": "none",
    'box-shadow': 'none',
    'background-color': 'white',
    'color': '#262626',
    'border-color': '2px solid #262626 !important',
    '-moz-border-radius': '7px',
    '-webkit-border-radius': '7px',
    'border-radius': '7px',
    'width': '250px',
    'height': '50px',
    'disabled': {
      'background-color': '#BBBBBB'
    },
    '&:hover': {
      background: '#ffffff',
      'outline': '0',
      "-webkit-box-shadow": "none",
      'box-shadow': 'none',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'yellow',
    },
  }
});

function CustomButton(props) {
  return <Button style={{border: '2px solid'}} variant="outlined" {...props} />;
}

export default withStyles(stylesTrans)(CustomButton);
