import React, { useState, useContext, useEffect } from 'react'
import { Grid, Divider, CircularProgress } from '@material-ui/core'
import DesktopCalendar from '../subcomponents/DesktopCalendar'
import HourView from '../subcomponents/HourView'
import moment from 'moment'
import StateManagementContext from '../../../context/StateManagementContext'
import facade from '../../../datafacade/datafacade';
import DesktopFlow from '../DesktopFlow'
import { isIPad13, isTablet } from 'react-device-detect'

export default function Calendar(props) {
    const [selectedDate, setSelectedDate] = useState(moment(new Date(), "YYYY-MM-DD").hour(0).minute(0).second(0).millisecond(0))
    const [appointments, setAppointments] = useState([])
    const [car, setCar] = useState({})
    const [loading, setLoading] = useState(false)
    const [desktopflow, setDesktopflow] = useState(false)
    const state = useContext(StateManagementContext)

    const setDate = (date) => {
        setSelectedDate(moment(new Date(date), "YYYY-MM-DD").hour(0))
    }

    const setState = async () => {
        setLoading(true)
        const app = await facade.getOneDayBookings(state.flow.car.id)
        const appointments = app.filter(a => a.car.id === state.flow.car.id)
        const selectedCar = state.cars.find(c => c.id === state.flow.car.id)
        setCar(selectedCar)
        state.setBookings(appointments)
        setAppointments(appointments)
        setLoading(false)
    }

    useEffect(() => {
        setState()
    }, [selectedDate])

    return (
        desktopflow ? <DesktopFlow isNote={true} noLicense={true} handleOpen={props.handleOpen} /> :
            <React.Fragment>
                <Grid item xs={12} className="desktop-header desktop-head-cal"><div className="desktop-head-cal">Kalender</div></Grid>
                <Grid item xs={12} style={{ paddingLeft: isTablet || isIPad13 ? 15 : 70, paddingRight: isTablet || isIPad13 ? 15 : 70 }}><Divider /></Grid>
                <Grid item xs={6} id="desktop-hour-cal" style={{ marginTop: 35, marginLeft: isTablet || isIPad13 ? -10 : 0 }}>
                    <DesktopCalendar setDate={setDate} />
                </Grid>
                <Grid item xs={6} style={{paddingLeft: isTablet || isIPad13 ? 30 : 0}}>
                    {loading ?
                        <div style={{ marginTop: 200 }}><CircularProgress /></div>
                        :
                        <HourView continueFlow={props.continueFlow} car={car} appointments={state.bookings} date={selectedDate} />
                    }
                </Grid>
            </React.Fragment>

    )
}