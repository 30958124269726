import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import Auth from '../auth/Auth';


export default function PrivateRoute({ component: Component, ...rest }) {
    const auth = new Auth()
    return (
      <Route
        {...rest}
        render={props =>
            auth.loggedIn() ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  }

