import React, { useContext, useEffect } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import IconButton from '../../utilComponents/IconButton';
import TableGrid from '../../utilComponents/TableGrid';
import SettingsIcon from '../../../assets/icons/setting48grey.png';
import AddIcon from '../../../assets/icons/Add.png';
import AddIconHover from '../../../assets/icons/AddDark.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import StateManagementContext from '../../../context/StateManagementContext';
import AddNewDepartment from './AddNewDepartment';
import UpdateDepartment from './UpdateDepartment';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '30px',
        '@media (max-width: 767px)': {
            marginTop: '60px'
        },
    },
    header: {
        fontFamily: 'bmwnextthin',
        textAlign: "center",
        fontSize: 25
    },
    main: {
        margin: 'auto',
        paddingTop: 15,
        width: '90%'
    },
    addIcon: {
        textAlign: 'left',
        paddingBottom: 10,
        paddingLeft: 20
    }
}))

export default function Departments(props) {
    const xs = useMediaQuery(theme => theme.breakpoints.down(767));
    const openSettings = (e) => {
        const id = e.target.id
        setHeader("Rediger afdeling")
        setComponent(<UpdateDepartment departmentId={id} handleClose={openTable} />)
    }

    const openAddNewDepartment = (e) => {
        setHeader("Tilføj afdeling")
        setComponent(<AddNewDepartment handleClose={openTable} />)
    }

    const openTable = (e) => {
        setHeader("Afdelinger")
        setComponent(<TableGrid aria="department table" collection={generateDate()} />)
    }

    const generateDate = () => {
        const collection = state.departments.map(dep => {
            if (xs) {
                return [
                    dep.departmentName,
                    dep.city,
                    <img id={dep.id} alt="" style={{ cursor: 'pointer' }} onClick={openSettings} src={SettingsIcon} height="28" width="28"></img>
                ]
            } else {
                return [
                    dep.id,
                    dep.departmentName,
                    dep.city,
                    dep.email,
                    dep.phone,
                    <img id={dep.id} alt="" style={{ cursor: 'pointer' }} onClick={openSettings} src={SettingsIcon} height="28" width="28"></img>
                ]
            }
        })
        return collection
    }

    const classes = useStyles()
    const state = useContext(StateManagementContext)
    const [component, setComponent] = React.useState()
    const [header, setHeader] = React.useState("Afdelinger")

    useEffect(() => {
        openTable()
        // eslint-disable-next-line
    }, [xs, state])

    return (
        <div className={classes.root}>
            <Grid container className={classes.main}>
                {header === "Afdelinger" ?
                    <Grid item xs={12} className={classes.addIcon}>
                        <IconButton title="Tilføj" rotate={null} onClick={openAddNewDepartment} height={25} width={25} icon={AddIcon} hoverIcon={AddIconHover} />
                    </Grid>
                    : null}
                <Grid item xs={12}>
                    {component}
                </Grid>
            </Grid>
        </div>
    )
}