export const PAGE_INDEX = {
  MONTH_VIEW_1_2: 0,
  MONTH_VIEW_2_2: 1,
  CHOOSE_LICENSE_PLATE: 2,
  DAY_VIEW_1_2: 3,
  DAY_VIEW_2_2: 4,
  APPOINTMENT_1: 5,
  APPOINTMENT_2: 6,
  TERMS_AND_CONDITIONS: 7,
  PICTURE: 8,
  SEARCH_CUSTOMER: 9,
  ENTER_CUSTOMER_MANUALLY: 10,
  LAST_INFORMATIONS: 11,
  SIGNATURE: 12,
  NEW_APPOINTMENT: 13,
  DONE: 14,
};

export const PAGE_INDEX_EXTERNAL = {
  MONTH_VIEW_1_2: 0,
  MONTH_VIEW_2_2: 1,
  DAY_VIEW_2_2: 2,
  TERMS_AND_CONDITIONS: 3,
  LAST_INFORMATIONS: 4,
  SIGNATURE: 5,
  DONE: 6,
};

export const HEADERS = {
  calendar: "Kalender",
  testDriveDate: "Vælg et tidspunkt",
  enterDriverCPR: "Manuel indstastning",
  doneCPR: "We got it!",
  failureCPR: "Det virkede ikke...",
  manualDriverInfo: "Udfyld felterne",
  missingInformation: "De sidste detaljer",
  enterSamplePlateToMount: "Indtast prøveplade",
  takePicture: "Tag et billede af førerens kørekort",
  flipPhoneToSign: "Vend telefonen om for at underskrive køresedlen",
  termsAndConditions: "Vilkår & betingelser",
  done: "You nailed it!",
  doneSub: "Prøvekørslen er oprettet",
  fail: "Det var desværre ikke muligt at oprette prøvekørslen.",
  failSub: "Vælg venligst et andet tidspunkt.",
};

export const FLOW_MODES = {
  initial: "Initial-Mode",
  flow2: "Add-Appointment-Flow",
  flow1: "Start-Calendar-Flow",
};

export const DANISH_MONTHS = [
  "Januar",
  "Februar",
  "Marts",
  "April",
  "Maj",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "December",
];

export const DANISH_WEEKS = [
  "Mandag",
  "Tirsdag",
  "Onsdag",
  "Torsdag",
  "Fredag",
  "Lørdag",
  "Søndag",
];

export const ONE_LETTER_WEEKS = ["Man", "Tirs", "Ons", "Tors", "Fre", "Lør", "Søn"];

export const DAYS_INDEX = {
  Mon: 0,
  Tue: 1,
  Wed: 2,
  Thu: 3,
  Fri: 4,
  Sat: 5,
  Sun: 6,
};

export const SUB_HEADERS = {
  latestLicensePlates: "Seneste anvendte prøveplader",
  termsAndConditions: "Vilkår og betingelser",
  appointmentSubject: "Emne",
};
export const INPUT_LABELS = {
  ssn: "CPR-nummer",
  name: "Navn Navnesen",
  address: "Adresse",
  cityZipcode: "Postnr By",
  phone: "Telefonnummer",
  email: "E-mailadresse",
  duration: "Varighed (Normalt 30 min)",
  firstName: "Navn",
  lastName: "Efternavn",
  street: "Gade",
  house: "Husnummer",
  zipCode: "Postnummer",
  city: "By",
  samplePlate: "Prøveplade",
};

export const BUTTON_LABELS = {
  addAppointment: "Add appointment",
  startCalendar: "Start Calendar",
  private: "Privat",
  advanced: "Erhverv",
  continue: "Fortsæt",
  addMoreDrivers: "Tilføj flere førere",
  addNote: "Tilføj note",
  search: "Søg",
  manual: "Manuel indtastning",
  camera: "Åben kamera",
  close: "Luk",
  yes: "Godkend",
  no: "Afvis",
  saveAndClose: "Gem og luk",
  save: "Gem",
  cancel: "Annuller",
};

export const DESCRIPTIONS = {
  takePicture:
    "Du kan tage et billede af førerens kørekortet for at aflæse og indsamle de nødvendige oplysninger, til brug for oprettelse af prøvekørslen. Alternativt, kan du manuelt indtaste oplysningerne.",
  success:
    "Prøvekørslen er nu oprettet og køresedlen er sendt som en SMS til det oplyste telefonnummer.",
  error:
    "Det valgte tidspunkt er ikke længere ledigt",
  chooseNewTime:
    "Vælg et nyt tidspunkt for at gennemføre oprettelsen",
  termsAndConditions: [
    "Vi vil gerne have lov til at kontakte dig efter din prøvekørsel, for at følge op på din oplevelse, og sende vores nyhedsbrev, med nyheder, tilbud og artikler fra BMW.",
    "Herudover indsamler vi data til brug for dokumentation og analyse.",
    "Ovenstående er det grundlæggende i vores Vilkår og Betingelser.",
    "Du kan læse mere om vores vilkår og betingelser her.",
    "Vil du acceptere vores vilkår og betingelser?",
  ],
};

export const BUTTON_MODES = {
  confirm: "confirm",
  manual: "manual",
};

export const PERSONAL_MODES = ["private", "advanced"];

export const DAYS_COUNT_IN_WEEK = 7;

export const DAY_LENGTH = 24;

export const MINS_PER_HOUR = 60;

export const SLOT_CONFIG = {
  nextSlot: 30,
  startTime: "00:00",
  endTime: "23:59",
  heightPerThirtyMins: 36,
};

export const COLOR_SCHEMA = {
  blueBackground: "#0066b1",
  blueBackgroundFinished: "#003d78",
  greyBackground: "#e22718",
};

export const MODAL_WIDTH = 375;

export const WIDTH_PERCENTAGE = 0.103;

export const DATE_FORMAT = "YYYY MM DD";
