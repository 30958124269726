import React, { useContext, useEffect, useState } from 'react';
import CustomButton from '../customComponents/CustomButton'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import CustomTextField from '../customComponents/CustomTextField';
import datafacade from '../../datafacade/datafacade';
import StateManagementContext from '../../context/StateManagementContext';
import BackIcon from '../../assets/icons/BackGrey.png';
import { isMobile } from 'react-device-detect';
import { slideInDown, slideOutUp } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import PasswordIcon from '../../assets/icons/PasswordChecker.svg';
import PasswordIcon1 from '../../assets/icons/PasswordChecker1.svg';

const styles = {
  slideInDown: {
    animation: 'x .5s',
    animationName: Radium.keyframes(slideInDown, 'slideInDown')
  },
  slideOutUp: {
    animation: 'x .5s',
    animationName: Radium.keyframes(slideOutUp, 'slideOutUp')
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '20px',
    paddingBottom: '30px'
  },
  submitButton: {
    width: '260px'
  },
  input: {
    paddingTop: '32px',
    textAlign: 'center',
    margin: 'auto',
    width: '300px'
  },
  typo: {
    fontFamily: 'bmwnextthin'
  },
  backHeader: {
    marginBottom: 20,
    padding: 26,
    marginLeft: -32,
    marginRight: -32,
    marginTop: -32,
    width: '100vw',
    boxShadow: '0px 0px 4px 2px #4F4F4F'
  }
}));


export default function ProfileUpdate(props) {
  const classes = useStyles();
  var regex = new RegExp("(?=.*[A-Z]).*[0-9]");
  const [passError, setPassError] = React.useState(false);
  const state = useContext(StateManagementContext)
  const [mounted, setMounted] = useState(false)
  const [fetchError, setFetchError] = useState(false)
  const [passRegex, setPassRegex] = useState(false)
  const [passRegex1, setPassRegex1] = useState(false)
  const [disButton, setDisButton] = useState(true)
  const [password, setPassword] = React.useState({
    oldPass: "",
    password1: "",
    password2: "",
  })

  const handleChangePassword = e => {
    const { name, value } = e.target
    setPassword({
      ...password,
      [name]: value
    })
  }

  const clearPass = () => {
    setPassword({
      oldPass: "",
      password1: "",
      password2: "",
    })
  }

  useEffect(() => {
    testPassword()
  }, [password])

  useEffect(() => {
    if(passRegex && passRegex1) setDisButton(false)
    else setDisButton(true)
  }, [passRegex, passRegex1])

  const testPassword = () => {
    if (regex.test(password.password1)) {
      setPassRegex(true)
    } else {
      setPassRegex(false)
    }

    if(password.password1.length >= 8) {
      setPassRegex1(true)
    } else {
      setPassRegex1(false)
    }
  }


  const handleSubmitPassword = async (e) => {
    if (password.password1 !== password.password2) {
      setFetchError(false)
      setPassError(true)
    } else {
      setFetchError(false)
      const body = {
        email: state.currentUser.email,
        oldPassword: password.oldPass,
        newPassword: password.password1
      }
      e.preventDefault()
      try {
        await datafacade.changePassword(body)
        clearPass()
        props.handleOpen(false)
      } catch (error) {
        setFetchError(true)
        setPassError(false)
      }
    }
  }

  const handleMounted = (setMount) => {
    if (props.openSubMenu) {
      setTimeout(() => {
        setMounted(setMount)
      }, 600)
    }
  }

  useEffect(() => {
    handleMounted(true)
  }, [props.openSubMenu])

  return (
    <div>
      {isMobile && props.open && mounted ?
        <StyleRoot>
          <div style={mounted ? styles.slideInDown : styles.slideOutUp}>
            <Grid container className={classes.backHeader}>
              <Grid item xs={3} style={{ marginTop: -6 }}>
                <img onClick={() => { props.handleOpen(!props.open) }} src={BackIcon} style={{ marginBottom: -10 }} alt={'close'} height={23} width={23} />
              </Grid>
              <Grid style={{ textAlign: 'center', marginTop: -3 }} item xs={6}>
                <Typography variant="inherit" style={{ fontFamily: 'bmwnextbo', borderBottom: '3px solid #1b69d4', paddingLeft: 10, paddingRight: 10, paddingBottom: 24, fontSize: 16 }}>Profil</Typography>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </div>
        </StyleRoot>
        : isMobile ? <div style={{ height: 59, width: '100vw' }}></div> : null}
      <Grid container
        spacing={0}
        direction="column"
        className={classes.root}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography className={classes.typo} variant="h5">
            Skift password
            </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <div className={classes.input}>
            <CustomTextField required type="password" name="oldPass" fullWidth label="Nuværende password" value={password.oldPass} onChange={handleChangePassword} />
          </div>
          <div className={classes.input}>
            <CustomTextField required type="password" error={passError} name="password1" fullWidth label="Nyt password" value={password.password1} onChange={handleChangePassword} />
          </div>
          <div className={classes.input}>
            <CustomTextField required type="password" error={passError} name="password2" fullWidth label="Gentag nyt password" value={password.password2} onChange={handleChangePassword} />
          </div>
          <div style={{ color: passRegex ? '#70AD47' : "", fontFamily: 'bmwnextli', fontSize: 14, marginTop: 32, padding: 8 }}><span style={{ position: 'relative', top: 3, padding: 3 }}><img src={passRegex ? PasswordIcon1 : PasswordIcon} alt="password1" /></span>Et stort bogstav. Et tal mellem 1 - 9.</div>
          <div style={{ color: passRegex1 ? '#70AD47' : "", fontFamily: 'bmwnextli', fontSize: 14 }}><span style={{ position: 'relative', top: 3, padding: 3, left: -15, marginRight: -15 }}><img src={passRegex1 ? PasswordIcon1 : PasswordIcon} alt="password2" /></span>Skal indeholde min. 8 karakterer.</div>
          <div style={{ color: '#e22718', paddingTop: 40 }}>{fetchError ? "Gammelt password matcher ikke" : '\u00A0'}{passError ? "Password matcher ikke eller er ikke over 8 karakterer" : '\u00A0'}</div>
          <div style={{ textAlign: 'center' }}>
            <CustomButton disabled={disButton} className={classes.submitButton} fullWidth onClick={handleSubmitPassword}>
              Opdater password
                </CustomButton>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}