import React, { useContext } from 'react';
import CustomButton from '../../customComponents/CustomButton'
import Dialog from '@material-ui/core/Dialog';
import datafacade from '../../../datafacade/datafacade';
import Grid from '@material-ui/core/Grid';
import StateManagementContext from '../../../context/StateManagementContext';
import Slide from '@material-ui/core/Slide';
import CustomButtonTrans from '../../customComponents/CustomButtonTrans'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteUserDialog(props) {
  const [open, setOpen] = React.useState(false);
  const state = useContext(StateManagementContext)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    datafacade.deleteUser(props.user)
    state.deleteUser(props.user)
    // props.handleClose()
    handleClose()
  }

  return (
    <div>
      <Grid container spacing={4} style={{ marginTop: 50 }}>
        <Grid item md={6} style={{ textAlign: 'right' }}>
          <CustomButtonTrans disabled={!props.changes} fullWidth onClick={props.handleSave}>
            Gem
      </CustomButtonTrans>
        </Grid>
        <Grid item md={6} style={{ textAlign: 'left' }}>
          <CustomButton disabled={props.disable} fullWidth onClick={handleClickOpen}>
            Slet
      </CustomButton>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={{ textAlign: "center", padding: 50, height: 400, width: 350 }}>
          <div style={{ fontFamily: 'bmwnextbo', fontSize: 22, padding: 10 }}>ADVARSEL!</div>
          <div style={{ padding: 8 }}>Du er ved at slette:</div>
          <div style={{ fontFamily: 'bmwnextbo', paddingBottom: 18 }}>{props.user.firstName} {props.user.lastName}</div>
          <div style={{ paddingBottom: 22 }}>Vil du fortsætte handlingen?</div>
          <div style={{ marginTop: 34 }}>
            <CustomButtonTrans onClick={handleClose} autoFocus>
              Fortryd
            </CustomButtonTrans>
            <div style={{ marginTop: 18 }}>
              <CustomButton onClick={handleDelete}>
                Slet
              </CustomButton>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
