import React, { Children, useEffect, useRef } from 'react';
import '../MainHeaderWindow/mainwindow.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { slideInLeft, slideOutLeft } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { Slide } from '@material-ui/core';


const styles = {
    slideInLeft: {
        animation: 'x .7s',
        animationName: Radium.keyframes(slideInLeft, 'slideInLeft')
    },
    slideOutLeft: {
        animation: 'x .7s',
        animationName: Radium.keyframes(slideOutLeft, 'slideOutLeft')
    }
}

const classNames = require('classnames');


export default function Main(props) {
    const notDesktop = useMediaQuery(theme => theme.breakpoints.down(1023));
    const mainRef = useRef(null)

    const mainClass = classNames({
        'window': props.open,
        'window-cal': props.calOpen && notDesktop,
        'window-hidden': !props.open || props.children === null,
        'window-medium': props.size === 'md',
        'window-small': props.size === 'sm',
        'no-overflow': props.child === 'testcars' && !isMobile || props.child === 'testcarsMini' && !isMobile
    });

    useEffect(() => {
        if (mainRef != null && !isMobileOnly) {
            mainRef.current.scrollTo(0, 0);
        }
    }, [props.child])


    if (isMobileOnly) {
        return (
            <Slide direction="right" timeout={{enter: 500, exit: 500}} in={props.openSubMenu} mountOnEnter unmountOnExit>
                <div ref={mainRef} className={mainClass}>
                    {Children.map(props.children, child => React.cloneElement(child, { ...props }))}
                </div>
            </Slide>
        )
    } else {
        return (
            <div ref={mainRef} className={mainClass}>
                {Children.map(props.children, child => React.cloneElement(child, { ...props }))}
            </div>
        )
    }
}