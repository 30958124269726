import React, { useContext } from 'react';
import CustomButton from '../../customComponents/CustomButton'
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import datafacade from '../../../datafacade/datafacade';
import StateManagementContext from '../../../context/StateManagementContext';
import CustomButtonTrans from '../../customComponents/CustomButtonTrans'

const useStyles = makeStyles(theme => ({
  input: {
    margin: theme.spacing(1, 0)
  },
  main: {
    width: '60%'
  },
  submitButton: {
    // margin: theme.spacing(4, 0),
    // width: '80%'
  },
}));

export default function DeleteCar(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const state = useContext(StateManagementContext)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    const c = { id: props.car.id }
    datafacade.deleteCar(c)
    state.deleteCar(props.car)
    handleClose()
  }

  return (
    <div>
      <Grid container spacing={10} style={{ marginTop: 50 }}>
        <Grid item md={6} style={{ textAlign: 'right' }}>
          <CustomButtonTrans disabled={!props.changes} className={classes.submitButton} fullWidth onClick={props.handleSave}>
            Gem
          </CustomButtonTrans>
        </Grid>
        <Grid item md={6} style={{ textAlign: 'left' }}>
          <CustomButton className={classes.submitButton} disabled={props.disable} fullWidth onClick={handleClickOpen}>
            Slet
          </CustomButton>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <div style={{ textAlign: "center", padding: 50, height: 400, width: 350 }}>
          <div style={{ fontFamily: 'bmwnextbo', fontSize: 22, padding: 10 }}>ADVARSEL!</div>
          <div style={{ padding: 8 }}>Du er ved at slette:</div>
          <div style={{ fontFamily: 'bmwnextbo', paddingBottom: 18 }}>{props.car.manufacturer + " " + props.car.model + " " + props.car.variant + " " + props.car.equipment}</div>
          <div style={{ paddingBottom: 22 }}>Vil du fortsætte handlingen?</div>
          <div style={{ marginTop: 34 }}>
            <CustomButtonTrans onClick={handleClose} autoFocus>
              Fortryd
          </CustomButtonTrans>
            <div style={{ marginTop: 18 }}>
              <CustomButton onClick={handleDelete}>
                Slet
          </CustomButton>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
