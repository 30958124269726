export const SET_FLOW_MODE = "SET_FLOW_MODE";

export const PAGE_NAVIGATION = "PAGE_NAVIGATION";

export const ADD_APPOINTMENT_STARTED = "ADD_APPOINTMENT_STARTED";

export const ADD_APPOINTMENT_SUCCESS = "ADD_APPOINTMENT_SUCCESS";

export const ADD_APPOINTMENT_FAILURE = "ADD_APPOINTMENT_FAILURE";

export const ADD_TIME_SLOTS_STARTED = "ADD_TIME_SLOTS_STARTED";

export const ADD_TIME_SLOTS_SUCCESS = "ADD_TIME_SLOTS_SUCCESS";

export const ADD_TIME_SLOTS_FAILURE = "ADD_TIME_SLOTS_FAILURE";

export const SAVE_DATE = "SAVE_DATE";

export const SET_TIME_SLOT = "SET_TIME_SLOT";

export const SET_PHOTO = "SET_PHOTO";

export const SET_SIGNATURE = "SET_SIGNATURE";
