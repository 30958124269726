import React, { Component, createRef } from "react";
import "../carousel/Carousel.css";

export default class Carousel extends Component {
  state = {
    currentId: this.props.index,
    centerPos: 0,
    widthBefore: 0,
  };

  mainRef = createRef();

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.setState({ widthBefore: this.mainRef.current.offsetWidth })
    this.slideTo(this.props.index)
  }

  slideTo(id) {
    if (id > this.props.maximumStep && this.props.stepper) {
      id = this.props.maximumStep
    }
    this.setState({ currentId: id }, () => {
      let centerPos =
        this.mainRef.current.offsetWidth / 2 -
        this.getWidthOfItems(id) -
        this.getDeltaXOfSelected(id);
        if(this.props.array[id].length > 2) {
          centerPos -= (this.getDeltaXOfSelected(id) * 1.5)
        }
      this.setState({ centerPos: centerPos });
    });
    this.props.callback(id)
  }

  getWidthOfItems(id) {
    const width = [...this.mainRef.current.children[1].children]
      .slice(0, id)
      .reduce((sum, i) => sum + i.offsetWidth, 0);
    return width;
  }

  getDeltaXOfSelected() {
    return (
      this.mainRef.current.children[1].children[this.state.currentId]
        .offsetWidth / 2
    );
  }

  handleWheelEvent = e => {
    e.persist();
    const { currentId } = this.state;
    const nextIndex = e.deltaY > 0 ? currentId + 1 : currentId - 1;
    if (nextIndex >= 0 && nextIndex < this.props.array.length) {
      this.setState({ currentId: nextIndex }, () => {
        this.slideTo(nextIndex);
      });
    }
  };

  handleTouchEvent = (i, event) => {
    this.setState({ currentIndex: i }, () => {
      this.slideTo(i);
    });
  };

  updateDimensions = () => {
    const width = this.mainRef.current.offsetWidth
    const reCenter = Math.abs(width - this.state.widthBefore) / 2
    if (width <= this.state.widthBefore) {
      const centerPos = this.state.centerPos - reCenter
      this.setState({ centerPos })
    } else {
      const centerPos = this.state.centerPos + reCenter
      this.setState({ centerPos })
    }
    this.setState({ widthBefore: width })
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    const { currentId, centerPos } = this.state;
    const { array, title } = this.props;

    const styleSelectedItem = id => {
      return currentId === id ? "carousel-selected" : "carousel-item";
    };

    const getIndex = item => {
      return array.indexOf(item);
    };

    const stylePositionCarousel = {
      transform: `translate(${centerPos}px)`
    };

    return (
      <div ref={this.mainRef} className="slider">
        <div className="carousel-header">{title}</div>
        <div
          style={stylePositionCarousel}
          className="carousel"
          onWheel={e => this.handleWheelEvent(e)}
        >
          {array.map((item, id) => (
            <div
              key={item}
              style={this.state.currentId === 11 && id === 11  ? {marginRight: 20} : {}}
              className={styleSelectedItem(getIndex(item))}
              onClick={() => this.slideTo(getIndex(item))}
              onTouchMove={event =>
                this.handleTouchEvent(getIndex(item), event)
              }
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
