import React from "react";
import moment from "moment";
import WeekLine from "../subcomponents/calendar/week-line";
import WeekView from "../subcomponents/calendar/week-view";
import Datafacade from '../../../../datafacade/datafacade';
import { formatDate, formatDateNoYear } from "../utils";
import TimeSlots from "../subcomponents/calendar/TimeSlots";
import Appointments from "../subcomponents/calendar/appointments";
import BackIcon from '../../../../assets/icons/backBlue.png';
import Facade from '../../../../datafacade/datafacade';
import StateManagementContext from '../../../../context/StateManagementContext';
import {
  PAGE_INDEX,
  FLOW_MODES as modes,
  DATE_FORMAT
} from "../constant";
import { connect } from "react-redux";
import {
  setPageID,
  setFlowMode,
} from "../../store/actions";
import BackCalendar from "../subcomponents/button/BackCalendar";
import WeekNo from "../subcomponents/calendar/WeekNo";

class DayView extends React.Component {
  static contextType = StateManagementContext
  state = {
    timeSlots: [],
    appointments: [],
    isBefore: false,
    selectedTime: null,
  }
  scrollView = React.createRef()

  componentDidMount = async () => {
    const { selectedDate, car } = this.props
    const stateContext = this.context
    const {
      setFlowMode1,
      setFlowMode2
    } = this.props;
    let today = new Date()
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)
    today.setMilliseconds(0)
    if (new Date(selectedDate).getTime() < today.getTime()) {
      this.setState({ isBefore: false })
    } else {
      this.setState({ isBefore: true })
    }

    if (this.checkFlowisOne()) {
      setFlowMode1()
      if (car.id !== undefined) {
        const appointments = await Datafacade.getOneDayBookings(car.id)
        this.setState({ appointments })
        stateContext.setBookings(appointments)
      }
      if (this.scrollView.current !== null) {
        if (stateContext.scrollPos === null) {
          const scroll = moment().hour() * 75
          stateContext.setScrollPos(scroll)
          this.scrollView.current.scrollTo(0, scroll)
        } else {
          this.scrollView.current.scrollTo(0, stateContext.scrollPos)
        }
      }
    } else {
      setFlowMode2()
    }
  }

  checkFlowisOne() {
    const { mode } = this.props;
    return mode === modes.flow1;
  }

  updateTimeSlots = async (date) => {
    const { car } = this.props
    let today = new Date()
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)
    today.setMilliseconds(0)
    if (date.getTime() < today.getTime()) {
      this.setState({ isBefore: false })
      this.setState({ timeSlots: [] })
    } else {
      this.setState({ isBefore: true })
      const timeSlots = await Facade.getAvailableSlots(date.getTime(), car.id)
      this.setState({ timeSlots })
    }
  }

  setTime = (start, end, id) => {
    const stateContext = this.context
    stateContext.flowSetTime(start, end)
    stateContext.flowSetSelectedTime(id)
  }

  onScroll = (e) => {
    const stateContext = this.context
    const pos = this.scrollView.current.scrollTop
    stateContext.setScrollPos(pos)
  }

  render() {
    const { onBackMonth1, onBackMonth2, selectedDate } = this.props;
    const month = moment(selectedDate, DATE_FORMAT).month();
    const yr = moment(selectedDate, DATE_FORMAT).year();
    const wk = moment(selectedDate, DATE_FORMAT).day();
    const day = moment(selectedDate, DATE_FORMAT).date();

    const onBackMonth = () =>
      this.checkFlowisOne() ? onBackMonth1() : onBackMonth2();

    const WeeksView = (
      <div>
        <BackCalendar onClick={this.props.onBackMonth1} title={formatDateNoYear(month, wk, day)} noline />
        <WeekNo moment={moment(selectedDate, DATE_FORMAT)} />
        <div className="weeks-view">
          <WeekView updateTimeSlots={this.updateTimeSlots} />
          <WeekLine />
        </div>
      </div>
    );


    const MainView = this.checkFlowisOne() ? (
      <>
        {WeeksView}
        <div style={{ borderBottom: '1px solid black', width: '100%' }}></div>
      </>
    ) : (
        <>
          <div className="back-year" onClick={onBackMonth}>
            <div style={{ marginLeft: -10 }}>
              <img style={{ verticalAlign: "middle", marginBottom: 4 }} alt="back" height="25" width="25" src={BackIcon} />
              <span style={{ fontFamily: 'bmwnextbo', marginLeft: 10, fontSize: 16 }}>{formatDate(yr, month, wk, day)}</span>
            </div>
          </div>
        </>
      );

    const SlotsView = this.checkFlowisOne() ? (
      <Appointments appointments={this.state.appointments} />
    ) : (
        <div>
          {
            <TimeSlots noFetch={this.checkFlowisOne()} selectedDate={this.props.selectedDate} carId={this.props.car.id} setStepper={this.props.setStepper} setCurrentStep={this.props.setCurrentStep} />
            //<TimeSlots selectedTime={this.state.selectedTime} setStepper={this.props.setStepper} setCurrentStep={this.props.setCurrentStep} isBefore={this.state.isBefore} selectedDate={this.props.selectedDate} setTime={this.setTime} timeSlots={this.state.timeSlots} />
          }
        </div>
      );

    return (
      <div className="p-4 text-center vh-100">
        <div className={this.checkFlowisOne() ? "" : "main-cal"}>
          {MainView}
          <div ref={this.scrollView} onScroll={this.onScroll} id="scroll-slot" className={this.checkFlowisOne() ? "appointment-view overflow" : "slots-view overflow"}>
            {SlotsView}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timeSlots: state.timeSlots,
    selectedDate: state.selectedDate,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setFlowMode1: () => dispatch(setFlowMode(modes.flow1)),
    setFlowMode2: () => dispatch(setFlowMode(modes.flow2)),
    onBackMonth1: () => dispatch(setPageID(PAGE_INDEX.MONTH_VIEW_1_2)),
    onBackMonth2: () => dispatch(setPageID(PAGE_INDEX.MONTH_VIEW_2_2)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DayView);
