import React, { useEffect, useCallback } from 'react';
import { isMobileOnly } from 'react-device-detect';
import ForwardIcon from '../../assets/icons/ForwardGrey.png';

const classNames = require('classnames');

export default function Item(props) {

    if (props.icon) {
        return (
            Icon(props)
        )
    } else {
        return (
            Text(props)
        )
    }
}

function Text(props) {
    const [active, setActive] = React.useState(false)

    const handleClick = (e) => {
        props.onClick(e)
    }

    const setItemActive = useCallback(() => {
        if(props.id === props.active) {
            setActive(true)
        } else {
            setActive(false)
        }
    }, [props])

    useEffect(() => {
        setItemActive()
    }, [props, setItemActive]);

    const textClass = classNames({
        'item': true,
        'item-open': props.open || isMobileOnly,
        'item-active': active && props.open
    });


    return (
        <div id={props.id} onClick={handleClick} className={textClass}>
            <div id={props.id} className={props.subitem ? "text-sub" : "text"}>
                <div id={props.id} style={{fontFamily: 'bmwnextbo'}}>{props.label}{props.endArrow ? <img height="25" width="25" style={{float: 'right'}} alt="icon" src={ForwardIcon}/> : null}</div>
            </div>
        </div>
    )
}

function Icon(props) {
    return (
        <div style={props.style} id={props.id} className="item">
            <div id={props.id} className="icon-header">
                <img id={props.id} onClick={props.onClick} src={props.label} alt={props.alt} height={props.height} width={props.width} />
            </div>
        </div>
    )
}