import React from 'react'
import SimpleViewSlider from "react-view-slider/simple";
import BackProgress from '../../../assets/icons/BackProgress.svg';
import ForwardProgress from '../../../assets/icons/ForwardProgress.svg';
import BackProgressDesktop from '../../../assets/icons/BackProgressDesktop.svg';
import ForwardProgressDesktop from '../../../assets/icons/ForwardProgressDesktop.svg';
import {isMobile} from 'react-device-detect'
import './progress.css'

export default function (props) {
    const [value, setValue] = React.useState(null);
    const [id, setId] = React.useState(props.curStep);
    const [tempHeader, setTempHeader] = React.useState("");
    const [showNav, setShowNav] = React.useState(true);
    const [width, setWidth] = React.useState(0)
    const progressRef = React.useRef(null)

    React.useEffect(() => {
        setWidth(progressRef.current.offsetWidth)
    }, []);

    React.useEffect(() => {
        setValue(Math.abs((id / (props.headers.length - 1) * width) - width));
    }, [id, width]);

    React.useEffect(() => {
        setTempHeader(props.tempHeader)
    }, [props.tempHeader]);

    React.useEffect(() => {
        setShowNav(props.showNav)
    }, [props.showNav]);

    React.useEffect(() => {
        setId(props.curStep)
        props.callback(props.curStep)
        if (tempHeader !== "") {
            props.setTempHeader("")
        }
    }, [props.curStep]);

    const onClickBack = () => {
        let curId = id
        curId--
        if (curId >= 0 && curId >= props.minimumStep) {
            setId(curId)
            props.callback(curId)
        }
    }

    const onClickForward = () => {
        let curId = id
        curId++
        if (curId !== props.headers.length && curId <= props.maximumStep) {
            setId(curId)
            props.callback(curId)
        }
    }




    return (
        <div className="progress-main">
            <div className="progress-header">
                {showNav ? <span id="progress-icon-back" onClick={onClickBack}><img src={isMobile ? BackProgress : BackProgressDesktop} alt="back" height={isMobile ? "22" : "30"} width="15" /></span> : null}
                {showNav ? <span id="progress-icon-forward" onClick={onClickForward}><img src={isMobile ? ForwardProgress : ForwardProgressDesktop} alt="back" height={isMobile ? "22" : "30"} width="15" /></span> : null}
                <SimpleViewSlider>
                    <span key={id}>{tempHeader === "" ? props.headers[id] : tempHeader}</span>
                </SimpleViewSlider>
            </div>
            <div ref={progressRef} className="progress-div">
                <div style={{ width: value === null ? "100%" : `${value}px` }} className="progress-bar"><hr className="progress-line" /></div>
                <div style={{ width: props.width }} className="progress" />
                <div style={{ height: 2 }}></div>
            </div>
        </div>
    )
}

