import { LabelImportant } from '@material-ui/icons';
import React, { useState } from 'react'

export default function Checkbox(props) {
    const [label, setLabel] = useState(props.disabled ? 'Deaktiveret' : 'Aktiveret');
    const [checked, setCheked] = useState(!props.disabled)

    const handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let lab = value ? 'Aktiveret' : 'Deaktiveret'
        setLabel(lab)
        setCheked(value)
        props.callback(props.id, !value)
    }

    return (
        <div>
            <input checked={checked} onChange={handleChange} type="checkbox" />
            <span style={{ position: 'relative', top: -2, left: 7, display: 'inline-block', width: 80}}>{label}</span>
        </div>
    )
}