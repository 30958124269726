import React from 'react'

export default () => {
    return (
        <iframe
            src="http://167.71.45.223:3000/public/dashboard/c647085a-d26b-466c-98c2-633fe1d55fdd"
            frameborder="0"
            style={{'position':'absolute', 'top': 0, 'left': 0, 'bottom':0, 'right':0, 'width':'100%', 'height':'100%', 'border':'none', 'padding':0}}
            allowtransparency
        ></iframe>
    )
}