import React from "react";
import MainHeader from "../subcomponents/header/MainHeader";
import CustomTextField from '../../../customComponents/CustomTextField';
import CustomButton from '../../../customComponents/CustomButton';
import { connect } from "react-redux";
import { setPageID } from "../../store/actions";
import StateManagementContext from '../../../../context/StateManagementContext';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import {
  PAGE_INDEX
} from "../constant";
import { Grid, Hidden } from "@material-ui/core";
import { isTablet, isIPad13, isMobile, isMobileOnly } from "react-device-detect";
import facade from '../../../../datafacade/datafacade'
import moment from 'moment'

class LicensePlate extends React.Component {
  static contextType = StateManagementContext
  state = {
    testPlate: "",
    available: false,
    error: {
      testPlateError: false
    }
  };

  componentDidMount = async () => {
    if (this.props.car !== undefined) {
      const available = await facade.isAvailableNow(moment().valueOf(), this.props.car.id)
      if (available) {
        const state = this.context
        this.setState({ available: true })
        state.flowSetTime(moment().valueOf(), moment().add(30, 'minutes').valueOf())
        state.flowSetSelectedTime(0)
      }
    }
  }

  onOptionChange = e => {
    if (this.state.error.testPlateError) this.setState({ error: { testPlateError: false } })
    this.setState({ testPlate: e.target.value });
  };

  handleClickPlate = e => {
    this.setState({ licensePlate: e.target.id })
  }

  onSubmit = e => {
    if (this.state.testPlate === "") {
      this.setState({ error: { testPlateError: true } })
    } else {
      const stateContext = this.context
      stateContext.flowSetTestPlate(this.state.testPlate)
      if (isTablet || isIPad13) {
        this.props.startFlow()
      } else {
        if (this.state.available) {
          this.props.setCurrentStep(1)
          this.props.setStepper(1)
          this.props.onClickContinueAvailable()
        } else {
          this.props.onClickContinue()
        }
      }
    }
  }

  onClickLicensePlate = (e) => {
    const { id } = e.target
    const stateContext = this.context
    stateContext.flowSetTestPlate(id)
    if (isTablet || isIPad13) {
      this.props.startFlow()
    } else {
      if (this.state.available) {
        this.props.setCurrentStep(1)
        this.props.setStepper(1)
        this.props.onClickContinueAvailable()
      } else {
        this.props.onClickContinue()
      }
    }
  }

  render() {
    const stateContext = this.context

    const ContinueButton = (
      <div className="text-center mt-4 btn-group" style={{ paddingTop: 20 }}>
        <div>
          <CustomButton fullWidth onClick={this.onSubmit}>
            Fortsæt
        </CustomButton>
        </div>
      </div>
    );

    const Plates = (
      <React.Fragment>
        <div style={{ fontFamily: 'bmwnextbo', fontSize: 18, textAlign: 'center', marginBottom: 10 }}>Senest anvendte</div>
        <div style={{ height: isTablet || isIPad13 ? 180 : 170, overflowY: 'auto', overflowX: 'hidden' }}>
          <GridList style={{ width: 235, margin: 'auto' }} cellHeight={65} cols={2}>
            {stateContext.plates.map((p, idx) => {
              return (
                <GridListTile style={{ marginTop: idx === 0 || idx === 1 ? 0 : -5 }} key={p} xs={6}><div id={p} onClick={this.onClickLicensePlate} className="plate-slot">{p}</div></GridListTile>
              )
            })}
          </GridList>
        </div>
      </React.Fragment>
    )

    return (
      <div style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
        <Grid container spacing={isTablet || isIPad13 ? 4 : 2} style={{ textAlign: "center" }}>
          {isMobileOnly ?
            <Grid item xs={12} style={{ marginTop: isMobile && !isTablet || isMobile && !isIPad13 ? 10 : 0 }}><MainHeader title="Indtast prøveplade" /></Grid> : null
          }
          <Grid item xs={12} style={{ marginTop: isMobileOnly ? -20 : 0, marginBottom: isMobileOnly ? 20 : 0 }}>
            <CustomTextField style={{ width: 285 }} required error={this.state.error.testPlateError} onBlur={() => window.scroll(0, 0)} name="testPlate" value={this.state.testPlate} onChange={this.onOptionChange} fullWidth label="Indtast prøveplade" />
          </Grid>
          <Grid item xs={12}>
            {Plates}
          </Grid>
          <Grid item xs={12} style={{ marginTop: isMobileOnly ? 10 : -10, paddingBottom: 50 }}>
            <CustomButton fullWidth onClick={this.onSubmit}>
              Fortsæt
            </CustomButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClickContinue: () => dispatch(setPageID(PAGE_INDEX.DAY_VIEW_2_2)),
    onClickContinueAvailable: () => dispatch(setPageID(PAGE_INDEX.TERMS_AND_CONDITIONS)),
  };
};

export default connect(null, mapDispatchToProps)(LicensePlate);
