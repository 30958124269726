import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '../../assets/icons/bmw_media_down_hov_b 1.svg';

export default function SimpleMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [label, setLabel] = React.useState(props.label)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const setStatus = async (status, id) => {
        if (status !== label) {
            const res = await props.callback(status, id)
            setLabel(res)
        }
        handleClose()
    }

    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                {label === undefined ? null : <span style={{ fontFamily: 'bmwnextli' }}>{label}&nbsp;</span>}<img src={MoreIcon} alt="more-icon" />
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {props.statuses.map(status => {
                    return (
                        <MenuItem key={status} onClick={() => { setStatus(status, props.id) }}>{status}</MenuItem>
                    )
                })}
            </Menu>
        </div>
    );
}
