import React, { useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import CustomTextField from '../../customComponents/CustomTextField';
import DeleteDepartment from './DeleteDepartment';
import datafacade from '../../../datafacade/datafacade';
import StateManagementContext from '../../../context/StateManagementContext';
import CustomButtonTrans from '../../customComponents/CustomButtonTrans'


export default function UpdateDepartmentForm(props) {
    const [nameError, setNameError] = React.useState(false);
    const [zipError, setZipError] = React.useState(false);
    const [addressError, setAddressError] = React.useState(false);
    const [cityError, setCityError] = React.useState(false);
    const [hasChanged, setHasChanged] = React.useState(false);
    const state = useContext(StateManagementContext)
    const [department, setDepartment] = React.useState({
        id: "",
        departmentName: "",
        address: "",
        postalCode: "",
        city: "",
        email: "",
        phone: "",
        lng: "",
        lat: ""
    })

    const handleChange = e => {
        setHasChanged(true)
        const { name, value } = e.target
        setNameError(false)
        setZipError(false)
        setAddressError(false)
        setCityError(false)
        setDepartment({
            ...department,
            [name]: value
        })
    }

    useEffect(() => {
        const dep = state.departments.find(dep => dep.id === props.departmentId)
        setDepartment(dep)
    }, [props, state.departments]);

    const save = (e) => {
        if (department.departmentName === "") {
            setNameError(true)
        } else if (department.address === "") {
            setAddressError(true)
        } else if (department.postalCode === "") {
            setZipError(true)
        } else if (department.city === "") {
            setCityError(true)
        } else {
            try {
                e.preventDefault()
                datafacade.updateDepartment(department)
                state.updateDepartment(department)
                props.handleClose()
            } catch (error) {
                console.log(error)
            }
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={8}>
                <Grid item md={4}>
                    <CustomTextField disabled name="id" fullWidth label="Id" value={department.id} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField required error={nameError} name="departmentName" fullWidth label="Afdelingsnavn" value={department.departmentName} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField required error={addressError} name="address" fullWidth label="Adresse" value={department.address} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField required error={zipError} name="postalCode" fullWidth label="Postnummer" value={department.postalCode} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField required error={cityError} name="city" fullWidth label="By" value={department.city} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField name="email" fullWidth label="Email" value={department.email} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField name="phone" fullWidth label="Telefon" value={department.phone} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField name="lat" fullWidth label="Lattitude" value={department.lat} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField name="lng" fullWidth label="Longitude" value={department.lng} onChange={handleChange} />
                </Grid>
            </Grid>
            <Grid container spacing={4} style={{ marginTop: 50 }}>
                <Grid item md={6} style={{ textAlign: 'right' }}>
                    <CustomButtonTrans disabled={!hasChanged} fullWidth onClick={save}>
                        Gem
                    </CustomButtonTrans>
                </Grid>
                <Grid item md={6} style={{ textAlign: 'left' }}>
                    <DeleteDepartment handleClose={props.handleClose} disable={department.id === state.currentUser.departmentId} dep={department} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}