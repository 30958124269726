import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const stylesBlue = () => ({
  root: {
    fontWeight: 'bold',
    fontFamily: 'bmwnextbo',
    fontSize: 15,
    'outline': '0',
    "-webkit-box-shadow": "none",
    'box-shadow': 'none',
    'background-color': '#1c69d4',
    'color': 'white',
    '-moz-border-radius': '7px',
    '-webkit-border-radius': '7px',
    'border-radius': '7px',
    'width': '250px',
    'height': '50px',
    'disabled': {
      'background-color': '#BBBBBB'
    },
    '&:hover': {
      background: '#0653b6',
      'outline': '0',
      "-webkit-box-shadow": "none",
      'box-shadow': 'none',
    }
  }
});



function CustomButton(props) {
  return <Button variant="contained" style={{width: props.width, height: props.height}} {...props} />;
}

export default withStyles(stylesBlue)(CustomButton);
