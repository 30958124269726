import React, { useEffect, useContext, useCallback, useState } from 'react';
import Auth from '../auth/Auth';
import Datafacade from '../datafacade/datafacade';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Header from '../components/Header/Header';
import MainWindow from '../components/MainHeaderWindow/MainWindow';
import { makeStyles } from '@material-ui/core/styles';
import StateManagementContext from '../context/StateManagementContext';
import TestCars from '../components/testcars/TestCars';
import TestCarsMini from '../components/testcars/TestCarsMini';
import Profile from '../components/profile/ProfileUpdate';
import DriversNote from '../components/driversnote/DriversNote';
import AdminPanel from '../components/admin/AdminPanel';
import Users from '../components/admin/users/Users';
import Bookings from '../components/admin/bookings/Bookings';
import Departments from '../components/admin/departments/Departments';
import Cars from '../components/admin/cars/Cars';
import { isTablet, isIPad13 } from 'react-device-detect';
import MessageBox from '../components/utilComponents/MessageBox';
import Overview from '../components/overview/Overview';
import ViewMore from '../assets/icons/bmw_loc-ind_arrow-down.svg';
import { animateScroll } from "react-scroll";


const useStyles = makeStyles(theme => ({
    // '@global': {
    //     body: {
    //         'background': `url(https://tds.jan-nygaard.dk/td/images/internal.jpg) no-repeat center center fixed`,
    //         '-webkit-background-size': 'cover',
    //         '-moz-background-size': 'cover',
    //         '-o-background-size': 'cover',
    //         'background-size': 'cover',
    //         // 'overflow': 'hidden'
    //     }
    // },
    main: {
        'background': `url(https://tds.jan-nygaard.dk/td/images/internal.jpg) no-repeat center center fixed`,
        '-webkit-background-size': 'cover',
        '-moz-background-size': 'cover',
        '-o-background-size': 'cover',
        'background-size': 'cover',
        height: '100vh'
    },
    mainWindow: {
        // position: 'absolute',
        // top: 0,
        // margin: 'auto'
    },
    overview: {
        height: '100vh',
    },
    overviewContent: {
        maxWidth: 1300,
        '@media (max-width: 1300px)': {
            maxWidth: 900
        },
        margin: 'auto',
        paddingTop: 53
    },
    expand: {
        position: "fixed",
        bottom: 0,
        left: '48%',
        cursor: 'pointer'
    },
    footer: {
        color: 'white',
        position: "fixed",
        bottom: 0,
        left: 5,
        fontSize: 10
    }
}));


export default function Workspace(props) {
    const xs = useMediaQuery(theme => theme.breakpoints.between(320, 767));
    const renderChildcomponent = (key) => {
        setKey(key)
        if (key === '') {
            setComponentToRender(null)
        } else {
            if (xs) {
                setOpenSubMenu(true)
                // setTimeout(() => {
                setComponentToRender(childComponents[key])
                // }, 1000)
            } else {
                setComponentToRender(childComponents[key])
            }

        }
    }

    const classes = useStyles();
    const state = useContext(StateManagementContext)
    const [openWindow, setOpenWindow] = React.useState(false)
    const [calOpen, setCalOpen] = React.useState(false)
    const [openSubMenu, setOpenSubMenu] = React.useState(false)
    const [key, setKey] = React.useState('')
    const [version, setVersion] = React.useState('')
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    const openCal = () => {
        setCalOpen(calOpen ? false : true)
    }

    const handleOpen = (show) => {
        if (isTablet || isIPad13) {
            if (!show) setComponentToRender(null)
        }
        if (xs && !show) {

            setOpenSubMenu(false)
            setTimeout(() => {
                setComponentToRender(null)
                setOpenWindow(false)
            }, 650)
        } else {
            setOpenWindow(show)
        }
    }

    const [componentToRender, setComponentToRender] = React.useState(null)
    const childComponents = {
        'driversnote': { component: <DriversNote openSubMenu={openSubMenu} handleOpen={handleOpen} openCal={openCal} />, size: 'lg' },
        'testcars': { component: <TestCars openSubMenu={openSubMenu} handleOpen={handleOpen} openCal={openCal} />, size: 'lg' },
        'testcarsMini': { component: <TestCarsMini openSubMenu={openSubMenu} handleOpen={handleOpen} openCal={openCal} />, size: 'lg' },
        'admin': { component: <AdminPanel render={renderChildcomponent} />, size: 'lg' },
        'profile': { component: <Profile openSubMenu={openSubMenu} handleOpen={handleOpen} />, size: 'sm' },
        'users': { component: <Users />, size: 'lg' },
        'departments': { component: <Departments />, size: 'lg' },
        'cars': { component: <Cars />, size: 'lg' },
        'customers': { component: <Bookings />, size: 'lg' },
    }

    const logout = () => {
        const auth = new Auth()
        auth.logout()
        props.history.replace('/login');
    }

    const sortCarsBySeries = (cars) => {
        cars.sort((a, b) => (a.model > b.model) ? 1 : -1)
    }


    const setState = async () => {
        state.setExternalLead(false)
        const auth = new Auth()
        const email = auth.getProfile().sub
        const cars = await Datafacade.getAllCars()
        sortCarsBySeries(cars)
        state.setCars(cars)
        const user = await Datafacade.getUserDetails(email)
        state.setCurrentUser(user)
        const departments = await Datafacade.getAllDepartments()
        state.setDepartments(departments)
        const usersInDep = await Datafacade.getAllUsersFromDepartment(user.departmentId)
        state.setUsers(usersInDep)
        const plates = await Datafacade.getPlates(user.departmentId)
        state.setPlates(plates)
        state.setHasFetched(true)
        fetch('/meta.json')
            .then((response) => response.json())
            .then((meta) => {
                setVersion(`Version ${meta.version}`)
            });
    }

    useEffect(() => {
        setState()
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        const opt = {
            duration: 3000
        }
        animateScroll.scrollToBottom(opt);
    }

    return (
        <React.Fragment>
            <MessageBox />
            {xs || scrollPosition > 10 ? null : <div className={classes.expand} onClick={scrollToTop}>
                <img src={ViewMore} height={50} />
            </div>}
            <div className={classes.main} id={"App"}>
                <Header
                    render={renderChildcomponent}
                    logout={logout}
                    handleOpenWindow={handleOpen}
                    handleHeaderOpen={openWindow}
                    pageWrapId={"page-wrap"}
                    outerContainerId={"App"}
                />
                <MainWindow child={key} openSubMenu={openSubMenu} open={openWindow} handleOpen={handleOpen} calOpen={calOpen} size={componentToRender === null ? null : componentToRender.size}>
                    {componentToRender === null ? null : componentToRender.component}
                </MainWindow>
            </div>
            {
                xs ? null :
                <div id="overview-scroll" className={classes.overview}>
                    <div className={classes.overviewContent}>
                        <Overview />
                    </div>
                </div>
            }
            <div className={classes.footer}>
                {version}
            </div>

        </React.Fragment>
    )
}