import React from "react";
import CustomToggleButton from '../../../customComponents/CustomToggleButton';
import { CircularProgress, Grid, Hidden } from '@material-ui/core';
import { ToggleButtonGroup } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import { MobileView, isTablet, isIPad13, isMobileOnly, isBrowser } from "react-device-detect";
import AddIcon from '../../../../assets/icons/Add.png';
import CloseIcon from '../../../../assets/icons/minus.png';
import CustomButton from '../../../customComponents/CustomButton';
import CustomTextField from '../../../customComponents/CustomTextField';
import Facade from '../../../../datafacade/datafacade';
import StateManagementContext from '../../../../context/StateManagementContext';
import { animateScroll } from "react-scroll";
import { connect } from "react-redux";
import { setPageID } from "../../store/actions";
import facade from '../../../../datafacade/datafacade';
import {
  PAGE_INDEX,
  BUTTON_LABELS,
  PERSONAL_MODES, PAGE_INDEX_EXTERNAL
} from "../constant";
import { SmsOutlined } from "@material-ui/icons";
import MessageBox from "../../../utilComponents/MessageBox";

const StyledToggleButtonGroup = withStyles(theme => ({
  root: {
    alignItems: 'center',
    'margin': theme.spacing(4, 0)
  },
  grouped: {
    '&:not(:first-child)': {
      position: 'relative',
      'border-left': '1px solid rgba(0, 0, 0, 0.12)',
      right: '14px',
      [theme.breakpoints.up(400)]: {
        right: '-6px'
      },
      [theme.breakpoints.up(700)]: {
        right: '-32px'
      },
    },
    '&:first-child': {
      position: 'relative',
      left: '-24px',
      [theme.breakpoints.up(400)]: {
        left: '-8px'
      },
      [theme.breakpoints.up(700)]: {
        left: '10px'
      },
    },
  },
}))(ToggleButtonGroup);

class LastInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "",
      showDurationPicker: false,
      hours: 0,
      minutes: 30,
      phone: "+45",
      email: "",
      b2c: null,
      customerFullName: "",
      customer: {
        firstName: "",
        lastName: "",
        address: "",
        zip: "",
        city: ""
      },
      driver: {
        fullName: "",
        driverLicenseNumber: ""
      },
      price: 0,
      km: 0,
      note: "",
      showDriver: false,
      showPrice: false,
      showNote: false,
      showMore: false,
      error: {
        emailError: false,
        phoneError: false,
        b2cError: false,
        fullNameError: false,
      },
      cprError: false,
      errorMsg: "",
      loading: false,
      knownCustomer: false,
      missingSign: true
    };
  }
  static contextType = StateManagementContext

  componentDidMount = async () => {
    const stateContext = this.context
    if (!stateContext.external) {
      if (stateContext.flow.customer.cpr !== undefined && stateContext.flow.customer.cpr !== "" && stateContext.flow.customer.address === undefined) {
        this.setState({ loading: true })
        try {
          let res = await facade.searchCpr(stateContext.flow.customer.cpr.replace("-", ""), stateContext.flow.customer.driverLicenseNumber, stateContext.currentUser)

          if (res.id !== undefined) {
            let { termsOfUse, personalData, isSubscribed } = stateContext.flow.customer
            let customer = res.customer
            customer.termsOfUse = termsOfUse
            customer.personalData = personalData
            customer.isSubscribed = isSubscribed
            delete customer.id
            stateContext.flowSetCustomer(customer)
            if (res.signature !== undefined) {
              stateContext.flowSetSignature(res.signature)
            }
            this.setState({ loading: false })
          } else {
            let { driverLicenseNumber, id, email, phone, b2c, termsOfUse, personalData, isSubscribed } = stateContext.flow.customer
            res.driverLicenseNumber = driverLicenseNumber
            res.email = email
            res.phone = phone
            res.b2c = b2c
            res.termsOfUse = termsOfUse
            res.personalData = personalData
            res.isSubscribed = isSubscribed
            if (stateContext.currentEvent.signature !== undefined) {
              this.setState({ missingSign: false })
            }
            if (id !== undefined) res.id = id
            stateContext.flowSetCustomer(res)
            this.setState({ loading: false })
          }
        } catch (err) {
          stateContext.flowSetCpr("")
          stateContext.flowSetDriverLicense("")
          const box = {show: true, msg: err.detailMessage, variant: 'error'}
          stateContext.createMessageBox(box)
          if (isTablet || isIPad13) {
            this.props.setMaxStep(3)
            this.props.setComponent(3)
            this.props.setTempHeader("Manuel indtastning")
            this.setState({ loading: false, cprError: true})
          } else {
            this.props.setCurrentStep(1)
            this.props.setStepper(2)
            this.props.setTempHeader("Manuel indtastning")
            this.setState({ loading: false, cprError: true })
            this.props.onFailCpr()
          }
        }
      }
    }
    this.setState({ mode: PERSONAL_MODES[0] });
    let customer = stateContext.flow.customer
    const price = stateContext.flow.price
    const km = stateContext.flow.km
    if (stateContext.flow.sign === undefined || stateContext.flow.sign === "" || stateContext.flow.sign === null) {
      this.setState({ missingSign: true })
    } else {
      this.setState({ missingSign: false })
    }
    if (customer.email !== undefined) {
      const phone = customer.phone
      const email = customer.email
      const b2c = customer.b2c
      const customerFullName = `${customer.firstName} ${customer.lastName}`
      this.setState({ phone, email, b2c, customerFullName })
    }
    if (stateContext.flow.drivers.length > 0) {
      const driver = {
        fullName: `${stateContext.flow.drivers[0].firstName} ${stateContext.flow.drivers[0].lastName}`,
        driverLicenseNumber: `${stateContext.flow.drivers[0].driverLicenseNumber}`,
      }
      this.setState({ driver })
    }
    this.setState({ customer, km, price })

  }

  onModeChange = e => {
    this.setState({ mode: e.target.value });
  };

  onChange = duration => {
    const { hours, minutes } = duration;
    this.setState({ hours, minutes });
  };

  closeDuration = () => {
    this.setState({ showDurationPicker: false });
  };

  showDuration = () => {
    this.setState(this.state.showDurationPicker ? { showDurationPicker: false } : { showDurationPicker: true });
  };

  onChangeDuration = () => {
    this.showDuration();
  };

  isChecked = mode => {
    return this.state.mode === mode;
  };

  handleB2c = (event, b2c) => {
    if (b2c !== null) {
      this.setState({ b2c }, this.scrollToBottom)
    }
  };

  onInputChange = e => {
    this.setState({ error: { emailError: false, phoneError: false, b2cError: false, fullNameError: false } })
    let { name, value } = e.target;
    if (name === "phone") {
      const countryCode = "+45"
      if (value.substring(0, 3) !== countryCode) {
        value = countryCode
      }
    }
    this.setState({ [name]: value });
  }

  onDriverInputChange = e => {
    const { name, value } = e.target
    this.setState({ driver: { ...this.state.driver, [name]: value } });
  }

  handleDriverClick = e => {
    if (this.state.showDriver) {
      this.setState({ showDriver: false })
      this.setState({ driver: { firstName: "", lastName: "", driverLicenseNumber: "" } });
    } else {
      this.setState({ showDriver: true }, this.scrollToBottom)
    }
  }

  handleNoteClick = e => {
    if (this.state.showNote) {
      this.setState({ showNote: false, note: "" })
    } else {
      this.setState({ showNote: true }, this.scrollToBottom)
    }
  }

  scrollToBottom() {
    if (isMobileOnly) {
      animateScroll.scrollToBottom({
        containerId: "container-lastInfo"
      });
    }
  }

  scrollToPhoneView() {
    if (isMobileOnly) {
      animateScroll.scrollTo(110, {
        containerId: "container-lastInfo"
      })
    }
  }

  handlePriceClick = e => {
    if (this.state.showPrice) {
      this.setState({ showPrice: false })
      this.setState({ price: 0, note: "", driver: { firstName: "", lastName: "", driverLicenseNumber: "" } });
    } else {
      this.setState({ showPrice: true }, this.scrollToBottom)
    }
  }

  handleMoreClick = e => {
    if (this.state.showMore) {
      this.setState({ showMore: false })
      this.setState({ price: 0, note: "", driver: { firstName: "", lastName: "", driverLicenseNumber: "" } });
    } else {
      this.setState({ showMore: true }, this.scrollToBottom)
    }
  }

  onSubmitMobile = e => {
    const stateContext = this.context

    if (this.state.b2c === null) {
      this.setState({ error: { b2cError: true } })
    } else if (this.state.phone.length < 11 || this.state.phone.length > 11) {
      this.setState({ error: { phoneError: true } })
    } else if (this.state.email === "" || !this.state.email.includes('@')) {
      this.setState({ error: { emailError: true } })
    } else if (stateContext.external && this.state.customerFullName === "") {
      if (this.state.customerFullName === "") {
        this.setState({ error: { fullNameError: true } })
      }
    } else {
      const { email, phone, b2c, driver, price, km, note, customerFullName } = this.state
      if (driver.fullName !== "" && driver.driverLicenseNumber !== "") {
        const driverArr = driver.fullName.split(" ")
        const formattedDriver = {
          firstName: driverArr[0],
          lastName: driverArr.slice(1).join(" "),
          driverLicenseNumber: driver.driverLicenseNumber
        }
        stateContext.flowSetDriver(formattedDriver)
      }
      if (stateContext.external) {
        const customerArr = customerFullName.split(" ")
        const formattedCustomer = {
          firstName: customerArr[0],
          lastName: customerArr.slice(1).join(" "),
        }
        const cus = stateContext.flow.customer
        cus.firstName = formattedCustomer.firstName
        cus.lastName = formattedCustomer.lastName
        delete cus.cpr
        delete cus.driverLicenseNumber
        delete cus.license
        stateContext.flowSetCustomer(cus)
      }
      stateContext.flowSetLastInfo(email, phone, b2c, price, km, note)
      if (stateContext.flow.start === null) {
        const start = new Date().getTime()
        const end = start + (1000 * 60 * 30)
        stateContext.flowSetTime(start, end)
      }

      if (isTablet || isIPad13 || isBrowser) {
        if (stateContext.external) {
          this.props.setCurStep(3)
          this.props.setMaxStep(3)
        } else {
          if (this.state.missingSign) {
            this.props.setCurStep(4)
            this.props.setMaxStep(4)
          } else {
            this.props.setCurStep(5)
          }

        }
      } else {
        if (stateContext.external) {
          this.props.onClickContinueExternal()
          this.props.setStepper(3)
          this.props.setCurrentStep(3)
        } else {
          if (this.state.missingSign) {
            this.props.setStepper(4)
            this.props.setCurrentStep(4)
            this.props.onClickToSignature()
          } else {
            this.props.onClickSaveAndClose()
            this.props.setStepper(5)
            this.props.setCurrentStep(5)
          }
        }
      }
    }
  }

  render() {
    const { customer, showMore } = this.state;
    const stateContext = this.context

    const DriverView = (
      <div>
        {!stateContext.external ? <div className="last-info-header">Tilføj flere førere</div> : null}
        <CustomTextField onBlur={() => window.scroll(0, 0)} name="fullName" value={this.state.driver.fullName} onChange={this.onDriverInputChange} fullWidth label="Fulde navn" />
        <div style={{ paddingTop: 8, marginBottom: stateContext.external ? 20 : 0 }}>
          <CustomTextField type="tel" onBlur={() => window.scroll(0, 0)} name="driverLicenseNumber" value={this.state.driver.driverLicenseNumber} onChange={this.onDriverInputChange} fullWidth label="Kørekortnummer" />
        </div>
      </div>
    );

    const PriceView = (
      <div>
        <div className="last-info-header">Udvidet lejebetingelser</div>
        <CustomTextField type="tel" onBlur={() => window.scroll(0, 0)} name="price" value={this.state.price} onChange={this.onInputChange} fullWidth label="Lejeomkostninger" />
        <div style={{ paddingTop: 8 }}>
          <CustomTextField type="tel" onBlur={() => window.scroll(0, 0)} name="km" value={this.state.km} onChange={this.onInputChange} fullWidth label="Kilometer i lejeperioden" />
        </div>
      </div>
    );

    const NoteView = (
      <div style={{ marginBottom: 20 }}>
        <div className="last-info-header">Tilføj note</div>
        <CustomTextField type="text" onBlur={() => window.scroll(0, 0)} name="note" value={this.state.note} onChange={this.onInputChange} fullWidth label="Note" />
      </div>
    );


    const ModeButtonsView = (
      <div className="d-flex justify-content-around personal-business p-4">
        <div style={{ textAlign: 'center' }}>
          <StyledToggleButtonGroup value={this.state.b2c} exclusive onChange={this.handleB2c}>
            <CustomToggleButton height={55} width={120} value={true}>
              Privat
            </CustomToggleButton>
            <CustomToggleButton height={55} width={120} value={false}>
              Erhverv
            </CustomToggleButton>
          </StyledToggleButtonGroup>
        </div>
      </div>
    );


    return (
        <div id="container-lastInfo" className={isTablet || isIPad13 ? "overflow" : "last-info overflow"} style={{ paddingLeft: 63, paddingRight: 63, paddingTop: isTablet || isIPad13 ? 33 : 10, height: isTablet || isIPad13 ? 500 : '' }}>
          <Grid container spacing={isTablet || isIPad13 ? 4 : 2} style={{ textAlign: "center" }}>
            {!stateContext.external ?
              <Grid item xs={12} sm={6}>
                {this.state.loading ?
                  <div style={{ textAlign: "center", marginTop: 30 }}>
                    <CircularProgress />
                  </div>
                  :
                  this.state.cprError ? <div></div> :
                    <div style={{ fontSize: 16, fontFamily: 'bmwnextli' }}>
                      <div>{`${customer.firstName} ${customer.lastName}`}</div>
                      <div>{`${customer.address}`}</div>
                      <div>{`${customer.zip} ${customer.city}`}</div>
                      <div style={{ marginTop: 10 }}>Kørekort: {customer.driverLicenseNumber}</div>
                    </div>}
              </Grid>
              :
              <Grid item xs={12} sm={6}>
                <CustomTextField style={{ width: isBrowser ? 300 : "" }} required error={this.state.error.fullNameError} type="text" onBlur={() => { this.scrollToPhoneView() }} onBlur={() => window.scroll(0, 0)} name="customerFullName" value={this.state.customerFullName} onChange={this.onInputChange} fullWidth label="Fulde navn" />
              </Grid>
            }
            <Hidden smDown>
              <Grid item sm={6}>
                {ModeButtonsView}
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={6} style={isTablet || isIPad13 ? { marginTop: -32 } : {}}>
              <CustomTextField style={{ width: isBrowser ? 300 : "" }} required error={this.state.error.emailError} type="email" onBlur={() => { this.scrollToPhoneView() }} onBlur={() => window.scroll(0, 0)} name="email" value={this.state.email} onChange={this.onInputChange} fullWidth label="Email" />
            </Grid>
            <Grid item xs={12} sm={6} style={isTablet || isIPad13 ? { marginTop: -32 } : {}}>
              <CustomTextField style={{ width: isBrowser ? 300 : "" }} required error={this.state.error.phoneError} type="tel" onBlur={() => { this.scrollToPhoneView() }} onBlur={() => window.scroll(0, 0)} name="phone" value={this.state.phone} onChange={this.onInputChange} fullWidth label="Telefonnummer" />
            </Grid>
            <Hidden smUp>
              <Grid item xs={12}>
                {ModeButtonsView}
              </Grid>
            </Hidden>
            <Grid item xs={12} style={{ marginTop: isMobileOnly ? -35 : "" }}>
              <div style={{ paddingBottom: isMobileOnly ? 32 : 0, padding: 16 }} onClick={this.handleMoreClick}>
                <img alt="add" height="25" width="25" src={this.state.showMore ? CloseIcon : AddIcon}></img>
                <span className="ml-2" style={{ paddingLeft: 10, fontSize: 17, fontFamily: 'bmwnextli', position: 'relative', top: -5 }}>{stateContext.external ? "Tilføj flere førere" : "Flere muligheder"}</span>
              </div>
            </Grid>
            {showMore ?
              stateContext.external ?
                isTablet || isIPad13 ?
                  <React.Fragment>
                    <Grid item xs={6} style={{ marginTop: -50 }}>
                      <CustomTextField onBlur={() => window.scroll(0, 0)} name="fullName" value={this.state.driver.fullName} onChange={this.onDriverInputChange} fullWidth label="Fulde navn" />
                    </Grid>
                    <Grid item xs={6} style={{ marginTop: -50 }}>
                      <CustomTextField type="tel" onBlur={() => window.scroll(0, 0)} name="driverLicenseNumber" value={this.state.driver.driverLicenseNumber} onChange={this.onDriverInputChange} fullWidth label="Kørekortnummer" />
                    </Grid>
                  </React.Fragment>
                  :
                  <Grid item xs={12} style={{ marginTop: -30 }}>
                    {DriverView}
                  </Grid>
                :
                <React.Fragment>
                  <Grid item xs={12} sm={4}>{PriceView}</Grid>
                  <Grid item xs={12} sm={4}>{DriverView}</Grid>
                  <Grid item xs={12} sm={4}>{NoteView}</Grid>
                </React.Fragment>
              : null
            }
            <Grid item xs={12} style={{ paddingBottom: isIPad13 || isTablet ? 150 : "", paddingTop: isIPad13 || isTablet ? 20 : "" }}>
              <CustomButton fullWidth onClick={this.onSubmitMobile}>
                {!this.state.missingSign ? 'Opret prøvekørsel' : 'Fortsæt'}
              </CustomButton>
            </Grid>
          </Grid>
        </div >
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClickContinue: () => dispatch(setPageID(PAGE_INDEX.SIGNATURE)),
    onClickContinueExternal: () => dispatch(setPageID(PAGE_INDEX_EXTERNAL.SIGNATURE)),
    onClickSaveAndClose: () => dispatch(setPageID(PAGE_INDEX.DONE)),
    onFailCpr: () => dispatch(setPageID(PAGE_INDEX.SEARCH_CUSTOMER)),
    onClickToSignature: () => dispatch(setPageID(PAGE_INDEX.SIGNATURE))
  };
};

export default connect(null, mapDispatchToProps)(LastInformation);
