import React, { createRef } from "react";
import { connect } from "react-redux";
import { setDate } from "../../../store/actions";
import { dayIDsInWeek, getResponsiveWidth } from "../../utils";
import { DATE_FORMAT } from "../../constant";
import moment from "moment";

class WeekView extends React.Component {
  getDaysFromWeek = (week) => {
    return dayIDsInWeek().map((d) => moment().week(week).day(d));
  };

  getDaysFromWeek1 = (week) => {
    return dayIDsInWeek().map((d) => moment().week(week).day(d));
  };

  weekViewRef = createRef();

  isDayChecked = (day) => {
    const selectedDay = moment(this.props.selectedDate, DATE_FORMAT).date();
    return selectedDay === day;
  };

  onDayChange = (e) => {
    const selectedDay = e.target.value;
    const selected = moment(this.props.selectedDate, DATE_FORMAT);
    this.updateDate(selected.year(), selected.month(), selectedDay);

  };

  updateDate(yr, mon, day) {
    const selectedDate = moment(new Date(yr, mon, day), DATE_FORMAT);
    this.props.setDate(selectedDate);
    this.props.updateTimeSlots(new Date(selectedDate.toString()))
  }

  isTodayInWeek(week, date) {
    let id = undefined
    dayIDsInWeek().forEach((d, idx) => {
      if (moment(date).isSame(moment().week(week).day(d).format("YYYY-MM-DD"))) id = idx
    })
    return id
  }

  render() {
    const width = getResponsiveWidth();
    const { selectedDate } = this.props;
    const weekNo = moment(selectedDate, DATE_FORMAT).week();
    const selectedDay = moment(selectedDate, DATE_FORMAT).date();
    const date = moment(selectedDate, DATE_FORMAT);

    const todayStyle = (id) => {
      return {
        transform: `translateX(${(id * 1.14) * width + 15}px`,
        borderRadius: "50%",
        color: "white",
        paddingLeft: 1,
        backgroundColor: "#1c69d4",
        fontFamily: "bmwnextbo",
      }
    }

    const styleDayPosition = (id, day) => {
      if (Math.abs(day - selectedDay) > 6) {
        return {
          display: "none",
        };
      } else 
      if (day === selectedDay) {
        return {
          transform: `translateX(${(id * 1.14) * width + 15}px`,
          borderRadius: "50%",
          color: "black",
          paddingLeft: 1,
          fontFamily: "bmwnextbo",

        };
      } else {
        return {
          transform: `translateX(${(id * 1.14) * width + 15}px`,
        };
      }
    };

    return (
      <div ref={this.weekViewRef} className="week-view">
        <div className="week-days d-flex">
          {this.getDaysFromWeek(weekNo).map((day, id) => {
            let today = false
            if (moment(day.format("YYYY-MM-DD")).isSame(moment().format("YYYY-MM-DD"))) today = true
            return <div key={id} className="day-line">
              <label
                className="position-absolute day"
                style={today ? todayStyle(id) : styleDayPosition(id, day.date())}

              >

                <input
                  type="radio"
                  checked={this.isDayChecked(day.date())}
                  onChange={this.onDayChange}
                  value={day.date()}
                />

                <div className="day-width">{day.date()}</div>
              </label>
            </div>
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedDate: state.selectedDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDate: (date) => dispatch(setDate(date)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WeekView);
