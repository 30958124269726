import React, { useState, useContext, useEffect } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import './subcomponents/desktopFlow.css'
import Time from './pages/Time';
import SimpleViewSlider from "react-view-slider/simple";
import LastDetails from './pages/LastDetails';
import Done from './pages/Done';
import Calendar from './pages/Calendar';
import ProgressBar from '../utilComponents/Progress/ProgressBar';
import { isTablet, isIPad13, isBrowser } from 'react-device-detect';
import Picture from '../calendar/components/pages/picture';
import SearchCustomer from '../calendar/components/pages/search-customer';
import ManualCustomer from '../calendar/components/pages/manual-customer';
import LastInformation from '../calendar/components/pages/last-information';
import TermsConditions from '../calendar/components/pages/terms-conditions';
import Signature from '../calendar/components/pages/signature';
import StateManagementContext from '../../context/StateManagementContext';
import LicensePlate from '../calendar/components/pages/license-plate';
import facade from '../../datafacade/datafacade';
import moment from 'moment';



export default function DesktopFlow(props) {
    const [currentStep, setCurrentStep] = useState(0)
    const [maximumStep, setMaximumStep] = useState(0)
    const [componentToRender, setComponentToRender] = useState(0)
    const [goBackToFetch, setGoBackToFetch] = useState(false)
    const [loading, setLoading] = useState(true)
    const headers = ["Tidspunkt", "Oplysninger", "Bekræftelse"]
    const tabletHeaders = ["Tidspunkt", "Vilkår & betingelser", "Kørekort", "Oplysninger", "Underskrift", "Bekræftelse"]
    const externalHeaders = ["Tidspunkt", "Vilkår & betingelser", "Oplysninger", "Underskrift", "Bekræftelse"]
    const [tempHeader, setTempHeader] = useState("")
    const [showNav, setShowNav] = useState(true)
    const state = useContext(StateManagementContext)
    const [testPlate, setTestPlate] = useState(null)
    const [changeFrame, setChangeFrame] = useState(false)
    const [noDif, setNoDif] = useState(false)
    const [available, setAvailable] = useState(false)

    const handleGoBackToFetch = (goBack) => {
        setGoBackToFetch(goBack)
    }

    const setComponent = (id) => {
        setComponentToRender(id)
    }

    const setMaxStep = (id) => {
        if (maximumStep < id) {
            setMaximumStep(id)
        }
    }

    const setCurStep = (id) => {
        setCurrentStep(id)
    }

    const setHeader = (header) => {
        setTempHeader(header)
    }

    const handleShowNav = (show) => {
        setShowNav(show)
    }

    const startFlow = () => {
        setTestPlate(false)
        setShowNav(true)
        if (available) {
            setHeader(tabletHeaders[1])
        } else {
            setHeader(tabletHeaders[0])
        }
    }

    useEffect(() => {
        if (!props.noLicense) {
            const car = state.cars.find(car => car.id === state.flow.car.id)
            setTestPlate(car.licensePlate === "" || !car.isRegistered && car.licensePlate === "")
        } else {
            if (!state.external) {
                setCurStep(1)
                setMaxStep(1)
                setComponent(1)
            }
            setTestPlate(false)
        }
    }, [])

    useEffect(() => {
        if (!state.external) {
            if (!noDif) {
                if (state.flow.toFinish && !changeFrame) {
                    const car = state.cars.find(car => car.id === state.flow.car.id)
                    setChangeFrame(true)
                    if (car.licensePlate === "" || !car.isRegistered && car.licensePlate === "") {
                        setTestPlate(car.licensePlate === "" || !car.isRegistered && car.licensePlate === "")
                    } else {
                        if (state.flow.customer.termsOfUse !== undefined) {
                            setCurStep(2)
                            setMaxStep(2)
                            setComponent(2)
                        } else {
                            setCurStep(1)
                            setMaxStep(1)
                            setComponent(1)
                        }
                    }
                } else if (state.flow.toFinish && changeFrame) {
                    if (state.flow.customer.termsOfUse !== undefined) {
                        setCurStep(2)
                        setMaxStep(2)
                        setComponent(2)
                    } else {
                        setCurStep(1)
                        setMaxStep(1)
                        setComponent(1)
                    }
                    setNoDif(true)
                }
            }
        }
    }, [state, changeFrame])

    useEffect(() => {
        if (testPlate && isTablet || testPlate && isIPad13) {
            setHeader("Indtast prøveplade")
            handleShowNav(false)
        }
    }, [testPlate, currentStep])

    useEffect(() => {
        if (!state.external) {
            checkAvailability()
        } else {
            setLoading(false)
        }
        return () => {
            state.flowClear()
        }
    }, [])

    const checkAvailability = async () => {
        if (props.isNote) {
            if (isIPad13 || isTablet) {
                if (loading && state.flow.car.id !== undefined) {
                    const available = await facade.isAvailableNow(moment().valueOf(), state.flow.car.id)
                    if (available) {
                        setMaxStep(1)
                        setCurStep(1)
                        setComponent(1)
                        state.flowSetTime(moment().valueOf(), moment().add(30, 'minutes').valueOf())
                        state.flowSetSelectedTime(0)
                        setAvailable(true)
                    }

                }
            }
        }
        setLoading(false)
    }


    const components = [
        <Time setHeader={setHeader} goBackToFetch={goBackToFetch} setMaxStep={setMaxStep} setCurStep={setCurStep} setComponent={setComponent} />,
        <LastDetails setHeader={setHeader} setMaxStep={setMaxStep} setCurStep={setCurStep} setComponent={setComponent} handleShowNav={handleShowNav} />,
        <Done setHeader={setHeader} handleGoBackToFetch={handleGoBackToFetch} flow={props.flow} handleOpen={props.handleOpen} setCurStep={setCurStep} handleShowNav={handleShowNav} />,
    ]

    const tabletComponents = [
        <Time setHeader={setHeader} goBackToFetch={goBackToFetch} setMaxStep={setMaxStep} setCurStep={setCurStep} setComponent={setComponent} />,
        <TermsConditions setTempHeader={setHeader} car={props.car} setComponent={setComponent} setCurStep={setCurStep} setMaxStep={setMaxStep} />,
        <Picture setTempHeader={setHeader} setMaxStep={setMaxStep} setComponent={setComponent} setCurStep={setCurStep} />,
        <SearchCustomer setTempHeader={setHeader} car={props.car} setComponent={setComponent} setCurStep={setCurStep} setMaxStep={setMaxStep} />,
        <ManualCustomer setTempHeader={setHeader} car={props.car} setComponent={setComponent} setCurStep={setCurStep} setMaxStep={setMaxStep} />,
        <LastInformation setTempHeader={setHeader} car={props.car} setComponent={setComponent} setCurStep={setCurStep} setMaxStep={setMaxStep} />,
        <Signature setTempHeader={setHeader} car={props.car} setComponent={setComponent} setCurStep={setCurStep} setMaxStep={setMaxStep} />,
        <Done handleShowNav={handleShowNav} setHeader={setHeader} handleGoBackToFetch={handleGoBackToFetch} flow={props.flow} handleOpen={props.handleOpen} setCurStep={setCurStep} />,
    ]

    const externalComponents = [
        <Time setHeader={setHeader} goBackToFetch={goBackToFetch} setMaxStep={setMaxStep} setCurStep={setCurStep} setComponent={setComponent} />,
        <TermsConditions setTempHeader={setHeader} car={props.car} setComponent={setComponent} setCurStep={setCurStep} setMaxStep={setMaxStep} />,
        isTablet || isIPad13 ? <LastInformation setTempHeader={setHeader} car={props.car} setComponent={setComponent} setCurStep={setCurStep} setMaxStep={setMaxStep} /> : <LastDetails setHeader={setHeader} setMaxStep={setMaxStep} setCurStep={setCurStep} setComponent={setComponent} handleShowNav={handleShowNav} />,
        <Signature setTempHeader={setHeader} car={props.car} setComponent={setComponent} setCurStep={setCurStep} setMaxStep={setMaxStep} />,
        <Done handleShowNav={handleShowNav} setHeader={setHeader} handleGoBackToFetch={handleGoBackToFetch} flow={props.flow} handleOpen={props.handleOpen} setCurStep={setCurStep} />,
    ]

    const calendarComponents = [
        <Calendar continueFlow={props.continueFlow} />
    ]

    const callback = (id) => {
        if (isTablet && !state.external || isIPad13 && !state.external) {
            switch (id) {
                case 0:
                    setComponent(0)
                    break;
                case 1:
                    setComponent(1)
                    break;
                case 2:
                    setComponent(2)
                    break;
                case 3:
                    setComponent(5)
                    break;
                case 4:
                    setComponent(6)
                    break;
                case 5:
                    setComponent(7)
                    break;
            }
        } else if (state.external) {
            if (isTablet || isIPad13) {
                switch (id) {
                    case 0:
                        setComponent(0)
                        break;
                    case 1:
                        setComponent(1)
                        break;
                    case 2:
                        setComponent(2)
                        break;
                    case 3:
                        setComponent(3)
                        break;
                    case 4:
                        setComponent(4)
                        break;
                }
            } else {
                switch (id) {
                    case 0:
                        setComponent(0)
                        break;
                    case 1:
                        setComponent(1)
                        break;
                    case 2:
                        setComponent(2)
                        break;
                    case 3:
                    case 4:
                        setComponent(4)
                        break;
                }
            }
        } else {
            setComponent(id)
        }
        setCurStep(id)
    }



    return (
        <React.Fragment>
            {props.isNote && !loading ?
                <Grid container id="desktop-main">
                    <Grid item xs={12} style={{ paddingLeft: isTablet || isIPad13 ? 56 : 0, paddingRight: isTablet || isIPad13 ? 56 : 0 }}>
                        <ProgressBar
                            headers={state.external ? externalHeaders : isTablet || isIPad13 ? tabletHeaders : headers}
                            tempHeader={tempHeader}
                            callback={callback}
                            maximumStep={maximumStep}
                            curStep={currentStep}
                            setTempHeader={setTempHeader}
                            showNav={showNav}
                            minimumStep={state.external ? 0 : props.noLicense ? 1 : 0}
                        />
                    </Grid>
                </Grid>
                : null}
            {loading ? <div style={{ textAlign: 'center', paddingTop: 250 }}><CircularProgress /></div> :
                <SimpleViewSlider>
                    {testPlate === null ? <Grid item xs={12}></Grid> : props.isNote ?
                        <Grid style={{ textAlign: "center", paddingLeft: isTablet || isIPad13 ? 56 : 0, paddingRight: isTablet || isIPad13 ? 56 : 0 }} container key={componentToRender}>
                            {state.external ? externalComponents[componentToRender] : isTablet || isIPad13 ? testPlate ? <LicensePlate startFlow={startFlow} /> : tabletComponents[componentToRender] : components[componentToRender]}
                        </Grid>
                        :
                        <Grid style={{ textAlign: "center", paddingLeft: isTablet || isIPad13 ? 56 : 0, paddingRight: isTablet || isIPad13 ? 56 : 0 }} container key={componentToRender}> {calendarComponents[componentToRender]}</Grid>
                    }
                </SimpleViewSlider>
            }
        </React.Fragment>
    )
}