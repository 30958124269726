import React, { createRef } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { setPageID, setTimeSlot } from "../../../store/actions";
import StateManagementContext from '../../../../../context/StateManagementContext';
import { toISOLocal } from '../../../../../helpers/Helpers';
import {
  SLOT_CONFIG,
  COLOR_SCHEMA,
  PAGE_INDEX,
  MINS_PER_HOUR,
  DATE_FORMAT,
} from "../../constant";

class Appointments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clientHeight: 0
    }
  }
  appointmentsRef = createRef();
  viewRef = createRef();
  static contextType = StateManagementContext

  componentDidMount() {
    this.setState({ clientHeight: this.viewRef.current.clientHeight })
  }

  eventStartsBeforeToday(event) {
    const { selectedDate } = this.props;
    return moment(moment(new Date(event.startTime), DATE_FORMAT).format("YYYY-MM-DD")).isBefore(moment(selectedDate, DATE_FORMAT))
  }

  eventEndsAfterToday(event) {
    const { selectedDate } = this.props;
    return moment(moment(new Date(event.endTime), DATE_FORMAT).format("YYYY-MM-DD")).isAfter(moment(selectedDate, DATE_FORMAT))
  }

  eventStartsBeforeTodayAndEndsAfterToday(event) {
    return this.eventEndsAfterToday(event) && this.eventStartsBeforeToday(event)
  }


  getAppointmentsBySelectedDate() {
    const { appointments, selectedDate } = this.props;
    if (!selectedDate) return [];
    if (appointments.length > 0)
      return appointments.filter(e => {
        if (moment(moment(new Date(e.startTime), DATE_FORMAT).format("YYYY-MM-DD")).isSame(moment(selectedDate, DATE_FORMAT)) ||
          moment(moment(new Date(e.endTime), DATE_FORMAT).format("YYYY-MM-DD")).isSame(moment(selectedDate, DATE_FORMAT)) ||
          moment(moment(new Date(e.startTime), DATE_FORMAT).format("YYYY-MM-DD")).isBefore(moment(selectedDate, DATE_FORMAT)) && moment(moment(new Date(e.endTime), DATE_FORMAT).format("YYYY-MM-DD")).isAfter(moment(selectedDate, DATE_FORMAT))) return e
      })
    return [];
  }

  getStartPosFrom(e) {
    if (!e) return 0;
    const mins = this.convertToMins(toISOLocal(new Date(e.startTime)).split("T")[1].split(":"));
    const pxPerMin = SLOT_CONFIG.heightPerThirtyMins / SLOT_CONFIG.nextSlot;
    return pxPerMin * mins;
  }

  getHeightFrom(e) {
    if (!e) return 0;
    const startMins = this.convertToMins(toISOLocal(new Date(e.startTime)).split("T")[1].split(":"));
    const endMins = this.convertToMins(toISOLocal(new Date(e.endTime)).split("T")[1].split(":"));
    const pxPerMin = SLOT_CONFIG.heightPerThirtyMins / SLOT_CONFIG.nextSlot;
    return pxPerMin * Math.abs(endMins - startMins);
  }

  convertToMins(arr) {
    return parseInt(arr[0] * MINS_PER_HOUR) + parseInt(arr[1]);
  }

  onClickAppointment = (event, e) => {
    event.stopPropagation();
    const stateContext = this.context
    stateContext.setCurrentEvent(e)
    if (e.testDrive === true) this.props.gotoAppointmentView1();
    else this.props.gotoAppointmentView2();
  };

  calculateTimeFromPos(offsetY) {
    const step = Math.floor(offsetY / SLOT_CONFIG.heightPerThirtyMins);
    const timeObj = {
      startTime: `${
        Math.floor(step / 2) > 9
          ? Math.floor(step / 2)
          : "0" + Math.floor(step / 2)
        }:${step % 2 > 0 ? "30" : "00"}`,
      endTime: `${
        Math.ceil(step / 2) > 9
          ? Math.ceil(step / 2)
          : "0" + Math.ceil(step / 2)
        }:${step % 2 > 0 ? "00" : "30"}`,
    };
    return timeObj;
  }

  handleClickSlot = (timeSlot) => {
    this.props.setTimeSlot(timeSlot);
    this.props.gotoNewAppointment();
  }

  render() {
    const slots = () => {
      let slots = [];

      let slotTime = moment(SLOT_CONFIG.startTime, "HH:mm");
      let endTime = moment(SLOT_CONFIG.endTime, "HH:mm");
      while (slotTime < endTime) {
        slots.push(slotTime.format("HH:mm"));
        slotTime = slotTime.add(SLOT_CONFIG.nextSlot, "minutes");
      }
      return slots;
    };


    const styleEvent = (event) => {
      let heightOfEventItem = this.getHeightFrom(event);
      let startPosOfEventItem = this.getStartPosFrom(event);
      if (this.eventStartsBeforeTodayAndEndsAfterToday(event)) {
        startPosOfEventItem = 0
        heightOfEventItem = this.state.clientHeight  - SLOT_CONFIG.heightPerThirtyMins * 1.5
      } else {
        if (heightOfEventItem + startPosOfEventItem > this.state.clientHeight || this.eventEndsAfterToday(event)) {
          heightOfEventItem = this.state.clientHeight - startPosOfEventItem - SLOT_CONFIG.heightPerThirtyMins * 1.5
        }
        if (this.eventStartsBeforeToday(event)) {
          startPosOfEventItem = 0
          const endMins = this.convertToMins(toISOLocal(new Date(event.endTime)).split("T")[1].split(":"));
          const pxPerMin = SLOT_CONFIG.heightPerThirtyMins / SLOT_CONFIG.nextSlot;
          heightOfEventItem = pxPerMin * Math.abs(endMins);
        }
      }
      const backgroundColorOfEvent =
        event.testDrive === true
          ? event.finished ? COLOR_SCHEMA.blueBackgroundFinished : COLOR_SCHEMA.blueBackground
          : COLOR_SCHEMA.greyBackground;
      const paddingTop = 16;
      return {
        transform: `translateY(${startPosOfEventItem + paddingTop}px)`,
        height: `${heightOfEventItem}px`,
        backgroundColor: `${backgroundColorOfEvent}`,
      };
    };

    const styleHrLines = {
      paddingBottom: `${SLOT_CONFIG.heightPerThirtyMins - 1}px`,
    };

    const AppointmentsView = (
      <div ref={this.viewRef} className="appointment">
        <ul className="views">
          {this.getAppointmentsBySelectedDate().map((event, i) => {
            return (
              <div
                key={i}
                className="event-view"
                style={styleEvent(event)}
                onClick={(e) => this.onClickAppointment(e, event)}
              >
                <span style={{ fontFamily: 'bmwnextbo', fontSize: 16 }}>{event.testDrive ? event.customer.firstName.length + event.customer.lastName.length > 20 ? event.customer.firstName.concat(" ").concat(event.customer.lastName).substring(0, 17) + "..." : event.customer.firstName.concat(" ").concat(event.customer.lastName) : event.subject.length > 20 ? event.subject.substring(0, 17) + "..." : event.subject}</span>
              </div>
            )
          })}
          {slots().map((i, idx) => (
            <li key={i} onClick={() => {
              let timeObj = {
                startTime: i,
                endTime: slots()[idx + 1] === undefined ? "23:59" : slots()[idx + 1]
              }
              this.handleClickSlot(timeObj)
            }} className="hr-line" style={styleHrLines}>
              <span>{i}</span>
            </li>
          ))}
        </ul>
      </div>
    );

    return (
      <div className="appointments" ref={this.appointmentsRef}>
        {AppointmentsView}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedDate: state.selectedDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    gotoNewAppointment: () => dispatch(setPageID(PAGE_INDEX.NEW_APPOINTMENT)),
    gotoAppointmentView1: () => dispatch(setPageID(PAGE_INDEX.APPOINTMENT_1)),
    gotoAppointmentView2: () => dispatch(setPageID(PAGE_INDEX.APPOINTMENT_2)),
    setTimeSlot: (timeObj) => dispatch(setTimeSlot(timeObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
