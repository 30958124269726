import React, { useEffect, useRef } from 'react'
import AddIcon from '../../../assets/icons/Add.png';
import AddIconHover from '../../../assets/icons/AddDark.png';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '../../utilComponents/IconButton';
import StateManagementContext from '../../../context/StateManagementContext'
import Appointments from '../subcomponents/AppointmentsDesktop'
import moment from 'moment'
import AddNewAppointment from '../../calendar/components/pages/new-appointment';

const WEEK_DAYS = ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"]
const MONTHS = ["januar", "februar", "marts", "april", "maj", "juni", "juli", "august", "september", "oktober", "november", "december"]

export default function HourView(props) {
    const state = React.useContext(StateManagementContext)
    const [open, setOpen] = React.useState(false);
    const appRef = useRef(null);
    const [selectedDate, setSelectedDate] = React.useState(moment(props.date, "YYYY-MM-DD"))
    const dateStr = `${WEEK_DAYS[selectedDate.day()]} den ${selectedDate.date()}. ${MONTHS[selectedDate.month()]} ${selectedDate.year()}`

    useEffect(() => {
        setSelectedDate(moment(props.date, "YYYY-MM-DD"))
    }, [props])

    useEffect(() => {
        const scroll = moment().hour() * 75
        appRef.current.scrollTo(0, scroll)
    }, [])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <div className="desktop-slot-header desk-app-header">
                <div className="left-align-head">{dateStr}</div>
                <div className="right-align-head">
                    <IconButton onClick={handleClickOpen} height={25} width={25} icon={AddIcon} hoverIcon={AddIconHover} />
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <div className="appointment-dialog">
                            <AddNewAppointment desktop={true} car={props.car} selectedDate={selectedDate} handleClose={handleClose} />
                        </div>
                    </Dialog>
                </div>
            </div>
            <div ref={appRef} key={selectedDate} className="desktop-appointments">
                <Appointments continueFlow={props.continueFlow} selectedDate={selectedDate} appointments={props.appointments} />
            </div>
        </React.Fragment>
    )
}