import React, { useEffect, useState, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string'
import Carousel from '../../components/carousel/Carousel';
import { Grid, Box, Paper, Portal } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { connect } from "react-redux";
import { setPageID } from "../../components/calendar/store/actions";
import { PAGE_INDEX_EXTERNAL } from "../../components/calendar/components/constant";
import Datafacade from '../../datafacade/datafacade';
import Geo from '../../geocoding/geocode';
import StateManagementContext from '../../context/StateManagementContext';
import { isBrowser, isMobile, isTablet, MobileView } from 'react-device-detect';
import DesktopFlow from '../../components/desktopflow/DesktopFlow';
import { fadeIn, fadeOut, slideInDown, slideOutUp } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import DefaultCar from '../../assets/img/defaultcar.png';
import Calendar from '../../components/calendar/components/mainExternal';


const styles = {
    fadeOut: {
        animation: 'x .5s',
        animationName: Radium.keyframes(fadeOut, 'fadeOut')
    },
    fadeIn: {
        animation: 'x .5s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn')
    },
    slideInDown: {
        animation: 'x .5s',
        animationName: Radium.keyframes(slideInDown, 'slideInDown')
    },
    slideOutUp: {
        animation: 'x .5s',
        animationName: Radium.keyframes(slideOutUp, 'slideOutUp')
    }
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginBottom: 400,
        backgroundColor: 'white',
        'min-height': '700px',
    },
    header: {
        paddingBottom: 20,
        '@media (min-width: 1023px)': {
            paddingLeft: 70,
            paddingRight: 70,
        },
    },
    department: {
        marginBottom: 50,
        '@media (min-width: 1023px)': {
            paddingLeft: 70,
            paddingRight: 70,
        },
        '@media (max-width: 600px)': {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
    backHeader: {
        marginBottom: 20,
        padding: 26,
        marginLeft: -32,
        marginTop: -32,
        width: '100vw',
        boxShadow: '0px 0px 4px 2px #4F4F4F'
    },
    testcars: {
        marginTop: 20,
        [theme.breakpoints.up(768)]: {
            paddingLeft: 0,
            paddingRight: 0
        },
        [theme.breakpoints.up(1600)]: {
            // padding: 20
        },
    },
    modelImageContainer: {
        position: "relative",
        left: 50,
        cursor: 'pointer'
    }
}));

const seriesData = ["1-Serie", "2-Serie", "3-Serie", "4-Serie", "5-Serie", "6-Serie", "7-Serie", "8-Serie", "X", "Z", "BMW i", "M", "MINI"]

function TestCarsExternal(props) {
    const classes = useStyles();
    const state = useContext(StateManagementContext)
    const [departments, setDepartments] = useState(props.departments);
    const [cars, setCars] = useState(props.cars);
    const [car, setCar] = useState({})
    const [seriesBySlider, setSeriesBySlider] = useState(props.seriesIndex);
    const [flow, setFlow] = useState(false)
    const [mobileFlow, setMobileFlow] = useState(false)
    const [slideOut, setSlideOut] = useState(false)
    const [deskCal, setDeskCal] = useState(false)
    const [mounted, setMounted] = useState(false)
    const noDistance = 'Kunne ikke bestemme afstand'

    useEffect(() => {
        setDepartments(props.departments)
        setCars(props.cars)
    }, [props.departments, props.cars])

    const callback = (id) => {
        setSeriesBySlider(id)
    }

    const handleOpen = (open) => {
        setFlow(open)
    }

    const startFlow = (id) => {
        const c = cars.find(c => c.id === id)
        setCar(c)
        state.setExternalLead(true)
        state.flowSetExternal(true)
        state.flowSetUser(null)
        state.flowSetSingleBooking(false)
        state.flowSetDepartment({ id: c.departmentId })
        state.flowSetCar({ id: id })
        state.flowSetTestDrive(true)
        state.flowSetFinished(false)
        if (isMobile && !isTablet) {
            setMobileFlow(true)
            props.onOpenDriversNote()
        } else {
            setFlow(true)
        }

    }

    const closeFlow = () => {
        setFlow(false)
        setMobileFlow(false)
        state.flowClear()
    }

    const MobileCalendarView = (
        <MobileView>{mobileFlow && <Calendar maximumStep={0} showStepper={true} close={closeFlow} car={car} />}</MobileView>
    );


    return (
        <React.Fragment>
            <Portal>
                {MobileCalendarView}
            </Portal>
            <div className={classes.testcars}>
                {flow ?
                    <StyleRoot>
                        <div style={slideOut ? styles.fadeIn : null}>
                            <DesktopFlow isNote={true} flow={startFlow} noLicense={true} handleOpen={handleOpen} />
                        </div>
                    </StyleRoot>
                    :
                    <StyleRoot>
                        <div style={slideOut ? styles.fadeOut : null}>
                        {state.hideSeriesBar ? null :
                            <Grid container>
                                <Grid item xs={12} style={{ display: 'flex-center', textAlign: 'center' }}>
                                    <Carousel
                                        title="THE"
                                        array={['1', '2', '3', '4', '5', '6', '7', '8', 'X', 'Z', 'i', 'M', 'MINI']}
                                        callback={callback}
                                        index={props.seriesIndex}
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.header}>
                                    <Divider />
                                </Grid>
                            </Grid>
                        }
                            <div className="overflow" style={state.iFrameLead ? {height: 'auto'} : { height: 500, paddingBottom: 100 }}>
                                {departments.map(department => {
                                    return (
                                        <div key={department.id}>
                                            <Grid container spacing={6} className={state.iFrameLead ? "" : classes.department}>
                                                <Grid item xs={12} className={classes.header}>
                                                    <div style={{ fontFamily: 'bmwnextbo', fontSize: 17, }}>{department.departmentName}, {department.city}</div>
                                                    <div style={{ fontFamily: 'bmwnextli', fontSize: 17, paddingBottom: 12 }}>{isNaN(department.distance) || department.distance > 1000 ? noDistance : 'Afstand: ' + department.distance + ' km.'}</div>
                                                </Grid>
                                                {
                                                    cars.filter(c => c.departmentId === department.id).filter(car => car.series === seriesData[seriesBySlider]).length === 0 ?
                                                        <Grid item xs={12} style={{ textAlign: "center", fontFamily: 'bmwnextthin', fontSize: 16, marginTop: -30 }}>
                                                            <div>Modellen er ikke tilgængelig<br />til prøvekørsel i øjeblikket</div>
                                                        </Grid>
                                                        : cars.filter(c => c.departmentId === department.id && !c.disabled && c.id !== 6357).filter(car => car.series === seriesData[seriesBySlider]).map((car, id) => {
                                                            return (
                                                                <Grid style={{ marginTop: -12, cursor: 'pointer' }} item className={classes.paper} xs={12} md={6} sm={6} lg={4} key={car.id}>
                                                                    <div onClick={() => { startFlow(car.id) }} className={classes.modelImageContainer}>
                                                                        {car.variantPicture.includes("null") ?
                                                                            <img src={DefaultCar} alt={car.id} height="90" width="190" />
                                                                            :
                                                                            <img src={car.variantPicture} alt={car.id} height="90" width="190" />
                                                                        }

                                                                    </div>
                                                                    <div style={{ backgroundColor: 'white' }}>
                                                                        <Paper elevation={0} style={{ width: '90%', zIndex: 2 }} >
                                                                            <Box display="flex" component="div" style={{ alignItems: "center" }} onClick={() => { startFlow(car.id) }} className={classes.paper}>
                                                                                <Box p={1} flexGrow={1} >
                                                                                    <div className={classes.text} style={{ fontFamily: 'bmwnextthin', fontSize: 18 }}>
                                                                                        {`${car.manufacturer} ${car.model} ${car.variant} ${car.equipment}`}
                                                                                    </div>
                                                                                </Box>
                                                                            </Box>

                                                                        </Paper>

                                                                    </div>
                                                                    <div style={{marginTop: 5}}>
                                                                        <Divider classes={{ root: classes.dividerColor }} />
                                                                    </div>
                                                                </Grid>
                                                            )
                                                        })
                                                }
                                            </Grid>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </StyleRoot>
                }
            </div>
        </React.Fragment>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        onOpenDriversNote: () => dispatch(setPageID(PAGE_INDEX_EXTERNAL.DAY_VIEW_2_2)),
    };
};

export default connect(null, mapDispatchToProps)(TestCarsExternal);