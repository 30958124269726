import React, { useEffect, useState, useContext } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import './slots.css'
import moment from "moment";
import Facade from '../../../../../datafacade/datafacade'
import StateManagementContext from '../../../../../context/StateManagementContext';
import { PAGE_INDEX, PAGE_INDEX_EXTERNAL, DATE_FORMAT } from "../../constant";
import { connect } from "react-redux";
import {
    setPageID,
} from "../../../store/actions";

function TimeSlots(props) {
    const [slots, setSlots] = useState([])
    const [loading, setLoading] = useState(true)
    const state = useContext(StateManagementContext)

    const shouldFetchOnSelectedDayToday = () => {
        if (state.external) {
            let externalMonthRule = moment().add(30, "days")
            let external24hRuleEnd = moment().add(1, "days")
            const mom = moment(props.selectedDate, DATE_FORMAT)
            const day = mom.day()
            if (day === 0 || day === 6) return false
            else if (
                mom.valueOf() <= external24hRuleEnd.valueOf() || 
                mom.date.valueOf() > externalMonthRule.valueOf()) return false
            else return true
        } else {
            return true
        }
    }

    const getSlots = async () => {
        if (shouldFetchOnSelectedDayToday()) {
            setLoading(true)
            let timeSlots = []
            if(state.external) {
                timeSlots = await Facade.getAvailableSlotsExternal(moment(props.selectedDate, DATE_FORMAT).valueOf(), props.carId)
            } else {
                timeSlots = await Facade.getAvailableSlots(moment(props.selectedDate, DATE_FORMAT).valueOf(), props.carId)
            }
            state.setTimeSlots(timeSlots)
            setSlots(timeSlots)
            setLoading(false)
        } else {
            state.setTimeSlots([])
            setSlots([])
            setLoading(false)
        }
    }

    useEffect(() => {
        if (state.external) {
            if (props.pageID === 2) {
                getSlots()
            }
        } else {
            if (props.pageID === 4 && !props.noFetch) {
                getSlots()
            }
        }

    }, [])

    const isNow = (date) => {
        const now = moment().format("YYYY-MM-DD HH:mm")
        const checkDate = date.format("YYYY-MM-DD HH:mm")
        return moment(now).isSame(checkDate)
    }

    const handleClickSlot = (id) => {
        const start = slots[id].start
        const end = slots[id].end
        state.flowSetTime(new Date(start).getTime(), new Date(end).getTime())
        state.flowSetSelectedTime(id)
        if (state.chooseNewTime) {
            if (state.external) {
                this.props.setStepper(4)
                this.props.setCurrentStep(4)
                this.props.onClickSelectNewTimeExternal();
            }
            props.onClickSelectNewTime()
            props.setStepper(5)
            props.setCurrentStep(5)
        } else {
            if (state.external) {
                props.onClickTimeSlotExternal()
                props.setStepper(1)
                props.setCurrentStep(1)
            } else {
                props.onClickTimeSlot()
                props.setStepper(1)
                props.setCurrentStep(1)
            }
        }
    }

    return (
        <Grid container style={{ textAlign: "center" }}>
            {loading ? <div style={{ margin: 'auto', paddingTop: 100 }}><CircularProgress /></div> : state.timeSlots.length > 0 ? state.timeSlots.map((slot, id) => {
                const start = moment(new Date(slot.start))
                const end = moment(new Date(slot.end))
                return (
                    <Grid key={id} item xs={4}>
                        <div className={state.flow.selectedTime === id ? "slots selected" : "slots"} onClick={() => { handleClickSlot(id) }}>
                            <div className="slot-start">{isNow(start) ? "Nu" : start.format("HH:mm")}</div>
                            <div className="slot-middle-text">til</div>
                            <div className="slot-end">{end.format("HH:mm")}</div>
                        </div>
                    </Grid>
                )
            }) :
                <Grid item xs={12}>
                    <div style={{ fontSize: 16, marginTop: 20, fontFamily: 'bmwnextli' }}>
                        Der er desværre ikke flere tilgængelig tider på den valgte dag. Vælg venligst en anden dag.
                </div>
                </Grid>
            }
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        pageID: state.pageID
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onClickTimeSlot: () => dispatch(setPageID(PAGE_INDEX.TERMS_AND_CONDITIONS)),
        onClickTimeSlotExternal: () => dispatch(setPageID(PAGE_INDEX_EXTERNAL.TERMS_AND_CONDITIONS)),
        onClickSelectNewTime: () => dispatch(setPageID(PAGE_INDEX.DONE)),
        onClickSelectNewTimeExternal: () => dispatch(setPageID(PAGE_INDEX_EXTERNAL.DONE))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeSlots);