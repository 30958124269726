const url = 'http://open.mapquestapi.com/geocoding/v1/address?key='
const key = 'jPYPv8044Luigg1WwWqvVqIH5CtGdcgA'
const suffix = '&location='

function handleHttpErrors(res) {
    if (res.status >= 200 && res.status < 300) { // Success status lies between 200 to 300
        return res.json();
    } else {
        var error = new Error(res.statusText)
        error.response = res.json()
        throw error
    }
}

class GeoCode {
    getLatLongFromAddress = (search) => {
        console.log('search: ', search)
        return fetch(url + key + suffix + search).then(handleHttpErrors)
    }

    calculateDistance = (latlngA, latlngB) => {
        const toRadian = angle => (Math.PI / 180) * angle;
        const distance = (a, b) => (Math.PI / 180) * (a - b);
        const RADIUS_OF_EARTH_IN_KM = 6371;
      
        let lat1 = latlngA[0];
        let lat2 = latlngB[0];
        const lon1 = latlngA[1];
        const lon2 = latlngB[1];
      
        const dLat = distance(lat2, lat1);
        const dLon = distance(lon2, lon1);
      
        lat1 = toRadian(lat1);
        lat2 = toRadian(lat2);
      
        // Haversine Formula
        const a =
          Math.pow(Math.sin(dLat / 2), 2) +
          Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
        const c = 2 * Math.asin(Math.sqrt(a));
      
        let finalDistance = RADIUS_OF_EARTH_IN_KM * c;
      
        return Math.round(finalDistance);
    }
}

export default new GeoCode();