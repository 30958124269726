import React from "react";
import { getResponsiveWidth } from "../../utils";
import { ONE_LETTER_WEEKS as weeks } from "../../constant";

export default class WeekLine extends React.Component {
  render() {
    const width = getResponsiveWidth();
    const styleWeekLine = id => {
      return {
        transform: `translateX(${(id * 1.14) * width}px)`,
      };
    };

    return (
      <div className="weeks">
        {weeks.map((week, id) => (
          <div key={id} className="weekdays-line">
            <div
              className="position-absolute week"
              style={styleWeekLine(id)}
            >
              {week}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
