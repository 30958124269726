import React, { useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CustomTextField from '../../customComponents/CustomTextField';
import DeleteUser from './DeleteUser';
import datafacade from '../../../datafacade/datafacade';
import CustomToggleButton from '../../customComponents/CustomToggleButton';
import { ToggleButtonGroup } from '@material-ui/lab';
import StateManagementContext from '../../../context/StateManagementContext';

const StyledToggleButtonGroup = withStyles(theme => ({
    root: {
        alignItems: 'center'
    },
    grouped: {
        '&:not(:first-child)': {
            'border': '1px solid #E6E6E6',
            'width': 142,
            position: 'relative',
            left: '40px'
        },
        '&:first-child': {
            'border': '1px solid #E6E6E6',
            'width': 142
        },
    },
}))(ToggleButtonGroup);


export default function UpdateUserForm(props) {
    const [role, setRole] = React.useState();
    const [initError, setInitError] = React.useState(false);
    const [fnameError, setFnameError] = React.useState(false);
    const [lnameError, setLnameError] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);
    const [phoneError, setPhoneError] = React.useState(false);
    const [hasChanged, setHasChanged] = React.useState(false);
    const state = useContext(StateManagementContext);
    const [user, setUser] = React.useState({
        id: "",
        initials: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        department: {
            id: ""
        },
        active: true
    })

    const handleRole = (event, newRole) => {
        setHasChanged(true)
        if (newRole !== null) {
            setRole(newRole);
            user.role = newRole
        }
    };

    const handleChange = e => {
        setHasChanged(true)
        const { name, value } = e.target
        setInitError(false)
        setFnameError(false)
        setLnameError(false)
        setEmailError(false)
        setPhoneError(false)
        if (name === "phoneNumber") {
            let val = value
            const countryCode = "+45"
            if (value.substring(0, 3) !== countryCode) {
                val = countryCode
            }
            setUser({
                ...user,
                [name]: val
            })
        } else {
            setUser({
                ...user,
                [name]: value
            })
        }
    }

    useEffect(() => {
        let u = state.users.find(user => user.id === props.userId)
        if (u !== undefined) {
            setRole(u.role)
            setUser({
                ...u,
                department: {
                    id: u.departmentId
                }
            })
        }
    }, [state.users, props]);

    const save = (e) => {
        if (user.initials === "") {
            setInitError(true)
        } else if (user.firstName === "") {
            setFnameError(true)
        } else if (user.lastName === "") {
            setLnameError(true)
        } else if (user.email === "" || !user.email.includes('@')) {
            setEmailError(true)
        } else if (user.phoneNumber === "") {
            setPhoneError(true)
        } else {
            try {
                e.preventDefault()
                datafacade.updateUser(user)
                state.updateUser(user)
                props.handleClose()
            } catch (error) {
                console.log(error)
            }
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={8}>
                <Grid item md={4}>
                    <CustomTextField disabled name="id" fullWidth label="Id" value={user.id} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField required error={initError} name="initials" fullWidth label="Initialer" value={user.initials} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField required error={fnameError} name="firstName" fullWidth label="Fornavn" value={user.firstName} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField required error={lnameError} name="lastName" fullWidth label="Efternavn" value={user.lastName} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField required error={emailError} name="email" fullWidth label="Email" value={user.email} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField required error={phoneError} name="phoneNumber" fullWidth label="Mobiltelefon" value={user.phoneNumber} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <StyledToggleButtonGroup value={role} exclusive onChange={handleRole}>
                        <CustomToggleButton value="admin">
                            <Typography>Administrator</Typography>
                        </CustomToggleButton>
                        <CustomToggleButton value="user">
                            <Typography>Bruger</Typography>
                        </CustomToggleButton>
                    </StyledToggleButtonGroup>
                </Grid>
            </Grid>
            <DeleteUser changes={hasChanged} handleSave={save} disable={user.id === state.currentUser.id} user={user} />
        </React.Fragment>
    );
}