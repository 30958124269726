import React from 'react'
import BackIcon from '../../../../../assets/icons/backBlue.png'
import './buttons.css'

export default (props) => {

    return (
        <div className="main-button" onClick={props.onClick}>
            <img className="back-button-icon" src={BackIcon} alt="back" height="30" width="30"/>
            <span className="back-button">{props.title}</span>
            {props.noline ? null : <div className="progress-static" style={{background: props.background}}><div style={{background: "#E6E6E6", height: 1, position: 'relative', top: 1, zIndex: -1}}></div></div>}
        </div>
    )
}