import React, { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CustomTextField from '../../customComponents/CustomTextField';
import Selector from '../../utilComponents/Selector';
import datafacade from '../../../datafacade/datafacade';
import StateManagementContext from '../../../context/StateManagementContext';
import DeleteCar from './DeleteCar';
import CustomFileUpload from '../../customComponents/CustomFileUpload';

const useStyles = makeStyles(theme => ({
    formGrid: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: -30
    },
    form: {
        width: '400px',
        '@media (max-width: 1023px)': {
            width: '70%'
        },
        '@media (max-width: 768px)': {
            width: '80%'
        },
    },
}));

const seriesData = ["1-Serie", "2-Serie", "3-Serie", "4-Serie", "5-Serie", "6-Serie", "7-Serie", "8-Serie", "X", "Z", "BMW i", "M", "MINI"]
const variantData = ["Sedan", "Touring", "Coupee", "Gran Coupee", "Cabriolet", "Active Tourer", "Gran Tourer", "Gran Turismo", "Hatch", "SAV"]
const fuelData = ["Benzin", "Diesel", "Plug-in-hybrid", "Elbil"]
const equipmentData = ["Sport", "Luxury", "Standard", "xLine", "M Sport", "M Sport X", "Connected", "Advantage", "Charged", "Charged Plus", "Charged Professional"]

const variantDataMini = ["3-Dørs", "5-Dørs", "Cabriolet", "Countryman", "Clubman"]
const equipmentDataMini = ["Classic", "Favoured", "JCW", "Essential", "Experience", "Maximise"] 

export default function UpdateCarForm(props) {
    const state = useContext(StateManagementContext)
    const [car, setCar] = React.useState(state.cars.find(c => c.id === props.carId))
    const classes = useStyles();
    const [modelError, setModelError] = React.useState(false);
    const [hasChanged, setHasChanged] = React.useState(false);

    const handleChange = (e) => {
        setHasChanged(true)
        const { name, value } = e.target
        setModelError(false)
        setCar({
            ...car,
            [name]: value
        })
    }

    useEffect(() => {
        // eslint-disable-next-line
    }, [car.series, car.variant, car.equipment, car.fuel, car.model, car.vin, state])

    const updateCarFromFetch = () => {
        try {
            let c = { ...car, department: { id: car.departmentId }, active: true }
            delete c.variantPicture
            if(c.picture === undefined) {
                c.picture = ""
            }
            datafacade.updateCar(c)
            c.variantPicture = c.picture
            state.updateCarInDepartment(c)
            props.handleClose()
        } catch (error) {
            console.log(error)
        }
    }

    const save = (e) => {
        if (car.model === "") {
            setModelError(true)
        } else {
            updateCarFromFetch()
        }
    }

    const getFile = (file) => {
        setHasChanged(true)
        setCar({
            ...car,
            picture: file.base64
        });
    }

    return (
        <React.Fragment>
            <Grid container spacing={8}>
                <Grid item md={4}>
                    <Selector label="Serie" name="series" val={car.series} handler={handleChange} data={seriesData} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField required error={modelError} name="model" fullWidth label="Model" value={car.model} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <Selector label="Variant" name="variant" val={car.variant} handler={handleChange} data={car.manufacturer === "MINI" ? variantDataMini : variantData} />
                </Grid>
                <Grid item md={4}>
                    <Selector className={classes.input} name="fuel" val={car.fuel} handler={handleChange} label="Brændstof" data={fuelData} />
                </Grid>
                <Grid item md={4}>
                    <Selector className={classes.input} name="equipment" val={car.equipment} handler={handleChange} label="Udstyrsmodel" data={car.manufacturer === "MINI" ? equipmentDataMini : equipmentData} />
                </Grid>
                <Grid item md={4} style={{ textAlign: "center" }}>
                    <CustomFileUpload text="Upload modelbillede" onDone={getFile} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField name="vin" fullWidth label="Stelnummer" value={car.vin} onChange={handleChange} />
                </Grid>
            </Grid>
            <DeleteCar handleSave={save} changes={hasChanged} car={car} />
        </React.Fragment>
    );
}