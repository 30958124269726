import React, { useContext } from 'react';
import CustomButton from '../../customComponents/CustomButton'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CustomTextField from '../../customComponents/CustomTextField';
import datafacade from '../../../datafacade/datafacade';
import CustomToggleButton from '../../customComponents/CustomToggleButton';
import { ToggleButtonGroup } from '@material-ui/lab';
import Selector from '../../utilComponents/Selector';
import AddIcon from '../../../assets/icons/Add.png';
import RemoveIcon from '../../../assets/icons/Remove24.png';
import StateManagementContext from '../../../context/StateManagementContext';
import CustomButtonTrans from '../../customComponents/CustomButtonTrans';
import DepartmentSelector from '../../utilComponents/DepartmentSelector';
import CustomFileUpload from '../../customComponents/CustomFileUpload';

const useStyles = makeStyles(theme => ({
    formGrid: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 0
    },
    form: {
        width: '400px',
        '@media (max-width: 1023px)': {
            width: '70%'
        },
        '@media (max-width: 768px)': {
            width: '80%'
        },
    },
    submitButton: {
        margin: theme.spacing(4, 0),
        // width: '90%'
    },
    input: {
        // margin: theme.spacing(2, 0)
    },
    licenseplate: {
        // textAlign: "center",
        // margin: theme.spacing(3, 0)
    },
    uploadRoot: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    uploadInput: {
        display: 'none',
    },
}));

const StyledToggleButtonGroup = withStyles(theme => ({
    root: {
        alignItems: 'center',
        // 'margin': theme.spacing(6, 0)
    },
    grouped: {
        '&:not(:first-child)': {
            'border': '1px solid #E6E6E6',
            'width': 142,
            position: 'relative',
            left: '30px'
        },
        '&:first-child': {
            'border': '1px solid #E6E6E6',
            'width': 142,

        },
    },
}))(ToggleButtonGroup);




export default function SettingsDialog(props) {
    const classes = useStyles();

    const seriesData = ["1-Serie", "2-Serie", "3-Serie", "4-Serie", "5-Serie", "6-Serie", "7-Serie", "8-Serie", "X", "Z", "BMW i", "M", "MINI"]
    const variantData = ["Sedan", "Touring", "Coupe", "Gran Coupe", "Cabriolet", "Active Tourer", "Gran Tourer", "Gran Turismo", "Hatch", "SAV"]
    const fuelData = ["Benzin", "Diesel", "Plug-in-hybrid", "Elbil"]
    const equipmentData = ["Sport", "Luxury", "Standard", "xLine", "M Sport", "M Sport X", "Connected", "Advantage", "Charged", "Charged Plus", "Charged Professional"]

    const variantDataMini = ["3-Dørs", "5-Dørs", "Cabriolet", "Countryman", "Clubman"]
    const equipmentDataMini = ["Classic", "Favoured", "JCW", "Essential", "Experience", "Maximise"] 

    const [isRegistered, setIsResistered] = React.useState("null");
    const [openLicenseplate, setOpenLicenseplate] = React.useState(false);
    const [modelError, setModelError] = React.useState(false);
    const [depError, setDepError] = React.useState(false)
    const state = useContext(StateManagementContext)
    const [carData, setCarData] = React.useState({
        variantData: ["Sedan", "Touring", "Coupe", "Gran Coupe", "Cabriolet", "Active Tourer", "Gran Tourer", "Gran Turismo", "Hatch", "SAV"],
        equipmentData: ["Sport", "Luxury", "Standard", "xLine", "M Sport", "M Sport X", "Connected", "Advantage", "Charged", "Charged Plus", "Charged Professional"]
    })
    const [car, setCar] = React.useState({
        department: { id: "" },
        manufacturer: "BMW",
        model: "",
        licensePlate: "",
        variant: carData.variantData[0],
        series: seriesData[0],
        equipment: carData.equipmentData[0],
        fuel: fuelData[0],
        isRegistered: false,
        active: true,
        picture: "",
        vin: ""
    })

    const handleRegistred = (event, newVal) => {
        setIsResistered(newVal);
        car.isRegistered = newVal
    };

    const handleChange = e => {
        const { name, value } = e.target
        setModelError(false)
        if (name === "series" && value === "MINI") {
            setCar({
                ...car,
                manufacturer: "MINI",
                [name]: value
            })
        } else if (name === "series" && value !== "MINI") {
            setCar({
                ...car,
                manufacturer: "BMW",
                [name]: value
            })
        } else {
            setCar({
                ...car,
                [name]: value
            })
        }
    }

    React.useEffect(() => {
        if (car.manufacturer === "MINI") {
            setCarData({
                variantData: variantDataMini,
                equipmentData: equipmentDataMini
            })
        } else {
            setCarData({
                variantData: variantData,
                equipmentData: equipmentData
            })
        }
    }, [car.manufacturer])

    const setDepartment = (departmentId) => {
        setCar({
            ...car,
            department: { id: departmentId }
        })
    }

    const clearCar = () => {
        setCar({
            department: { id: "" },
            manufacturer: "BMW",
            model: "",
            licensePlate: "",
            variant: carData.variantData[0],
            series: seriesData[0],
            equipment: carData.equipmentData[0],
            fuel: fuelData[0],
            isRegistered: false,
            active: true,
            picture: "",
            vin: ""
        })
        setCarData({
            variantData: variantData,
            equipmentData: equipmentData
        })
        setIsResistered("null")
    }

    const handleSubmit = async (e) => {
        if (car.model === "") {
            setModelError(true)
        } else if (car.department.id === "") {
            setDepError(true)
        } else {
            e.preventDefault()
            try {
                const carWithId = await datafacade.addNewCar(car)
                if (carWithId.departmentId === state.currentUser.departmentId) {
                    state.addNewCarToDepartment(carWithId)
                }
                props.handleClose()
                clearCar()
            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleOpenLicenseplate = e => {
        if (openLicenseplate) {
            setOpenLicenseplate(false)
            setCar({
                ...car,
                isRegistered: false,
                licensePlate: ""
            })
        } else (
            setOpenLicenseplate(true)
        )
    }

    const getFile = (file) => {
        setCar({
            ...car,
            picture: file.base64
        });
    }

    return (
        <div>
            <Grid container spacing={8}>
                <Grid item md={4}>
                    <Selector val={seriesData[0]} label="Serie" name="series" handler={handleChange} data={seriesData} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField required error={modelError} name="model" fullWidth label="Model" value={car.model} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <Selector val={carData.variantData[0]} label="Variant" name="variant" handler={handleChange} data={carData.variantData} />
                </Grid>
                <Grid item md={4}>
                    <div style={{ cursor: "pointer" }} onClick={handleOpenLicenseplate}>
                        <div style={{ marginLeft: 60, marginTop: 15 }}><img style={{ float: "left" }} alt="" src={openLicenseplate ? RemoveIcon : AddIcon} height={25} width={25} /> <div style={{ paddingTop: 2, paddingLeft: 10, float: "left" }}>Tilføj nummerplade</div></div>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <Selector val={fuelData[0]} className={classes.input} name="fuel" handler={handleChange} label="Brændstof" data={fuelData} />
                </Grid>
                <Grid item md={4}>
                    <Selector val={carData.equipmentData[0]} className={classes.input} name="equipment" handler={handleChange} label="Udstyrsmodel" data={carData.equipmentData} />
                </Grid>
                <Grid item md={4}>
                    {openLicenseplate ?
                        <StyledToggleButtonGroup value={isRegistered} exclusive onChange={handleRegistred}>
                            <CustomToggleButton style={{ width: 142 }} value={true}>
                                Indregistreret
                            </CustomToggleButton>
                            <CustomToggleButton style={{ width: 142 }} value={false}>
                                Prøveskilte
                            </CustomToggleButton>
                        </StyledToggleButtonGroup>
                        :
                        null
                    }
                </Grid>
                <Grid item md={4}>
                    {openLicenseplate ?
                        <CustomTextField className={classes.input} name="licensePlate" fullWidth label="Nummerplade" value={car.licensePlate} onChange={handleChange} />
                        : null}
                </Grid>
                <Grid item md={4}>
                    <DepartmentSelector className={classes.input} departmentError={depError} setDep={setDepartment} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField name="vin" fullWidth label="Stelnummer" value={car.vin} onChange={handleChange} />
                </Grid>
                <Grid item md={4}></Grid>
                <Grid item md={4} style={{ textAlign: "center" }}>
                    <CustomFileUpload text="Upload modelbillede" onDone={getFile} />
                </Grid>
            </Grid>
            <Grid container spacing={4} style={{ marginTop: 50 }}>
                <Grid item md={6} style={{ textAlign: "right" }}>
                    <CustomButtonTrans onClick={props.handleClose}>
                        Fortryd
                    </CustomButtonTrans>
                </Grid>

                <Grid item md={6} style={{ textAlign: "left" }}>
                    <CustomButton onClick={handleSubmit}>
                        Opret
                </CustomButton>
                </Grid>
            </Grid>
        </div>
    );
}