import React, { useContext } from 'react';
import CustomButton from '../../customComponents/CustomButton'
import Grid from '@material-ui/core/Grid';
import CustomTextField from '../../customComponents/CustomTextField';
import datafacade from '../../../datafacade/datafacade';
import Geo from '../../../geocoding/geocode'
import StateManagementContext from '../../../context/StateManagementContext';
import CustomButtonTrans from '../../customComponents/CustomButtonTrans'


export default function SettingsDialog(props) {
    const [idError, setIdError] = React.useState(false);
    const [nameError, setNameError] = React.useState(false);
    const [zipError, setZipError] = React.useState(false);
    const [addressError, setAddressError] = React.useState(false);
    const [cityError, setCityError] = React.useState(false);
    const state = useContext(StateManagementContext)
    const [department, setDepartment] = React.useState({
        id: "",
        departmentName: "",
        address: "",
        postalCode: "",
        city: "",
        email: "",
        phone: "+45",
        lng: "",
        lat: "",
        distance: ""
    })

    const handleChange = e => {
        setIdError(false)
        setNameError(false)
        setZipError(false)
        setAddressError(false)
        setCityError(false)
        const { name, value } = e.target
        if (name === "phone") {
            let val = value
            const countryCode = "+45"
            if (value.substring(0, 3) !== countryCode) {
                val = countryCode
            }
            setDepartment({
                ...department,
                [name]: val
            })
        } else {
            setDepartment({
                ...department,
                [name]: value
            })
        }
    }

    const clearDepartment = () => {
        setDepartment({
            id: "",
            departmentName: "",
            address: "",
            postalCode: "",
            city: "",
            email: "",
            phone: "+45",
            distance: ""
        })
    }

    const fetchLngLat = async () => {
        try {
            const res = await Geo.getLatLongFromAddress(`${department.address} ${department.postalCode}`)
            const latLng = res.results[0].locations[0].latLng
            if (!latLng) {
            } else {
                const distance = Geo.calculateDistance([latLng.lat, latLng.lng], [state.currentUserPos.coords.latitude, state.currentUserPos.coords.longitude])
                setDepartment({
                    ...department,
                    lng: latLng.lng,
                    lat: latLng.lat,
                    distance: distance
                })
            }

        } catch (error) {
        }
    }

    const handleSubmit = async (e) => {
        if (department.id === "") {
            setIdError(true)
        } else if (department.departmentName === "") {
            setNameError(true)
        } else if (department.address === "") {
            setAddressError(true)
        } else if (department.postalCode === "") {
            setZipError(true)
        } else if (department.city === "") {
            setCityError(true)
        } else {
            e.preventDefault()
            try {
                const dep = await datafacade.addDepartment(department)
                state.addDepartmentToList(dep)
                props.handleClose()
                clearDepartment()
            } catch (error) {
            }
        }
    }

    return (
        <div>
            <Grid container spacing={8}>
                <Grid item md={4}>
                    <CustomTextField required error={idError} name="id" fullWidth label="Id" value={department.id} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField required error={nameError} name="departmentName" fullWidth label="Afdelingsnavn" value={department.departmentName} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField required error={addressError} onBlur={fetchLngLat} name="address" fullWidth label="Adresse" value={department.address} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField required error={zipError} onBlur={fetchLngLat} name="postalCode" fullWidth label="Postnummer" value={department.postalCode} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField required error={cityError} onBlur={fetchLngLat} name="city" fullWidth label="By" value={department.city} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField name="email" fullWidth label="Email" value={department.email} onChange={handleChange} />
                </Grid>
                <Grid item md={4}>
                    <CustomTextField name="phone" fullWidth label="Telefon" value={department.phone} onChange={handleChange} />
                </Grid>
            </Grid>
            <Grid container spacing={4} style={{ marginTop: 50 }}>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <CustomButtonTrans onClick={props.handleClose}>
                        Fortryd
                    </CustomButtonTrans>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'left' }}>
                    <CustomButton onClick={handleSubmit}>
                        Opret
                    </CustomButton>
                </Grid>
            </Grid>
        </div>
    );
}