import React from 'react'

export default function WeekNo (props) {
    const [weekNo, setWeekNo] = React.useState(0)

    React.useEffect(() => {
        setWeekNo(props.moment.isoWeeks())
    }, [props.moment])

    return (
        <div style={{borderBottom: '1px solid black', marginLeft: 25, marginBottom: 5}}>
            <div style={{fontFamily: 'bmwnextbo', fontSize: 11, textAlign: 'left', position: 'relative', left: -20, bottom: -7}}>{weekNo}</div>
        </div>
    )
}