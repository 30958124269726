import React, { useState, useContext, useEffect } from 'react'
import { Grid, Hidden } from '@material-ui/core'
import { ToggleButtonGroup } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import CustomToggleButton from '../../customComponents/CustomToggleButton';
import CustomTextField from '../../customComponents/CustomTextField';
import CustomButton from '../../customComponents/CustomButton';
import AddIcon from '../../../assets/icons/Add.png';
import CloseIcon from '../../../assets/icons/minus.png';
import StateManagementContext from '../../../context/StateManagementContext';
import { isIPad13, isTablet } from 'react-device-detect';

const StyledToggleButtonGroup = withStyles(theme => ({
    root: {
        alignItems: 'center',
        'margin': theme.spacing(4, 0)
    },
    grouped: {
        '&:not(:first-child)': {
            position: 'relative',
            'border-left': '1px solid rgba(0, 0, 0, 0.12)',
            right: '-70px',
            [theme.breakpoints.up(1600)]: {
                right: '-120px'
            },
        },
        '&:first-child': {
            position: 'relative',
            left: '50px',
            [theme.breakpoints.up(1600)]: {
                left: '100px'
            },
        },
    },
}))(ToggleButtonGroup);

const StyledToggleButtonGroupExternal = withStyles(theme => ({
    root: {
        alignItems: 'center',
        'margin': theme.spacing(4, 0)
    },
    grouped: {
        '&:not(:first-child)': {
            position: 'relative',
            'border-left': '1px solid rgba(0, 0, 0, 0.12)',
            right: '-85px',
            [theme.breakpoints.up(1600)]: {
                right: '-120px'
            },
        },
        '&:first-child': {
            position: 'relative',
            left: '45px',
            [theme.breakpoints.up(1600)]: {
                left: '100px'
            },
        },
    },
}))(ToggleButtonGroup);

export default function Customer(props) {
    const state = useContext(StateManagementContext)
    const [b2c, setB2c] = useState(null)
    const [showMore, setShowMore] = useState(false)
    const [info, setInfo] = useState({
        customerFullName: "",
        driverFullName: "",
        driverLicenseNumber: "",
        phone: "+45",
        email: "",
        price: 0,
        km: 150,
        note: ""
    })
    const [error, setError] = useState({
        nameError: false,
        emailError: false,
        phoneError: false,
        b2cError: false
    })

    const handleB2c = (event, val) => {
        setError({
            nameError: false,
            phoneError: false,
            emailError: false,
            b2cError: false
        })
        setB2c(val)

    };

    const handleShowMore = () => {
        setShowMore(!showMore)
    }

    const handleSave = () => {
        if (b2c === null) {
            setError({ b2cError: true })
        } else if (info.phone.length < 11 || info.phone.length > 11) {
            setError({ phoneError: true })
        } else if (info.email === "" || !info.email.includes('@')) {
            setError({ emailError: true })
        } else if (info.customerFullName === "") {
            setError({ nameError: true })
        } else {
            if (info.driverFullName !== "" && info.driverLicenseNumber !== "") {
                const driverArr = info.driverFullName.split(" ")
                const formattedDriver = {
                    firstName: driverArr[0],
                    lastName: driverArr.slice(1).join(" "),
                    driverLicenseNumber: info.driverLicenseNumber
                }
                state.flowSetDriver(formattedDriver)
            }
            const customerArr = info.customerFullName.split(" ")
            const formattedCustomer = {
                firstName: customerArr[0],
                lastName: customerArr.slice(1).join(" "),
            }

            if (!state.external) {
                state.flowSetCustomer(formattedCustomer)
            } else {
                let cus = state.flow.customer
                cus.firstName = formattedCustomer.firstName
                cus.lastName = formattedCustomer.lastName
                delete cus.cpr
                delete cus.driverLicenseNumber
                delete cus.license
                state.flowSetCustomer(cus)
            }
            state.flowSetLastInfo(info.email, info.phone, b2c, info.price, info.km, info.note)
            state.flowSetFinished(false)
            if (isTablet || isIPad13) {
                props.setMaxStep(4)
                props.setCurStep(4)
                props.setComponent(4)
            } else {
                if (state.external) {
                    props.setMaxStep(4)
                    props.setCurStep(4)
                    props.setComponent(4)
                } else {
                    props.setMaxStep(2)
                    props.setCurStep(2)
                    props.setComponent(2)
                }
            }
            if (!state.external) {
                props.handleShowNav(false)
            }
        }
    }

    const onChange = (e) => {
        const { name, value } = e.target
        setError({
            nameError: false,
            phoneError: false,
            emailError: false,
            b2cError: false
        })
        if (name === "phone") {
            let val = value
            const countryCode = "+45"
            if (value.substring(0, 3) !== countryCode) {
                val = countryCode
            }
            setInfo({
                ...info,
                [name]: val
            })
        } else {
            setInfo({
                ...info,
                [name]: value
            })
        }
    }

    const setFullfilledState = () => {
        const c = state.flow.customer
        const price = state.flow.price
        const km = state.flow.km
        if (c.email !== undefined) {
            if (state.flow.drivers.length > 0) {
                const fullName = `${state.flow.drivers[0].firstName} ${state.flow.drivers[0].lastName}`
                const driverLicenseNumber = `${state.flow.drivers[0].driverLicenseNumber}`
                setInfo({
                    ...info,
                    customerFullName: `${c.firstName} ${c.lastName}`,
                    phone: c.phone,
                    email: c.email,
                    km: km,
                    price: price,
                    driverFullName: fullName,
                    driverLicenseNumber: driverLicenseNumber
                })
            } else {
                setInfo({
                    ...info,
                    customerFullName: `${c.firstName} ${c.lastName}`,
                    phone: c.phone,
                    email: c.email,
                    km: km,
                    price: price
                })
            }
            setB2c(c.b2c)
        }

    }

    useEffect(() => {
        setFullfilledState()
    }, [])

    const driverView = (
        <React.Fragment>
            {state.external ? null : <div className="last-info-header" style={{ textAlign: 'center', paddingBottom: 30 }}>Tilføj flere førere</div>}
            <div className="input-fields p-3 text-center personal-input">
                <div className="btn-group">
                    <div style={{ marginTop: state.external ? 0 : -25 }}>
                        <CustomTextField style={{ width: 280 }} name="driverFullName" value={info.driverFullName} onChange={onChange} fullWidth label="Fulde navn" />
                    </div>
                    <div style={{ paddingTop: state.external ? 50 : 60 }}>
                        <CustomTextField style={{ width: 280 }} type="tel" name="driverLicenseNumber" value={info.driverLicenseNumber} onChange={onChange} fullWidth label="Kørekortnummer" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

    const priceView = (
        <React.Fragment>
            <div className="last-info-header" style={{ textAlign: 'center', paddingBottom: 30 }}>Udvidet lejebetingelser</div>
            <div className="input-fields p-3 text-center personal-input">
                <div className="btn-group">
                    <div style={{ marginTop: -25 }}>
                        <CustomTextField style={{ width: 280 }} type="tel" name="price" value={info.price} onChange={onChange} fullWidth label="Totale lejeomkostninger i perioden" />
                    </div>
                    <div style={{ paddingTop: 60 }}>
                        <CustomTextField style={{ width: 280 }} type="tel" name="km" value={info.km} onChange={onChange} fullWidth label="Inkluderede kilometer i perioden" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

    const noteView = (
        <React.Fragment>
            <div className="last-info-header" style={{ textAlign: 'center', paddingBottom: 30 }}>Tilføj note</div>
            <div className="input-fields p-3 text-center personal-input">
                <div className="btn-group">
                    <div style={{ marginTop: -25 }}>
                        <CustomTextField style={{ width: 280 }} type="text" name="note" value={info.note} onChange={onChange} fullWidth label="Note" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

    const BottomView = (
        <React.Fragment>
            <Hidden mdDown><Grid item md={4}></Grid></Hidden>
            <Grid item lg={4} md={6}>
                <div className="input-desktop-flow">
                    <CustomTextField required error={error.emailError} style={{ width: 280 }} name="email" value={info.email} onChange={onChange} label="Email" fullWidth />
                </div>
            </Grid>
            <Hidden mdDown><Grid item md={4}></Grid></Hidden>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <div style={{ marginTop: 10 }} className="input-desktop-flow input-last">
                    <div className="d-flex justify-content-center m-4" style={{ cursor: 'pointer', paddingTop: 15 }} onClick={handleShowMore}>
                        <img alt="add" height="25" width="25" src={showMore ? CloseIcon : AddIcon}></img>
                        <span className="ml-2" style={{ paddingLeft: 10, fontSize: '1rem', fontFamily: 'bmwnextthin' }}>Flere muligheder</span>
                    </div>
                </div>
            </Grid>
            {showMore ?
                <React.Fragment>
                    <Grid item lg={4} md={6}>{priceView}</Grid>
                    <Grid item lg={4} md={6}>{driverView}</Grid>
                    <Grid item lg={4} md={6}>{noteView}</Grid>
                </React.Fragment>
                : null}
        </React.Fragment>
    )

    const BottomViewExternal = (
        <React.Fragment>
            <Grid item lg={4} md={6}>
                {showMore ?
                    driverView
                    : null}
            </Grid>
        </React.Fragment>
    )

    const BottomViewExternalTablet = (
        <React.Fragment>
            {showMore ?
                <React.Fragment>
                    <Grid item xs={6}>
                        <div className="input-desktop-flow">
                            <CustomTextField style={{ width: 280 }} name="driverFullName" value={info.driverFullName} onChange={onChange} fullWidth label="Fulde navn" />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="input-desktop-flow">
                            <CustomTextField style={{ width: 280 }} type="tel" name="driverLicenseNumber" value={info.driverLicenseNumber} onChange={onChange} fullWidth label="Kørekortnummer" />
                        </div>
                    </Grid>
                </React.Fragment>
                : null
            }
        </React.Fragment>
    )

    const internal = (
        <React.Fragment>
            {isIPad13 || isTablet ?
                <div style={{ height: 500, overflowY: 'auto' }}>
                    <Grid container className="desktop-last-details-main">
                        <Grid className="desktop-last-details-subs" item lg={4} md={6}>
                            <div>
                                <div className="d-flex justify-content-around personal-business p-4">
                                    <div style={{ textAlign: 'center' }}>
                                        <StyledToggleButtonGroup value={b2c} exclusive onChange={handleB2c}>
                                            <CustomToggleButton height={55} width={120} value={true}>
                                                Privat
                        </CustomToggleButton>
                                            <CustomToggleButton height={55} width={120} value={false}>
                                                Erhverv
                        </CustomToggleButton>
                                        </StyledToggleButtonGroup>
                                    </div>
                                </div>
                                <div style={{ color: '#e22718' }}>{error.b2cError ? "Vælg venligst privat eller erhverv" : '\u00A0'}</div>
                            </div>
                        </Grid>
                        <Grid className="desktop-last-details-subs" item lg={4} md={6}>
                            <div className="input-desktop-flow input-last">
                                <CustomTextField required error={error.nameError} style={{ width: 280 }} name="customerFullName" value={info.customerFullName} onChange={onChange} label="Fulde navn" fullWidth />
                            </div>
                        </Grid>
                        <Grid item lg={4} md={6}>
                            <div style={{ marginTop: 10 }} className="input-desktop-flow input-last">
                                <div className="d-flex justify-content-center m-4" style={{ cursor: 'pointer', paddingTop: 15 }} onClick={handleShowMore}>
                                    <img alt="add" height="25" width="25" src={showMore ? CloseIcon : AddIcon}></img>
                                    <span className="ml-2" style={{ paddingLeft: 10, fontSize: '1rem', fontFamily: 'bmwnextthin' }}>Tilføj flere førere</span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item lg={4} md={6}>
                            <div className="input-desktop-flow">
                                <CustomTextField required error={error.emailError} style={{ width: 280 }} name="email" value={info.email} onChange={onChange} label="Email" fullWidth />
                            </div>
                        </Grid>
                        {BottomView}
                        <Grid className="desktop-last-details-subs" item lg={4} md={6}>
                            <div className="input-desktop-flow">
                                <CustomTextField required error={error.phoneError} style={{ width: 280 }} name="phone" value={info.phone} onChange={onChange} label="Telefonnummer" fullWidth />
                            </div>
                        </Grid>
                        <Grid className="desktop-last-details-subs" item xs={12}>
                            <div className="input-last" style={{ paddingBottom: 100 }}>
                                <CustomButton onClick={handleSave}>Gem</CustomButton>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                :
                <div style={{ height: 500, overflowY: 'auto' }}>
                    <Grid container className="desktop-last-details-main">
                        <Grid className="desktop-last-details-subs" item lg={4} md={6}>
                            <div>
                                <div className="d-flex justify-content-around personal-business p-4">
                                    <div style={{ textAlign: 'center' }}>
                                        <StyledToggleButtonGroup value={b2c} exclusive onChange={handleB2c}>
                                            <CustomToggleButton height={55} width={120} value={true}>
                                                Privat
                            </CustomToggleButton>
                                            <CustomToggleButton height={55} width={120} value={false}>
                                                Erhverv
                            </CustomToggleButton>
                                        </StyledToggleButtonGroup>
                                    </div>
                                </div>
                                <div style={{ color: '#e22718' }}>{error.b2cError ? "Vælg venligst privat eller erhverv" : '\u00A0'}</div>
                            </div>
                        </Grid>
                        <Grid className="desktop-last-details-subs" item lg={4} md={6}>
                            <div className="input-desktop-flow input-last">
                                <CustomTextField required error={error.nameError} style={{ width: 280 }} name="customerFullName" value={info.customerFullName} onChange={onChange} label="Fulde navn" fullWidth />
                            </div>
                        </Grid>
                        <Grid className="desktop-last-details-subs" item lg={4} md={6}>
                            <div className="input-desktop-flow input-last">
                                <CustomTextField required error={error.phoneError} style={{ width: 280 }} name="phone" value={info.phone} onChange={onChange} label="Telefonnummer" fullWidth />
                            </div>
                        </Grid>
                        {BottomView}
                        <Grid className="desktop-last-details-subs" item xs={12}>
                            <div className="input-last">
                                <CustomButton onClick={handleSave}>Gem</CustomButton>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            }
        </React.Fragment>
    )

    const external = (
        <React.Fragment>
            {isIPad13 || isTablet ?
                <div style={{ height: 500, overflowY: 'auto' }}>
                    <Grid container className="desktop-last-details-main">
                        <Grid className="desktop-last-details-subs" item lg={4} md={6}>
                            <div>
                                <div className="d-flex justify-content-around personal-business p-4">
                                    <div style={{ textAlign: 'center' }}>
                                        <StyledToggleButtonGroup value={b2c} exclusive onChange={handleB2c}>
                                            <CustomToggleButton height={55} width={120} value={true}>
                                                Privat
                                            </CustomToggleButton>
                                            <CustomToggleButton height={55} width={120} value={false}>
                                                Erhverv
                                            </CustomToggleButton>
                                        </StyledToggleButtonGroup>
                                    </div>
                                </div>
                                <div style={{ color: '#e22718' }}>{error.b2cError ? "Vælg venligst privat eller erhverv" : '\u00A0'}</div>
                            </div>
                        </Grid>
                        <Grid className="desktop-last-details-subs" item lg={4} md={6}>
                            <div className="input-desktop-flow input-last">
                                <CustomTextField required error={error.nameError} style={{ width: 280 }} name="customerFullName" value={info.customerFullName} onChange={onChange} label="Fulde navn" fullWidth />
                            </div>
                        </Grid>
                        <Grid item lg={4} md={6}>
                            <div className="input-desktop-flow">
                                <CustomTextField required error={error.emailError} style={{ width: 280 }} name="email" value={info.email} onChange={onChange} label="Email" fullWidth />
                            </div>
                        </Grid>
                        <Grid className="desktop-last-details-subs" item lg={4} md={6}>
                            <div className="input-desktop-flow">
                                <CustomTextField required error={error.phoneError} style={{ width: 280 }} name="phone" value={info.phone} onChange={onChange} label="Telefonnummer" fullWidth />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ marginTop: 10 }} className="input-desktop-flow input-last">
                                <div className="d-flex justify-content-center m-4" style={{ cursor: 'pointer', paddingTop: 15 }} onClick={handleShowMore}>
                                    <img alt="add" height="25" width="25" src={showMore ? CloseIcon : AddIcon}></img>
                                    <span className="ml-2" style={{ paddingLeft: 10, fontSize: '1rem', fontFamily: 'bmwnextthin' }}>Tilføj flere førere</span>
                                </div>
                            </div>
                        </Grid>
                        {BottomViewExternalTablet}
                        <Grid className="desktop-last-details-subs" item xs={12}>
                            <div className="input-last" style={{ paddingBottom: 150 }}>
                                <CustomButton onClick={handleSave}>Fortsæt</CustomButton>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                :
                <div style={{ height: 500, overflowY: 'auto' }}>
                    <Grid container className="desktop-last-details-main">
                        <Grid item lg={4} md={6}>
                            <div className="input-desktop-flow">
                                <CustomTextField required error={error.nameError} style={{ width: 280 }} name="customerFullName" value={info.customerFullName} onChange={onChange} label="Fulde navn" fullWidth />
                            </div>
                        </Grid>
                        <Grid className="desktop-last-details-subs" item lg={4} md={6}>
                            <div className="input-desktop-flow">
                                <CustomTextField required error={error.emailError} style={{ width: 280 }} name="email" value={info.email} onChange={onChange} label="Email" fullWidth />
                            </div>
                        </Grid>
                        <Grid className="desktop-last-details-subs" item lg={4} md={6}>
                            <div className="input-desktop-flow">
                                <CustomTextField required error={error.phoneError} style={{ width: 280 }} name="phone" value={info.phone} onChange={onChange} label="Telefonnummer" fullWidth />
                            </div>
                        </Grid>
                        <Grid item lg={4} md={6}>
                            <div style={{ marginTop: 10 }} className="input-desktop-flow input-last">
                                <div className="d-flex justify-content-center m-4" style={{ cursor: 'pointer', paddingTop: 40 }} onClick={handleShowMore}>
                                    <img alt="add" height="25" width="25" src={showMore ? CloseIcon : AddIcon}></img>
                                    <span className="ml-2" style={{ paddingLeft: 10, fontSize: '1rem', fontFamily: 'bmwnextthin' }}>Tilføj flere førere</span>
                                </div>
                            </div>
                        </Grid>
                        {BottomViewExternal}
                        <Grid className="desktop-last-details-subs" item lg={4} md={6}>
                            <div>
                                <div className="d-flex justify-content-around personal-business-external p-4">
                                    <div style={{ textAlign: 'center' }}>
                                        <StyledToggleButtonGroup value={b2c} exclusive onChange={handleB2c}>
                                            <CustomToggleButton height={55} width={120} value={true}>
                                                Privat
                            </CustomToggleButton>
                                            <CustomToggleButton height={55} width={120} value={false}>
                                                Erhverv
                            </CustomToggleButton>
                                        </StyledToggleButtonGroup>
                                    </div>
                                </div>
                                <div style={{ color: '#e22718' }}>{error.b2cError ? "Vælg venligst privat eller erhverv" : '\u00A0'}</div>
                            </div>
                        </Grid>
                        <Grid className="desktop-last-details-subs" item xs={12}>
                            <div className="input-last">
                                <CustomButton className={state.iFrameLead ? "submit-testdrive-form" : ""} onClick={handleSave}>Fortsæt</CustomButton>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            }
        </React.Fragment>
    )

    return (
        state.external ? external : internal
    )
}