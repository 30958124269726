import React from "react";
import {ToggleButton} from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  root: {
    fontSize: 15,
    'background-color': 'white',
    'color': '#262626',
    'width': 163,
    '-moz-border-radius': '0px',
    '-webkit-border-radius': '0px',
    'border-radius': '0px',
    'outline': '0',
    "-webkit-box-shadow": "none",
    'box-shadow': 'none',
    'fontFamily': 'bmwnextli',
  },
  selected: {
    'background-color': 'red'
  }
  
});
//Laves fra bunden fremfor mui
function CustomToggleButton(props) {

  return <ToggleButton 
            {...props} 
            style={{width: props.width ? props.width : "", height: props.height ? props.height : ""}} 
            />;
}

export default withStyles(styles)(CustomToggleButton);