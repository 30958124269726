import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import StateManagementContext from '../../../../../context/StateManagementContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  circle: {
    height: 55,
    width: 55,
    background: 'transparent',
    border: '3px solid #1C69D4',
    borderRadius: '50%',
    marginRight: 10,
    display: 'inline-block',
    float: 'left',
    paddingBottom: 10
  },
  circleText: {
    fontSize: 18,
    color: '#1C69D4',
    paddingTop: 10,
    fontFamily: 'bmwnextbo',
    cursor: 'pointer'
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  label: {
    fontSize: 18,
    fontFamily: 'bmwnextbo'
  },
  content: {
    textAlign: 'left',
    fontSize: 16,
    fontFamily: 'bmwnextli',
    // width: '110%'
  },
  icon: {
    color: "#1C69D4 !important",
  },
  iconText: {
    fontFamily: 'bmwnextbo !important'
  }
}));


function getStepContent(step) {
  switch (step) {
    case 0:
      return `Jeg giver hermed samtykke til, at Jan Nygaard AS må kontakte mig efter prøvekørslen og løbende sende mig nyheder med invitationer til events, artikler og tilbud.`;
    case 1:
      return 'Jeg giver samtykke til indhentning af adresse- og navneoplysninger fra CPR-registreret til brug for udarbejdelse af den lovpligtige dokumentation i forbindelse med din prøvekørsel?';
    case 2:
      return `Jeg er indforstået med Jan Nygaard AS’ brugsbetingelser, der bl.a. indbefatter; at jeg skal føre køretøjet ansvarsfuldt. Tilbagelevere bilen indenfor den aftalte tidsramme. Udelukkende lade personer noteret på køresedlen føre køretøjet. Jeg kan blive opkrævet en selvrisiko på 5.000,- hvis der opstår skader i forbindelse med prøvekørslen.`;
    default:
      return 'Unknown step';
  }
}

function getLinkText(step) {
  switch (step) {
    case 0:
    case 1:
      return 'Du kan læse mere om, hvordan vi behandler dine persondata i vores Datapolitik';
    case 2:
      return `Du kan læse mere om vores brugsbetingelser her`;

    default:
      return 'Unknown step';
  }
}

// function getStepContent(step) {
//   switch (step) {
//     case 0:
//       return `Vi vil gerne have lov til at kontakte Dem efter Deres prøvekørsel, for at følge op på Deres oplevelse, og herudover sende vores nyhedsbrev, med nyheder, tilbud og artikler fra BMW og MINI

//       Herudover indsamler og gemmer vi data til brug for dokumentation og analyse. 
//       `;
//     default:
//       return 'Unknown step';
//   }
// }

export default function TermsStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ['Samtykke:', 'Indhentning af persondata:', 'Betingelser for brug:'];
  // const steps = ['Samtykke:'];
  const state = useContext(StateManagementContext)
  const [consent, setConsent] = React.useState({
    isSubscribed: false,
    personalData: false,
    termsOfUse: false,
  })

  useEffect(() => {
    if (state.consentFullFilled) {
      setActiveStep(3)
    }
  }, [])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (activeStep === steps.length && !state.consentFullFilled) {
      props.onDone(consent)
    }
  }, [activeStep])

  const handleClick = (consentGiven) => {
    const key = Object.keys(consent)[activeStep]

    setConsent({
      ...consent,
      [key]: consentGiven
    })
    handleNext()
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel style={{ textAlign: 'left' }}
              StepIconProps={{ classes: { root: classes.icon, text: classes.iconText } }}
              classes={{
                label: classes.label
              }}>{label}</StepLabel>
            <StepContent>
              <Typography className={classes.content}>{getStepContent(index)}</Typography>
              <a href="/terms" target="_blank" rel="noopener noreferrer"><Typography className={classes.content}>{getLinkText(index)}</Typography></a>
              <div className={classes.actionsContainer}>
                <div className={classes.circle} onClick={() => handleClick(true)}><div className={classes.circleText}>Ja</div></div>
                {activeStep === steps.length - 1 ?
                  null
                  :
                  <div className={classes.circle} onClick={() => handleClick(false)}><div className={classes.circleText}>Nej</div></div>}
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
