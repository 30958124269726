import React, { useState, useContext, useEffect } from 'react';
import CustomButton from '../../../customComponents/CustomButton';
import CustomButtonTrans from '../../../customComponents/CustomButtonTrans';
import StateManagementContext from '../../../../context/StateManagementContext';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Camera from "../subcomponents/ocr/Camera";
import { connect } from "react-redux";
import { setPageID } from "../../store/actions";
import axios from 'axios';
import { VISION_URL } from '../../../../helpers/Helpers';
import { FACING_MODES, IMAGE_TYPES } from 'jslib-html5-camera-photo';
import {
  PAGE_INDEX,
  DESCRIPTIONS,
  BUTTON_LABELS,
} from "../constant";
import { Portal, Grid, Hidden } from '@material-ui/core';
import { isTablet, isIPad13, isMobileOnly } from 'react-device-detect';

function Picture(props) {
  const xs = useMediaQuery(theme => theme.breakpoints.between(320, 767));
  const state = useContext(StateManagementContext)
  const [showCamera, setShowCamera] = useState(false)
  const [intervalId, setIntervalId] = useState(0)
  const [disabled, setDisabled] = useState(false)

  const handleShowCamera = (show) => {
    if (!show) {
      clearInterval(intervalId)
    }
    setShowCamera(show)
  }

  const setInterId = (id) => {
    setIntervalId(id)
  }

  const onContinue = () => {
    if (isTablet || isIPad13) {
      props.setCurStep(3)
      props.setMaxStep(5)
    } else {
      props.onClickLastDetails()
    }
  }

  const onClickSearchCustomer = () => {
    props.setTempHeader("Manuel indtastning")
    if (isTablet || isIPad13) {
      props.setComponent(3)
    } else {
      props.onClickSearchCustomer()
    }
  }

  const checkConsent = () => {
    if (state.flow.customer.personalData !== undefined) {
      const consentGiven = state.flow.customer.personalData
      setDisabled(!consentGiven)
    }
  }

  // useEffect(() => {
  //   checkConsent()
  // })

  return (
    !showCamera ?
      <div style={{ paddingLeft: 63, paddingRight: 63, paddingTop: 33 }}>
        <Grid container spacing={xs ? 2 : 4} style={{ textAlign: "center" }}>
          {isTablet || isIPad13 ? <Grid item xs={12} style={{ fontSize: 18, fontFamily: 'bmwnextbo' }}>Tag et billede af førerens<br />kørekort</Grid> : null}
          <Hidden smDown><Grid item sm={3}></Grid></Hidden>
          {disabled ?
            <Grid item xs={12} sm={6} style={{ fontSize: 16, fontFamily: 'bmwnextli' }}>
              Det er desværre ikke muligt af benytte CPR søgning, da kunden har fravalgt sig dette.
            </Grid>
            :
            <Grid item xs={12} sm={6} style={{ fontSize: 16, fontFamily: 'bmwnextli' }}>
              Du kan tage et billede af førerens<br />
              kørekort for at aflæse og indsamle de nødvendige oplysninger, til brug for oprettelsen af prøvekørslen.<br />
              Alternativt, kan du manuelt indtaste oplysnigerne.
            </Grid>
          }

          <Hidden smUp><Grid item xs={12}></Grid></Hidden>
          <Grid item xs={12} sm={6} style={{ textAlign: isMobileOnly ? 'center' : 'right' }}>
            <CustomButton disabled={disabled} fullWidth onClick={() => { handleShowCamera(true) }}>
              {BUTTON_LABELS.camera}
            </CustomButton>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: isMobileOnly ? 'center' : 'left' }}>
            <CustomButtonTrans fullWidth onClick={onClickSearchCustomer}>
              {BUTTON_LABELS.manual}
            </CustomButtonTrans>
          </Grid>
        </Grid>
      </div>
      :
      <CameraView setTempHeader={props.setTempHeader} setInterId={setInterId} showCamera={showCamera} goToManual={props.onClickManualMode} onContinue={onContinue} handleShowCamera={handleShowCamera} setStepper={props.setStepper} setCurrentStep={props.setCurrentStep} />
  );
}

const mapDispatchToProps = dispatch => {
  return {
    onClickSearchCustomer: () => dispatch(setPageID(PAGE_INDEX.SEARCH_CUSTOMER)),
    onClickManualMode: () => dispatch(setPageID(PAGE_INDEX.PICTURE)),
    onClickLastDetails: () => dispatch(setPageID(PAGE_INDEX.LAST_INFORMATIONS)),
  };
};

export default connect(null, mapDispatchToProps)(Picture);

function CameraView(props) {
  const [onSucces, setOnSucces] = useState(false)
  const state = useContext(StateManagementContext)
  const [cpr, setCpr] = useState(null)
  const [licenseNumber, setLicenseNumber] = useState(null)
  const [uri, setUri] = useState("")
  const signal = axios.CancelToken.source()

  const handleTakePhoto = (uri) => {
    if (onSucces === false && props.showCamera === true) {
      requestVision(uri)
    }
  }

  const requestVision = async (uri) => {
    var data = {
      requests: [
        {
          image: {
            content: uri.slice(22),
          },
          features: [{
            type: "TEXT_DETECTION",
            maxResults: 5
          }]
        }
      ]
    }

    await axios({
      method: 'post',
      url: VISION_URL,
      data,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    })
      .then((r) => {
        let array = r.data.responses[0].textAnnotations
        if (array !== undefined) {
          for (let x = 1; x < array.length; x++) {
            let res = array[x].description
            if (res.includes('-') && res.length === 11 && cpr === null) {
              setCpr(res)
              if (licenseNumber !== null) {
                setOnSucces(true)
              }
            }
            if (res.length === 8 && !isNaN(parseFloat(res)) && isFinite(res) && licenseNumber === null) {
              setLicenseNumber(res)
              if (cpr !== null) {
                setOnSucces(true)
              }
            }
          }
        }
      })
      .catch((error) => {
      })
  }

  useEffect(() => {
    return function cleanup() {
      signal.cancel()
    };
  });

  useEffect(() => {
    if (cpr !== null && licenseNumber !== null) {
      state.flowSetCpr(cpr)
      state.flowSetDriverLicense(licenseNumber)
      setOnSucces(true)
      props.handleShowCamera(false)
      props.onContinue()
    }
  }, [cpr, licenseNumber])

  return (
    <Portal>
      <div className="camera">
        <Camera
          setInterId={props.setInterId}
          handleShowCamera={props.handleShowCamera}
          showCamera={props.showCamera}
          idealFacingMode={FACING_MODES.ENVIRONMENT}
          idealResolution={true}
          isMaxResolution={true}
          sizeFactor={1}
          imageType={IMAGE_TYPES.PNG}
          imageCompression={0.97}
          isImageMirror={false}
          delay={1000}
          onTakePhoto={handleTakePhoto}
          onSucces={onSucces}
          goToManual={props.goToManual}
          setTempHeader={props.setTempHeader}
        />
      </div>
    </Portal>
  )
}