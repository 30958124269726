import React, { useEffect, useState, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Carousel from '../carousel/Carousel';
import { Grid } from '@material-ui/core';
import CarPaper from './CarPaper';
import { Typography, Divider } from '@material-ui/core';
import Arrow from '../../assets/icons/forwardBlue.png';
import BackIcon from '../../assets/icons/BackGrey.png';
import Datafacade from '../../datafacade/datafacade';
import Geo from '../../geocoding/geocode';
import StateManagementContext from '../../context/StateManagementContext';
import { isMobile, isTablet, isIPad13, isMobileOnly } from 'react-device-detect';
import DesktopFlow from '../../components/desktopflow/DesktopFlow';
import { fadeIn, fadeOut, slideInDown, slideOutUp } from 'react-animations';
import Radium, { StyleRoot } from 'radium';


const styles = {
    fadeOut: {
        animation: 'x .5s',
        animationName: Radium.keyframes(fadeOut, 'fadeOut')
    },
    fadeIn: {
        animation: 'x .5s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn')
    },
    slideInDown: {
        animation: 'x .5s',
        animationName: Radium.keyframes(slideInDown, 'slideInDown')
    },
    slideOutUp: {
        animation: 'x .5s',
        animationName: Radium.keyframes(slideOutUp, 'slideOutUp')
    }
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginBottom: 400,
        backgroundColor: 'white',
        'min-height': '700px'
    },
    header: {
        paddingBottom: 20,
        '@media (min-width: 1023px)': {
            paddingLeft: 70,
            paddingRight: 70,
        },
    },
    department: {
        marginBottom: 50,
        '@media (min-width: 1023px)': {
            paddingLeft: 70,
            paddingRight: 70,
        },
    },
    backHeader: {
        marginBottom: 20,
        padding: 26,
        marginLeft: -32,
        marginTop: -32,
        width: '100vw',
        boxShadow: '0px 0px 4px 2px #4F4F4F'
    },
    testcars: {
        [theme.breakpoints.up(768)]: {
            paddingLeft: 0,
            paddingRight: 0
        },
        [theme.breakpoints.up(1600)]: {
            padding: 20
        },
    },
    dummybox: {
        height: 59
    }
}));

const seriesData = ["1-Serie", "2-Serie", "3-Serie", "4-Serie", "5-Serie", "6-Serie", "7-Serie", "8-Serie", "X", "Z", "BMW i", "M"]

export default function TestCars(props) {
    const classes = useStyles();
    const [departments, setDepartments] = useState([]);
    const [cars, setCars] = useState([]);
    const [seriesBySlider, setSeriesBySlider] = useState(0);
    const [flow, setFlow] = useState(false)
    const [slideOut, setSlideOut] = useState(false)
    const [deskCal, setDeskCal] = useState(false)
    const [mounted, setMounted] = useState(false)
    const state = useContext(StateManagementContext)
    const noDistance = 'Kunne ikke bestemme afstand'



    const calculateDistance = (departments) => {
        departments.forEach(dep => {
            let distance = NaN
            const depCoord = [dep.lat, dep.lng]
            if (state.currentUserPos !== undefined || state.currentUserPos !== undefined) {
                distance = Geo.calculateDistance([state.currentUserPos.coords.latitude, state.currentUserPos.coords.longitude], depCoord)
            }
            dep.distance = distance
        })
    }

    const sortByDistance = (departments) => {
        departments.sort((a, b) => (a.distance > b.distance) ? 1 : -1)
    }

    const setState = async () => {
        let departments = null
        let cars = null
        if (state.external) {
            departments = await Datafacade.getAllDepartments()
            cars = await Datafacade.getAllCars()
        } else {
            departments = state.departments
            cars = state.cars
            calculateDistance(departments)
            sortByDistance(departments)
            departments.forEach(department => {
                if (isNaN(department.distance)) {
                    departments.push(departments.splice(departments.indexOf(department), 1)[0]);
                }
            })
        }
        setDepartments(departments)
        setCars(cars)
    }

    const callback = (id) => {
        setSeriesBySlider(id)
    }

    const startFlow = (isFlow) => {
        setDeskCal(isFlow)
        if (flow) {
            if (!isTablet && !isIPad13)
                setFlow(false)
        } else {
            setSlideOut(true)
            setTimeout(() => setFlow(true), 500)
        }
    }

    const continueFlow = () => {
        setDeskCal(true)
        setSlideOut(true)
        setTimeout(() => setFlow(true), 500)
    }

    const handleMounted = (setMount) => {
        if (props.openSubMenu) {
            setTimeout(() => {
                setMounted(setMount)
            }, 600)
        }
    }

    useEffect(() => {
        if (state.external) {
            setState()
        }
    }, [])

    useEffect(() => {
        if (!state.external) {
            setState()
        }
    }, [setState])

    useEffect(() => {
        handleMounted(true)
    }, [props.openSubMenu])



    return (
        <div className={classes.testcars}>
            {isMobile && props.open && mounted ?
                    <StyleRoot>
                        <div style={mounted ? styles.slideInDown : styles.slideOutUp}>
                            <Grid container className={classes.backHeader}>
                                <Grid item xs={3} style={{ marginTop: -6 }}>
                                    <img onClick={() => {
                                        props.handleOpen(!props.open);
                                    }} src={BackIcon} style={{ marginBottom: -10 }} alt={'close'} height={23} width={23} />
                                </Grid>
                                <Grid style={{ textAlign: 'center', marginTop: -3 }} item xs={6}>
                                    <Typography variant="inherit" style={{ fontFamily: 'bmwnextbo', borderBottom: '3px solid #1b69d4', paddingLeft: 10, paddingRight: 10, paddingBottom: 24, fontSize: 16 }}>BMW</Typography>
                                </Grid>
                                <Grid item xs={3}></Grid>
                            </Grid>
                        </div>
                    </StyleRoot>
                : isMobileOnly ? <div className={classes.dummybox}></div> : null}
            {flow ?
                <StyleRoot>
                    <div style={slideOut ? styles.fadeIn : null}>
                        <DesktopFlow isNote={deskCal} continueFlow={continueFlow} flow={startFlow} handleOpen={props.handleOpen} />
                    </div>
                </StyleRoot>
                :
                <StyleRoot>
                    <div style={slideOut ? styles.fadeOut : null}>
                        <Grid container>
                            <Grid item xs={12} style={{ display: 'flex-center', textAlign: 'center' }}>
                                <Carousel
                                    title="THE"
                                    array={['1', '2', '3', '4', '5', '6', '7', '8', 'X', 'Z', 'i', 'M']}
                                    callback={callback}
                                    index={0}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.header}>
                                <Divider />
                            </Grid>
                        </Grid>
                        <div className="overflow" style={{ height: 500, paddingBottom: 100 }}>
                            {departments.map(department => {
                                return (
                                    <div key={department.id}>
                                        <Grid container spacing={6} className={classes.department}>
                                            <Grid item xs={12} className={classes.header}>
                                                <div style={{ fontFamily: 'bmwnextbo', fontSize: 17, }}>{department.departmentName}, {department.city}</div>
                                                <div style={{ fontFamily: 'bmwnextli', fontSize: 17, paddingBottom: 12 }}>{isNaN(department.distance) || department.distance > 1000 ? noDistance : 'Afstand: ' + department.distance + ' km.'}</div>
                                            </Grid>
                                            {
                                                cars.filter(c => c.departmentId === department.id).filter(car => car.series === seriesData[seriesBySlider]).length === 0 ?
                                                    <Grid item xs={12} style={{ textAlign: "center", fontFamily: 'bmwnextthin', fontSize: 16, marginTop: -30 }}>
                                                        <div>Modellen er ikke tilgængelig<br />til prøvekørsel i øjeblikket</div>
                                                    </Grid>
                                                    : cars.filter(c => c.departmentId === department.id  && !c.disabled).filter(car => car.series === seriesData[seriesBySlider]).map((car, id) => {
                                                        return (
                                                            <Grid style={{ marginTop: -12 }} item className={classes.paper} xs={12} md={6} sm={6} lg={4} key={car.id}>
                                                                <CarPaper
                                                                    openCal={props.openCal}
                                                                    startFlow={startFlow}
                                                                    carid={car.id}
                                                                    icon={Arrow}
                                                                    text={car.manufacturer + " " + car.model + " " + car.variant + " " + car.equipment}
                                                                    bottomdivider={1}
                                                                    car={car}
                                                                />
                                                            </Grid>
                                                        )
                                                    })
                                            }
                                        </Grid>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </StyleRoot>
            }
        </div>
    )
}