import React, { useContext, useEffect } from 'react';
import { makeStyles, Grid, CircularProgress } from '@material-ui/core';
import TableGrid from '../../utilComponents/TableGrid';
import PDFIcon from '../../../assets/icons/pdf48grey.png';
import Mobile from '../../../assets/icons/mobile.svg';
import NoMobile from '../../../assets/icons/nomobile.svg';
import SearchIcon from '../../../assets/icons/search.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import StateManagementContext from '../../../context/StateManagementContext';
import CustomTextField from '../../customComponents/CustomTextField';
import facade from '../../../datafacade/datafacade';
import { NOTE_URL } from '../../../helpers/Helpers';
import moment from 'moment'
import CSVExporter from '../utils/CSVExporter';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '30px',
        '@media (max-width: 767px)': {
            marginTop: '60px'
        },
    },
    header: {
        fontFamily: 'bmwnextthin',
        textAlign: "center",
        fontSize: 25,
    },
    main: {
        margin: 'auto',
        paddingTop: 40,
        width: '90%'
    },
    addIcon: {
        textAlign: 'left',
        paddingBottom: 10
    }
}))

function formattedDate(d) {
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());
    const time = d.toLocaleTimeString().substr(0, d.toLocaleTimeString().lastIndexOf("."))

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return `${day}/${month}/${year} kl ${time}`;
}

export default function Departments(props) {
    const [data, setData] = React.useState([])
    const [search, setSearch] = React.useState("")
    const [bookings, setBookings] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [selectedDate, setSelectedDate] = React.useState(moment().format("YYYY-MM-DD"))

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value)
    };

    function sortByDate(a, b) {
        var dateA = new Date(a.startTime).getTime();
        var dateB = new Date(b.startTime).getTime();
        return dateA < dateB ? 1 : -1;
    };

    async function fetchBookingsFromDate(date) {
        setLoading(true)
        const bookings = await facade.getBookingsFromDate(date)
        bookings.sort(sortByDate)
        setBookings(bookings)
        setLoading(false)
    }

    useEffect(() => {
        fetchBookingsFromDate(moment(selectedDate).valueOf())
    }, [selectedDate])

    const generateData = (bookings) => {
        let collection = bookings.map(b => {
            if (b.testDrive) {
                return [
                    b.department.id,
                    b.user !== undefined ? b.external ? `(Eksternt) ${b.user.firstName} ${b.user.lastName}` : `${b.user.firstName} ${b.user.lastName}` : 'Eksternt',
                    b.customer !== undefined ? b.customer.firstName + " " + b.customer.lastName : "Fjernet kunde",
                    formattedDate(new Date(b.startTime)),
                    formattedDate(new Date(b.endTime)),
                    `${b.car.manufacturer === undefined ? "" : b.car.manufacturer} ${b.car.model === undefined ? "" : b.car.model} ${b.car.variant === undefined ? "" : b.car.variant} ${b.car.equipment === undefined ? "" : b.car.equipment}`,
                    b.car.licensePlate === undefined || b.car.licensePlate === "" ? b.testPlate : b.car.licensePlate,
                    b.customer !== undefined ? b.customer.isSubscribed ? <img id={b.id} alt="" style={{ cursor: 'pointer' }} src={Mobile} height="28" width="28"></img> : <img id={b.id} alt="" style={{ cursor: 'pointer' }} src={NoMobile} height="28" width="28"></img> : "",
                    <a href={NOTE_URL + b.path} target="_blank" rel="noopener noreferrer"><img id={b.id} alt="" style={{ cursor: 'pointer' }} src={PDFIcon} height="28" width="28"></img></a>
                ]
            } else {
                return []
            }
        })

        return collection
    }

    const classes = useStyles()
    const state = useContext(StateManagementContext)

    const onChange = (e) => {
        setSearch(e.target.value)
        let val = e.target.value
        if (val.length === 0) {
            setData(generateData(bookings))
        } else {
            let collection = bookings.filter(v => {
                if (v.testDrive) {
                    if (
                        String(v.customer.firstName + " " + v.customer.lastName).toLowerCase().includes(val.toLowerCase()) ||
                        String(formattedDate(new Date(v.startTime))).toLowerCase().includes(val.toLowerCase()) ||
                        String(v.car.manufacturer + " " + v.car.model + " " + v.car.variant + " " + v.car.equipment).toLowerCase().includes(val.toLowerCase()) ||
                        String(v.car.licensePlate).toLowerCase().includes(val.toLowerCase()) ||
                        String(v.department.id).toLowerCase().includes(val.toLowerCase())
                    ) {
                        return v
                    }
                }
            });
            setData(generateData(collection))
        }
    }

    useEffect(() => {
        setData(generateData(bookings, state.currentUser.departmentId))
    }, [bookings])

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Grid container spacing={4}>
                
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}><CustomTextField style={{ width: '250px' }} name="search" onChange={onChange} value={search} label={<img alt="search" height="20" width="20" src={SearchIcon}></img>} /></Grid>
                    <Grid item xs={3}>
                        <CustomTextField style={{ width: '250px' }} type="date" value={selectedDate} onChange={handleDateChange} label="Dato" />
                    </Grid>
                    <Grid item xs={3}><CSVExporter/></Grid>
                </Grid>
            </div>
            <Grid container className={classes.main}>
                {loading ?
                    <Grid item xs={12} style={{ marginTop: 50, textAlign: "center" }}>
                        <CircularProgress />
                    </Grid>
                    :
                    <Grid item xs={12}>
                        {data.length > 0 ? <TableGrid aria="bookings table" collection={data} /> : null}
                    </Grid>}
            </Grid>
        </div>
    )
}