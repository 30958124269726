import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
  },
  select: {
    '&:before': {
      borderColor: 'var(--galaxy-blue)',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderColor: 'var(--galaxy-blue)',
    }
  },
}));

export default function DepartmentSelector(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(props.val)

  const handleChange = e => {
    const val = e.target.value
    setSelected(val)
    props.handler(e)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <FormControl className={classes.formControl} error={props.error}>
        <InputLabel>{props.label}</InputLabel>
        <Select
          name={props.name}
          className={classes.select}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={selected}
          onChange={handleChange}
          required
          fullWidth
        >
          {props.data.map((d, i) =>
            <MenuItem value={d} key={i}>{d}</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
}
