import React from "react";
import TextField from "@material-ui/core/TextField";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

const themeGrey = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        fontFamily: 'bmwnextthin',
        color: "#212121",
        "&$focused": {
          color: "#212121"
        }
      }
    },
    MuiInput: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
      underline: {
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottomColor: "#212121",
        },
        "&:after": {
          borderBottomColor: "#212121"
        },
        "&:before": {
          borderBottomColor: "#212121"
        },
      }
    }
  }
});

const themeWhite = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        fontFamily: 'bmwnextthin',
        color: "#FFFFFF",
        "&$focused": {
          color: "#FFFFFF"
        }
      }
    },
    MuiInput: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
      root: {
        color: '#FFFFFF',
        fontFamily: 'bmwnextthin',
      },
      underline: {
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottomColor: "#FFFFFF",
        },
        "&:after": {
          borderBottomColor: "#FFFFFF"
        },
        "&:before": {
          borderBottomColor: "#FFFFFF"
        },
      }
    }
  }
});


export default function CustomTextField(props) {

  return <ThemeProvider theme={props.theme === 'white' ? themeWhite : themeGrey}><TextField {...props}  variant="standard" /></ThemeProvider>
}

// export default withStyles(stylesWhi)(CustomTextField);