import React from 'react';
import ReactDOM from 'react-dom';
import Login from './views/Login'
import Workspace from './views/Workspace';
import ExternalSite from './externalsite/views/External';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './auth/ProtectedRoute';
import ForgotPass from './views/ForgotPass';
import Terms from './views/Terms';
import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import '../src/css/globalCss.css';
import { StateManagementProvider } from './context/StateManagementContext';
import { Provider } from "react-redux";
import reducer from "./components/calendar/store/reducer";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import CacheBuster from './cachebuster/CacheBuster';
import moment from 'moment';
import Dashboard from './views/Dashboard';
//import TagManager from 'react-gtm-module';

let muiTheme = createMuiTheme({
  typography: {
    fontFamily: [
      'bmwnextreg',
      'bmwnextthin',
      'bmwnextbo',
      'bmwnextli',
    ].join(','),
    some: {
      fontSize: 400,
    },
    button: {
      textTransform: 'none',
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {

      },
    },
  }

});

const theme = responsiveFontSizes(muiTheme);

// const tagManagerArgs = {
//   gtmId: 'GTM-TGFJHR8'
// }


export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      externalLead: false,
      users: [],
      user: {},
      departments: [],
      cars: [],
      plates: [],
      currentUserPos: {
        coords: {
          latitude: 0,
          longitude: 0
        }
      },
      bookings: [],
      calendarAppointments: [],
      flowstate: {
        id: null,
        subject: "",
        customer: {
          firstName: "",
          license: null,
          driverLicenseNumber: "",
          cpr: ""
        },
        car: {},
        department: {},
        user: {},
        start: null,
        end: null,
        sign: null,
        testDrive: false,
        drivers: [],
        finished: false,
        toFinish: false,
        note: "",
        price: 0,
        km: 150,
        selectedTime: null,
        testPlate: "",
        singleBooking: false,
        external: false
      },
      currentEvent: {},
      chooseNewTime: false,
      cpr: "",
      driverLicenseNumber: "",
      scrollPos: null,
      hasFetched: false,
      hasFetchedTS: false,
      fetchCal: false,
      timeSlots: [],
      consentFullFilled: false,
      iFrameLead: false,
      hideSeriesBar: false,
      overviewDate: moment().format("YYYY-MM-DD"),
      messageBox: {
        show: false,
        msg: "",
        variant: ""
      }
    }
  }



  //User list
  setUsers = (users) => {
    this.setState({ users })
  }

  addUserToList = (user) => {
    const users = this.state.users
    users.push(user)
    this.setState({ users })
  }

  updateUser = (user) => {
    const stateUsers = this.state.users
    const users = stateUsers.map(u => {
      if (u.id === user.id) {
        return user
      } else {
        return u
      }
    })
    this.setState({ users })
  }

  deleteUser = (user) => {
    const users = this.state.users.filter(u => u.id !== user.id)
    this.setState({ users })
  }

  //Current user
  setCurrentUser = (user) => {
    this.setState(prevState => ({
      ...prevState,
      user: user
    }))
  }

  //Department list
  setDepartments = (departments) => {
    this.setState({ departments })
  }

  addDepartmentToList = (department) => {
    const departments = this.state.departments
    departments.push(department)
    this.setState({ departments })
  }

  updateDepartment = (department) => {
    const stateDepartments = this.state.departments
    const departments = stateDepartments.map(d => {
      if (d.id === department.id) {
        return department
      } else {
        return d
      }
    })
    this.setState({ departments })
  }

  deleteDepartment = (department) => {
    const departments = this.state.departments.filter(d => d.id !== department.id)
    this.setState({ departments })
  }

  //Car list
  // addNewCarToDepartment = (car) => {
  //   const dep = [...this.state.departments];
  //   const departments = dep.map(d => {
  //     if (d.id === car.departmentId) {
  //       d.cars.push(car)
  //     }
  //     return d
  //   })
  //   console.log(departments)
  //   this.setState({ departments });
  // const department = this.departments.find(d => d.id === car.departmentId)

  // department.cars.push(car)
  // }

  setCars = (cars) => {
    let state = this.state
    state.cars = cars
    this.setState(state)
  }

  addNewCar = (car) => {
    let state = this.state
    state.cars.push(car)
    this.setState(state)
  }

  updateCar = (car) => {
    let state = this.state
    state.cars = state.cars.map(c => {
      if (c.id === car.id) {
        return { ...c, ...car }
      }
      return c
    })
    this.setState(state)
  }

  deleteCar = (car) => {
    let state = this.state
    state.cars = state.cars.filter(c => c.id !== car.id)
    this.setState(state)
  }

  // updateCarInDepartment = (car) => {
  //   let dep = [...this.state.departments];
  //   let departments = dep.map(d => {
  //     if (d.id === car.departmentId) {
  //       const cars = d.cars.map(c => {
  //         if (c.id !== car.id) {
  //           return c
  //         } else {
  //           return car
  //         }
  //       })
  //       d.cars = cars
  //     }
  //     return d
  //   })
  //   this.setState({ departments });
  // }

  // deleteCar = (car) => {
  //   let dep = [...this.state.departments];
  //   let departments = dep.map(d => {
  //     if (d.id === car.departmentId) {
  //       const cars = d.cars.filter(c => c.id !== car.id)
  //       d.cars = cars
  //     }
  //     return d
  //   })
  //   this.setState({ departments });
  // }

  //Bookings
  setBookings = (bookings) => {
    this.setState({ bookings })
  }

  addBooking = (booking) => {
    let state = this.state
    let bookings = state.bookings.filter(b => b.id !== booking.id)
    state.bookings = bookings
    state.bookings.push(booking)
    this.setState(state)
  }

  updateBooking = (booking) => {
    let state = this.state
    state.bookings = state.bookings.filter(b => b.id !== booking.id)
    state.bookings.push(booking)
    this.setState(state)
  }

  //Flowstate
  setFlowId = (id) => {
    let state = this.state
    state.flowstate.id = id
    this.setState(state)
  }

  setFlowDriverLicense = (driverLicenseNumber) => {
    let state = this.state
    state.flowstate.customer.driverLicenseNumber = driverLicenseNumber
    this.setState(state)
  }

  setFlowAddress = (address, zip, city) => {
    let state = this.state
    state.flowstate.customer.address = address
    state.flowstate.customer.zip = zip
    state.flowstate.customer.city = city
    this.setState(state)
  }

  setFlowCpr = (cpr) => {
    let state = this.state
    state.flowstate.customer.cpr = cpr
    this.setState(state)
  }

  setFlowToFinish = (toFinish) => {
    let state = this.state
    state.flowstate.toFinish = toFinish
    this.setState(state)
  }

  setFlowCustomer = (customer) => {
    let state = this.state
    state.flowstate.customer = customer
    this.setState(state)
  }

  setFlowCar = (car) => {
    let state = this.state
    state.flowstate.car = car
    this.setState(state)
  }

  setFlowLastInfo = (email, phone, b2c, price, km, note) => {
    let state = this.state
    state.flowstate.customer.email = email
    state.flowstate.customer.phone = phone
    state.flowstate.customer.b2c = b2c
    state.flowstate.price = price
    state.flowstate.km = km
    state.flowstate.note = note
    this.setState(state)
  }

  // setFlowDriverLicense = (uri) => {
  //   let state = this.state
  //   state.flowstate.customer.license = uri
  //   this.setState(state)
  // }

  setFlowConsent = (consent) => {
    let state = this.state
    state.flowstate.customer.isSubscribed = consent.isSubscribed
    state.flowstate.customer.personalData = consent.personalData
    state.flowstate.customer.termsOfUse = consent.termsOfUse
    this.setState(state)
  }

  setFlowSignature = (uri) => {
    let state = this.state
    state.flowstate.sign = uri
    this.setState(state)
  }

  setFlowDepartment = (department) => {
    let state = this.state
    state.flowstate.department = department
    this.setState(state)
  }

  setFlowUser = (user) => {
    let state = this.state
    state.flowstate.user = user
    this.setState(state)
  }

  setFlowTestDrive = (isTestDrive) => {
    let state = this.state
    state.flowstate.testDrive = isTestDrive
    this.setState(state)
  }

  setFlowTime = (start, end) => {
    let state = this.state
    state.flowstate.start = start
    state.flowstate.end = end
    this.setState(state)
  }

  setFlowDriver = (driver) => {
    let state = this.state
    state.flowstate.drivers.push(driver)
    this.setState(state)
  }

  setFlowTestPlate = (testPlate) => {
    let state = this.state
    state.flowstate.testPlate = testPlate
    this.setState(state)
  }

  setFlowFinished = (finished) => {
    let state = this.state
    state.flowstate.finished = finished
    this.setState(state)
  }

  setCurrentEvent = (event) => {
    let state = this.state
    state.currentEvent = event
    this.setState(state)
  }

  setSingleBooking = (single) => {
    let state = this.state
    state.flowstate.singleBooking = single
    this.setState(state)
  }

  removeEvent = (event) => {
    let state = this.state
    state.bookings = state.bookings.filter(b => b.id !== event.id)
    this.setState(state)
  }

  setFlowSubject = (subject) => {
    let state = this.state
    state.flowstate.subject = subject
    this.setState(state)
  }

  setSelectedTime = (id) => {
    let state = this.state
    state.flowstate.selectedTime = id
    this.setState(state)
  }

  setChooseNewTime = (chooseNewTime) => {
    let state = this.state
    state.chooseNewTime = chooseNewTime
    this.setState(state)
  }

  setConsentFullFilled = (consentFullFilled) => {
    let state = this.state
    state.consentFullFilled = consentFullFilled
    this.setState(state)
  }

  setScrollPos = (pos) => {
    let state = this.state
    state.scrollPos = pos
    this.setState(state)
  }

  setExternalLead = (external) => {
    let state = this.state
    state.externalLead = external
    this.setState(state)
  }

  setPlates = (plates) => {
    let state = this.state
    state.plates = plates
    this.setState(state)
  }

  setHasFetched = (hasFetched) => {
    let state = this.state
    state.hasFetched = hasFetched
    this.setState(state)
  }

  setHasFetchedTS = (hasFetched) => {
    let state = this.state
    state.hasFetchedTS = hasFetched
    this.setState(state)
  }

  setFetchCal = (fetchCal) => {
    let state = this.state
    state.fetchCal = fetchCal
    this.setState(state)
  }

  setTimeSlots = (slots) => {
    let state = this.state
    state.timeSlots = slots
    this.setState(state)
  }

  setiFrameLead = (isiFrame) => {
    let state = this.state
    state.iFrameLead = isiFrame
    this.setState(state)
  }

  setHideSeriesBar = (hide) => {
    let state = this.state
    state.hideSeriesBar = hide
    this.setState(state)
  }

  createMessageBox = (box) => {
    this.setState({messageBox: box})
  }

  setBookingExternal = (external) => {
    let state = this.state
    state.flowstate.external = external
    this.setState(state)
  }

  clearFlow = () => {
    const flowstate = {
      id: null,
      customer: {
        firstName: "",
        license: null,
        driverLicenseNumber: ""
      },
      car: {},
      department: {},
      user: {},
      start: null,
      end: null,
      sign: null,
      testDrive: false,
      drivers: [],
      finished: false,
      license: null,
      note: "",
      toFinish: false,
      km: 150,
      price: 0,
      selectedTime: null,
      testPlate: "",
      singleBooking: false,
      external: false
    }
    this.setState({ hasFetchedTS: false, consentFullFilled: false, currentEvent: {} })
    this.setState({ flowstate })
  }

  askLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({ currentUserPos: position })
      }, null,
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        });
    } else {
    }
  }

  render() {
    this.askLocation()
    const state = {
      //User state management
      users: this.state.users,
      setUsers: this.setUsers,
      addUserToList: this.addUserToList,
      updateUser: this.updateUser,
      deleteUser: this.deleteUser,

      //Currentuser state management
      currentUser: this.state.user,
      currentUserPos: this.state.currentUserPos,
      setCurrentUser: this.setCurrentUser,
      askLocation: this.askLocation,

      //Department state management
      departments: this.state.departments,
      setDepartments: this.setDepartments,
      addDepartmentToList: this.addDepartmentToList,
      updateDepartment: this.updateDepartment,
      deleteDepartment: this.deleteDepartment,

      //Car state management
      cars: this.state.cars,
      addNewCarToDepartment: this.addNewCar,
      updateCarInDepartment: this.updateCar,
      deleteCar: this.deleteCar,
      setCars: this.setCars,

      //Booking state management
      bookings: this.state.bookings,
      calendarAppointments: this.state.calendarAppointments,
      setBookings: this.setBookings,
      addBooking: this.addBooking,
      updateBooking: this.updateBooking,
      removeEvent: this.removeEvent,
      plates: this.state.plates,
      setPlates: this.setPlates,

      //Flowstate management
      flow: this.state.flowstate,
      flowSetCustomer: this.setFlowCustomer,
      flowSetCar: this.setFlowCar,
      flowSetLastInfo: this.setFlowLastInfo,
      flowSetSubject: this.setFlowSubject,
      flowSetConsent: this.setFlowConsent,
      flowSetDepartment: this.setFlowDepartment,
      flowSetUser: this.setFlowUser,
      flowSetTime: this.setFlowTime,
      flowSetSignature: this.setFlowSignature,
      flowSetTestDrive: this.setFlowTestDrive,
      flowSetDriver: this.setFlowDriver,
      flowSetFinished: this.setFlowFinished,
      flowClear: this.clearFlow,
      flowSetId: this.setFlowId,
      flowSetToFinish: this.setFlowToFinish,
      flowSetSelectedTime: this.setSelectedTime,
      flowSetTestPlate: this.setFlowTestPlate,
      flowSetCpr: this.setFlowCpr,
      flowSetDriverLicense: this.setFlowDriverLicense,
      flowSetSingleBooking: this.setSingleBooking,
      flowSetAddress: this.setFlowAddress,
      flowSetExternal: this.setBookingExternal,

      //SingleEventManagement
      currentEvent: this.state.currentEvent,
      setCurrentEvent: this.setCurrentEvent,

      //Stepper
      maxStep: this.state.maxStep,

      //Scrollpos
      scrollPos: this.state.scrollPos,
      setScrollPos: this.setScrollPos,

      //chooseNewTime
      chooseNewTime: this.state.chooseNewTime,
      setChooseNewTime: this.setChooseNewTime,

      external: this.state.externalLead,
      setExternalLead: this.setExternalLead,

      hasFetched: this.state.hasFetched,
      setHasFetched: this.setHasFetched,

      hasFetchedTS: this.state.hasFetchedTS,
      setHasFetchedTS: this.setHasFetchedTS,

      fetchCal: this.state.fetchCal,
      setFetchCal: this.setFetchCal,

      timeSlots: this.state.timeSlots,
      setTimeSlots: this.setTimeSlots,

      consentFullFilled: this.state.consentFullFilled,
      setConsentFullFilled: this.setConsentFullFilled,

      iFrameLead: this.state.iFrameLead,
      setiFrameLead: this.setiFrameLead,

      hideSeriesBar: this.state.hideSeriesBar,
      setHideSeriesBar: this.setHideSeriesBar,
    
      messageBox: this.state.messageBox,
      createMessageBox: this.createMessageBox
    }

    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return (
            <React.Fragment>
              <StateManagementProvider value={state}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <Switch>
                    <Route exact path="/" component={ExternalSite} />
                    <Route exact path="/testdrive" component={ExternalSite} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forgotpassword" component={ForgotPass} />
                    <Route exact path="/terms" component={Terms} />
                    <ProtectedRoute exact path="/workspace" component={Workspace} />
                    <ProtectedRoute exact path="/dashboard" component={Dashboard} />
                    <Route path="*" component={() => "404 Not Found"} />
                  </Switch>
                </ThemeProvider>
              </StateManagementProvider>
            </React.Fragment>
          );
        }}
      </CacheBuster>
    );
  }
}

const middlewares = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
//TagManager.initialize(tagManagerArgs)
ReactDOM.render(<Provider store={store}><BrowserRouter><App /></BrowserRouter></Provider>, document.getElementById('root'));
