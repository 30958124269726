import React, { useEffect, useContext, useState } from 'react'
import {
    BrowserView,
    MobileView
} from "react-device-detect";
import MainExternalBrowser from './MainExternalBrowser'
import { makeStyles } from '@material-ui/core/styles';
import StateManagementContext from '../../context/StateManagementContext';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import TestCars from '../components/TestCarsExternal';
import Datafacade from '../../datafacade/datafacade';
import Geo from '../../geocoding/geocode';

const seriesData = ["1", "2", "3", "4", "5", "6", "7", "8", "x", "z", "i", "m", "mini"]


const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: 'white',
        position: 'fixed',
        top: 0,
        width: '100%'
    },
    root: {
        margin: '0 auto',
        maxWidth: 1280,
        maxHeight: 620
    }
}));

export default function External(props) {
    const state = useContext(StateManagementContext)
    const location = useLocation();
    const [hideContainer, setHideContainer] = useState(null)
    const [departments, setDepartments] = useState([]);
    const [cars, setCars] = useState([]);
    const [position, setPosition] = useState(undefined)
    const [positionAck, setPositionAck] = useState(false)
    const [seriesIndex, setSeriesIndex] = useState(0)
    const classes = useStyles()


    useEffect(() => {
        state.setExternalLead(true)
        const params = queryString.parse(location.search)
        const hideContainer = params.hidecontainer === "false" || params.hidecontainer === undefined ? false : true
        const hideSeries = params.hideseries === "false" || params.hideseries === undefined ? false : true
        setHideContainer(hideContainer)
        state.setHideSeriesBar(hideSeries)
        let id = 0
        const series = params.series
        seriesData.forEach((s, i) => {
            if (s === series) {
                id = i
            }
        })
        setSeriesIndex(id)
    }, [])

    const calculateDistance = (departments) => {
        departments.forEach(dep => {
            let distance = NaN
            const depCoord = [dep.lat, dep.lng]
            if (position !== undefined) {
                distance = Geo.calculateDistance([position.coords.latitude, position.coords.longitude], depCoord)
            }
            dep.distance = distance
        })
    }

    const askLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setPosition(position)
                setPositionAck(true)
            }, null,
                {
                    enableHighAccuracy: true,
                    timeout: 3000,
                    maximumAge: 0
                });
        } else {
            setPositionAck(true)
        }
    }

    const sortByDistance = (departments) => {
        departments.sort((a, b) => (a.distance > b.distance) ? 1 : -1)
    }

    const setState = async () => {
        let departments = null
        let cars = null
        departments = await Datafacade.getAllDepartments()
        cars = await Datafacade.getAllCars()

        // departments.forEach(department => {
        //     if (isNaN(department.distance)) {
        //         departments.push(departments.splice(departments.indexOf(department), 1)[0]);
        //     }
        // }
        setDepartments(departments)
        setCars(cars)
    }

    useEffect(() => {
        if (positionAck) {
            calculateDistance(departments)
            sortByDistance(departments)
        } else {
            askLocation()
            setState()
        }

    }, [positionAck])


    if (hideContainer !== null) {
        if (hideContainer) {
            if(!state.iFrameLead)
                state.setiFrameLead(true)
            return (
                <div className={classes.background}>
                    <div className={classes.root}>
                        <TestCars departments={departments} cars={cars} seriesIndex={seriesIndex} external={true} />
                    </div>
                </div>
            )
        } else {
            return (
                <React.Fragment>
                    <MainExternalBrowser departments={departments} cars={cars} seriesIndex={seriesIndex} />
                </React.Fragment>
            )
        }
    } else {
        return (
            <React.Fragment></React.Fragment>
        )
    }
}