// import decode from 'jwt-decode';
import Auth from '../auth/Auth';
import { API_URL, VISION_URL } from '../helpers/Helpers';
import moment from 'moment';


// const url = "http://localhost:8084/JanNygaardRest/api/";

function makeOptions(method, body, type) {
    const auth = new Auth()
    var opts = {
        method: method,
        headers: {
            'Content-Type': type,
            'x-access-token': auth.getToken()
        }
    }
    if (body) {
        opts.body = JSON.stringify(body);
    }
    return opts;
}

function handleHttpErrors(res) {
    const json = res.json()
    if (res.ok) {
        return json;
    } else {
        return json.then(err => {throw err;})
    }
}

function handleHttpErrorsText(res) {
    const text = res.text()
    if (res.ok) {
        return text;
    } else {
        return text.then(err => {throw err;})
    }
}



class DataFacade {
    constructor() {
        this.auth = new Auth()
    }
    resetPassword = (email) => {
        return fetch(API_URL + "user/forgotpassword/" + email).then(handleHttpErrors)
    }

    getUserDetails = (email) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "user/" + email, makeOptions('GET', null, 'application/json')).then(handleHttpErrors)
    }

    getAvailableCarsForDepartment = (departmentId) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "car/department/" + departmentId, makeOptions('GET', null, 'application/json')).then(handleHttpErrors)
    }

    getAllUsersFromDepartment = (departmentId) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "user", makeOptions('GET', null, 'application/json')).then(handleHttpErrors)
    }

    getBookingsFromDepartment = (departmentId) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "booking/" + departmentId, makeOptions('GET', null, 'application/json')).then(handleHttpErrors)
    }

    getAllCars = () => {
        return fetch(API_URL + "car", makeOptions('GET', null, 'application/json')).then(handleHttpErrors)
    }

    getAllDepartments = () => {
        return fetch(API_URL + "department", makeOptions('GET', null, 'application/json')).then(handleHttpErrors)
    }

    getBookingsByCar = (id) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "booking/car/" + id, makeOptions('GET', null, 'application/json')).then(handleHttpErrors)
    }

    getAllBookings = (days) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "booking/calendar/" + days, makeOptions('GET', null, 'application/json')).then(handleHttpErrors)
    }

    getAllAdminBookings = (departmentId) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "booking/" + departmentId, makeOptions('GET', null, 'application/json')).then(handleHttpErrors)
    }

    searchCpr = (cpr, dlNumber, user) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "customer/cpr/" + cpr + "/" + dlNumber, makeOptions('POST', user, 'application/json')).then(handleHttpErrors)
    }

    getAvailableSlots = (date, carId) => {
        return fetch(API_URL + "booking/slots/" + date + "/" + carId, makeOptions('GET', null, 'application/json')).then(handleHttpErrors)
    }

    getAvailableSlotsExternal = (date, carId) => {
        return fetch(API_URL + "booking/slots/external/" + date + "/" + carId, makeOptions('GET', null, 'application/json')).then(handleHttpErrors)
    }

    deleteCar = (car) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "car", makeOptions('DELETE', car, 'application/json'))
    }

    deleteUser = (user) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "user", makeOptions('DELETE', user, 'application/json'))
    }

    deleteDepartment = (dep) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "department", makeOptions('DELETE', dep, 'application/json'))
    }

    addNewCar = (car) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "car", makeOptions('POST', car, 'application/json')).then(handleHttpErrors)
    }

    addNewUser = (user) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "user", makeOptions('POST', user, 'application/json')).then(handleHttpErrors)
    }

    addDepartment = (dep) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "department", makeOptions('POST', dep, 'application/json')).then(handleHttpErrors)
    }

    addBooking = (booking) => {
        return fetch(API_URL + "booking", makeOptions('POST', booking, 'application/json')).then(handleHttpErrors)
    }

    updateBooking = (booking) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "booking", makeOptions('PUT', booking, 'application/json')).then(handleHttpErrors)
    }

    updateBookingStatus = (id, status) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + `booking/alter/${id}/${status}`, makeOptions('PUT', '', 'application/json')).then(handleHttpErrors)
    }

    finishBooking = (booking) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "booking/alter", makeOptions('PUT', booking, 'application/json')).then(handleHttpErrors)
    }

    updateUser = (user) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "user", makeOptions('PUT', user, 'application/json'))
    }

    deleteBooking = (booking) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "booking", makeOptions('DELETE', booking, 'application/json')).then(handleHttpErrors)
    }

    updateCar = (car) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "car", makeOptions('PUT', car, 'application/json'))
    }

    disableCar = (id, disable) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "car/disable/" + id + "/" + disable, makeOptions('PUT', null, 'application/json')).then(handleHttpErrors)
    }

    updateDepartment = (dep) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "department", makeOptions('PUT', dep, 'application/json'))
    }

    changePassword = (pass) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "user/changepassword", makeOptions('POST', pass, 'application/json')).then(handleHttpErrors)
    }

    cancelBooking = (id) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "booking/cancel/" + id, makeOptions('GET', null, 'application/json')).then(handleHttpErrors)
    }

    getPlates = (departmentId) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "booking/plates/" + departmentId, makeOptions('GET', null, 'application/json')).then(handleHttpErrors)
    }

    getBookingsFromDate = (date) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "booking/day/" + date, makeOptions('GET', null, 'application/json')).then(handleHttpErrors)
    }

    getFutureBookingsFromDate = (date, depId) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "booking/future/" + depId + "/" + date, makeOptions('GET', null, 'application/json')).then(handleHttpErrors)
    }

    getOneDayBookings = (carId) => {
        const date = moment().subtract(1, "days")
        if (this.auth.loggedIn())
            return fetch(API_URL + "booking/endafter/" + date.valueOf() + "/" + carId, makeOptions('GET', null, 'application/json')).then(handleHttpErrors)
    }

    getStats = (start, end) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "stat/" + start.valueOf() + "/" + end.valueOf(), makeOptions('GET', null, 'application/json')).then(handleHttpErrors)
    }

    isAvailableNow = (start, carId) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + "booking/available/" + start.valueOf() + "/" + carId, makeOptions('GET', null, 'application/json')).then(handleHttpErrors)
    }

    getTestdrivesAsCSV = (start, end) => {
        if (this.auth.loggedIn())
            return fetch(API_URL + `stat/testdrives/${start}/${end}/csv`, makeOptions('GET', null, 'text/csv')).then(handleHttpErrorsText)
    }

    requestOCR = (dataUri) => {
        if (this.auth.loggedIn()) {
            let opts = {
                method: 'POST',
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
            let body = {
                requests: [
                    {
                        image: {
                            content: dataUri.slice(22),
                        },
                        features: [{
                            type: "TEXT_DETECTION",
                            maxResults: 5
                        }]
                    }
                ]
            }
            opts.body = JSON.stringify(body);
            return fetch(VISION_URL, opts).then(handleHttpErrors)
        }
    }

}

export default new DataFacade();