import React, { useContext, useEffect } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import IconButton from '../../utilComponents/IconButton';
import TableGrid from '../../utilComponents/TableGrid';
import SettingsIcon from '../../../assets/icons/setting48grey.png';
import PDFIcon from '../../../assets/icons/pdf48grey.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddIcon from '../../../assets/icons/Add.png';
import AddIconHover from '../../../assets/icons/AddDark.png';
import StateManagementContext from '../../../context/StateManagementContext';
import Carousel from '../../carousel/Carousel';
import UpdateCar from './UpdateCar';
import AddNewCar from './AddNewCar';
import Checkbox from '../utils/Checkbox';
import facade from '../../../datafacade/datafacade';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '30px',
        '@media (max-width: 767px)': {
            marginTop: '60px'
        },
    },
    header: {
        fontFamily: 'bmwnextthin',
        textAlign: "center",
        margin: 'auto',
        fontSize: 25,
        marginBottom: 80,
        marginTop: -40
    },
    main: {
        margin: 'auto',
        padding: 15,
        width: '90%'
    },
    addIcon: {
        textAlign: 'left',
        paddingBottom: 10,
        paddingLeft: 20
    }
}))


const seriesData = ["1-Serie", "2-Serie", "3-Serie", "4-Serie", "5-Serie", "6-Serie", "7-Serie", "8-Serie", "X", "Z", "BMW i", "M", "MINI"]

export default function Cars(props) {
    const xs = useMediaQuery(theme => theme.breakpoints.down(767));
    const openSettings = (e) => {
        const id = parseInt(e.target.id)
        setHeader("Rediger bil")
        setComponent(<UpdateCar carId={id} handleClose={openTable} />)
    }

    const openAddNewCar = (e) => {
        setHeader("Tilføj bil")
        setComponent(<AddNewCar handleClose={openTable} />)
    }

    const openTable = (e) => {
        setHeader('')
        setComponent(<TableGrid aria="car table" collection={generateData()} />)
    }

    const disableCar = async (id, disabled) => {
        try {
            const car = await facade.disableCar(id, disabled)
            state.updateCarInDepartment(car)
        } catch(err) {

        }
        
    }

    const generateData = () => {
        const filteredCars = state.cars.filter(car => car.series === seriesData[seriesBySlider])
        filteredCars.sort((a, b) => (a.model > b.model) ? 1 : -1)
        const collection = filteredCars.map(car => {
            if (xs) {
                return [
                    `${car.manufacturer} ${car.model} ${car.variant} ${car.equipment}`,
                    <img id={car.id} alt="" style={{ cursor: 'pointer' }} onClick={() => { }} src={PDFIcon} height="28" width="28"></img>,
                    <img id={car.id} alt="" style={{ cursor: 'pointer' }} onClick={openSettings} src={SettingsIcon} height="28" width="28"></img>
                ]
            } else {
                return [
                    <Checkbox id={car.id} callback={disableCar} disabled={car.disabled}/>,
                    `${car.manufacturer} ${car.model} ${car.variant} ${car.equipment}`,
                    car.departmentId,
                    car.vin,
                    car.licensePlate,
                    car.fuel,
                    <img id={car.id} alt="" style={{ cursor: 'pointer' }} onClick={() => { }} src={PDFIcon} height="28" width="28"></img>,
                    <img id={car.id} alt="" style={{ cursor: 'pointer' }} onClick={openSettings} src={SettingsIcon} height="28" width="28"></img>
                ]
            }
        })
        return collection
    }

    const classes = useStyles()
    const state = useContext(StateManagementContext)
    const [seriesBySlider, setSeriesBySlider] = React.useState(0)
    const [component, setComponent] = React.useState()
    const [header, setHeader] = React.useState('')

    useEffect(() => {
        openTable()
        // eslint-disable-next-line
    }, [xs, seriesBySlider, state])

    const callback = (id) => {
        setSeriesBySlider(id)
    }

    return (
        <div className={classes.root}>
            <Grid container className={classes.main}>
                {header === '' ?
                    <Grid item xs={12} style={{ display: 'flex-center', textAlign: 'center' }}>
                        <Carousel
                            title="THE"
                            array={['1', '2', '3', '4', '5', '6', '7', '8', 'X', 'Z', 'i', 'M', 'MINI']}
                            callback={callback}
                            index={0}
                        />
                    </Grid>
                    : null}
                {header === '' ?
                    <Grid item xs={12} className={classes.addIcon}>
                        <IconButton title="Tilføj" rotate={null} onClick={openAddNewCar} height={25} width={25} icon={AddIcon} hoverIcon={AddIconHover} />
                    </Grid>
                    : null}
                <Grid item xs={12}>
                    {component}
                </Grid>
            </Grid>
        </div>
    )
}