import React from "react";
import SimpleViewSlider from "react-view-slider/simple";
import MonthView from "./pages/month-view";
import DayView from "./pages/day-view";
import ManualCustomer from "./pages/manual-customer";
import SearchCustomer from "./pages/search-customer";
import LicensePlate from "./pages/license-plate";
import Picture from "./pages/picture";
import TermsConditions from "./pages/terms-conditions";
import Signature from "./pages/signature";
import Success from "./pages/success";
import LastInformation from "./pages/last-information";
import NewAppointment from "./pages/new-appointment";
import AppointmentView from "./pages/view-appointment";
import { connect } from "react-redux";
import { FLOW_MODES } from "./constant";
import { setPageID } from "../store/actions";
import StateManagementContext from '../../../context/StateManagementContext';
import {
  PAGE_INDEX
} from "./constant";
import "./main.css";
import { slideInUp, slideOutDown } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import ProgressBar from "../../utilComponents/Progress/ProgressBar";
import facade from '../../../datafacade/datafacade';
import { CircularProgress } from "@material-ui/core";
import moment from 'moment';

const styles = {
  slideIn: {
    animation: 'x .5s',
    animationName: Radium.keyframes(slideInUp, 'slideInUp')
  },
  slideOutDown: {
    animation: 'x .5s',
    animationName: Radium.keyframes(slideOutDown, 'slideOutDown')
  }
}

class Calendar extends React.Component {
  constructor(props) {
    super(props)
    this.mainRef = React.createRef();
    this.state = {
      maximumStep: this.props.maximumStep,
      currentStep: this.props.maximumStep,
      mobileStepper: ['1', '2', '3', '4', '5', '6', '7'],
      headers: ["Tidspunkt", "Vilkår & betingelser", "Kørekort", "Oplysninger", "Underskrift", "Opretter prøvekørsel.."],
      tempHeader: "",
      showStepper: false,
      close: false,
      showNav: true,
      minimumStep: 0,
      loading: true
    }
  }

  static contextType = StateManagementContext
 
  componentDidMount = async () => {
    
    const state = this.context
    if (this.state.loading && this.props.pageID > 3 && this.props.car.id !== undefined) {
      const available = await facade.isAvailableNow(moment().valueOf(), this.props.car.id)
      if (available) {
        this.setState({ maximumStep: 1, currentStep: 1 }, this.props.termsConditions)
        state.flowSetTime(moment().valueOf(), moment().add(30, 'minutes').valueOf())
        state.flowSetSelectedTime(0)
      }
    }
    this.setState({loading: false})
    if (this.props.driversNote) {
      this.setState({ minimumStep: 1 })
      const mainRef = this.mainRef.current
      if (window.devicePixelRatio > 2) {
        mainRef.style.height = "78%";
      } else {
        mainRef.style.height = "87%";
      }
    }
    this.setState({ showStepper: this.props.showStepper })
    this.setState({ close: false })
  }

  setStepper = (maximumStep) => {
    this.setState({ maximumStep })
  }

  setCurrentStep = (currentStep) => {
    this.setState({ currentStep })
  }

  showStepper = (showStepper) => {
    this.setState({ showStepper })
  }

  close = () => {
    const state = this.context
    state.flowClear()
    this.setState({ close: true })
    setTimeout(() => { this.props.close() }, 500)
  }

  setTempHeader = (header) => {
    this.setState({ tempHeader: header })
  }

  setHideNav = (hideNav) => {
    this.setState({ showNav: !hideNav })
  }

  render() {
    const { pageID, driversNote } = this.props;
    const callback = (id) => {
      if (id < this.state.currentStep) this.setCurrentStep(id)
      switch (id) {
        case 0:
          this.props.dayview2()
          break;
        case 1:
          this.props.termsConditions()
          break;
        case 2:
          this.props.camera()
          break;
        case 3:
          this.props.lastInformation()
          break;
        case 4:
          this.props.signature()
          break;
        case 5:
          this.props.success()
          break;
      }
    }

    const pages = [
      <MonthView setStepper={this.setStepper} setCurrentStep={this.setCurrentStep} />,
      <MonthView setStepper={this.setStepper} setCurrentStep={this.setCurrentStep} />,
      <LicensePlate setTempHeader={this.setTempHeader} setMainHigh={this.setMainHigh} car={this.props.car} setCurrentStep={this.setCurrentStep} setStepper={this.setStepper} />,
      <DayView setMainHigh={this.setMainHigh} mode={FLOW_MODES.flow1} car={this.props.car} setCurrentStep={this.setCurrentStep} setStepper={this.setStepper} />,
      <DayView setMainHigh={this.setMainHigh} mode={FLOW_MODES.flow2} car={this.props.car} setCurrentStep={this.setCurrentStep} setStepper={this.setStepper} />,
      <AppointmentView setLowMain={this.setLowMain} mode={1} showStepper={this.showStepper} close={this.props.close} setCurrentStep={this.setCurrentStep} setStepper={this.setStepper} />,
      <AppointmentView setLowMain={this.setLowMain} mode={2} showStepper={this.showStepper} close={this.props.close} setCurrentStep={this.setCurrentStep} setStepper={this.setStepper} />,
      <TermsConditions setTempHeader={this.setTempHeader} setStepper={this.setStepper} setCurrentStep={this.setCurrentStep} />,
      <Picture setTempHeader={this.setTempHeader} setStepper={this.setStepper} setCurrentStep={this.setCurrentStep} />,
      <SearchCustomer setTempHeader={this.setTempHeader} car={this.props.car} setCurrentStep={this.setCurrentStep} setStepper={this.setStepper} />,
      <ManualCustomer car={this.props.car} setCurrentStep={this.setCurrentStep} setStepper={this.setStepper} />,
      <LastInformation setTempHeader={this.setTempHeader} close={this.props.close} setCurrentStep={this.setCurrentStep} setStepper={this.setStepper} />,
      <Signature setStepper={this.setStepper} setCurrentStep={this.setCurrentStep} />,
      <NewAppointment car={this.props.car} close={this.props.close} setCurrentStep={this.setCurrentStep} setStepper={this.setStepper} />,
      <Success setTempHeader={this.setTempHeader} setHideNav={this.setHideNav} mainRef={this.mainRef} close={this.close} setCurrentStep={this.setCurrentStep} setStepper={this.setStepper} />
    ];

    return (
      <StyleRoot>
        <div>
          <div className="backdrop" onClick={this.close}></div>
          <div ref={this.mainRef} className="main" style={this.state.close ? styles.slideOutDown : styles.slideIn}>
            {this.state.loading && this.props.pageID !== 2 ?
              <div className="main-spinner"><CircularProgress /></div>
              :
              <React.Fragment>
                {pageID === 0 || pageID === 1 || pageID === 2 || !this.state.showStepper ?
                  null :
                  <div style={{ marginLeft: 30, marginRight: 30 }}>
                    <ProgressBar
                      headers={this.state.headers}
                      tempHeader={this.state.tempHeader}
                      callback={callback}
                      maximumStep={this.state.maximumStep}
                      curStep={this.state.currentStep}
                      setTempHeader={this.setTempHeader}
                      showNav={this.state.showNav}
                      minimumStep={this.state.minimumStep}
                    />
                  </div>}

                <div className="pages">
                  <SimpleViewSlider>
                    <div key={pageID}>{pages[pageID]}</div>
                  </SimpleViewSlider>
                </div>
              </React.Fragment>
            }
          </div>
        </div>
      </StyleRoot>
    );
  }
}

const mapStateToProps = state => {
  return {
    pageID: state.pageID
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dayview2: () => dispatch(setPageID(PAGE_INDEX.DAY_VIEW_2_2)),
    searchCustomer: () => dispatch(setPageID(PAGE_INDEX.SEARCH_CUSTOMER)),
    dayview1: () => dispatch(setPageID(PAGE_INDEX.DAY_VIEW_1_2)),
    lastInformation: () => dispatch(setPageID(PAGE_INDEX.LAST_INFORMATIONS)),
    camera: () => dispatch(setPageID(PAGE_INDEX.PICTURE)),
    termsConditions: () => dispatch(setPageID(PAGE_INDEX.TERMS_AND_CONDITIONS)),
    signature: () => dispatch(setPageID(PAGE_INDEX.SIGNATURE)),
    success: () => dispatch(setPageID(PAGE_INDEX.DONE))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
