import React, { useContext, useEffect } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import IconButton from '../../utilComponents/IconButton';
import TableGrid from '../../utilComponents/TableGrid';
import AddNewUser from './AddNewUser';
import SettingsIcon from '../../../assets/icons/setting48grey.png';
import AddIcon from '../../../assets/icons/Add.png';
import AddIconHover from '../../../assets/icons/AddDark.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import StateManagementContext from '../../../context/StateManagementContext';
import UpdateUserForm from './UpdateUser';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '30px',
        '@media (max-width: 767px)': {
            marginTop: '60px'
        },
    },
    header: {
        fontFamily: 'bmwnextthin',
        textAlign: "center",
        fontSize: 25
    },
    main: {
        margin: 'auto',
        paddingTop: 15,
        width: '90%'
    },
    addIcon: {
        textAlign: 'left',
        paddingBottom: 10,
        paddingLeft: 20
    }
}))

export default function Users(props) {
    const xs = useMediaQuery(theme => theme.breakpoints.down(767));
    const openSettings = (e) => {
        const id = parseInt(e.target.id)
        setHeader("Rediger bruger")
        setComponent(<UpdateUserForm userId={id} handleClose={openTable} />)
    }

    const openAddNewUser = (e) => {
        setHeader("Tilføj bruger")
        setComponent(<AddNewUser handleClose={openTable} />)
    }

    const openTable = (e) => {
        setHeader("Brugere")
        setComponent(<TableGrid aria="user table" collection={generateData()} />)
    }

    const generateData = () => {
        const collection = state.users.map(user => {
            if (xs) {
                return [
                    user.firstName + " " + user.lastName,
                    user.role,
                    <img id={user.id} alt="" style={{ cursor: 'pointer' }} onClick={openSettings} src={SettingsIcon} height="28" width="28"></img>
                ]
            } else {
                return [
                    user.departmentId,
                    user.initials,
                    user.firstName + " " + user.lastName,
                    user.email,
                    user.phoneNumber,
                    user.role,
                    <img id={user.id} alt="" style={{ cursor: 'pointer' }} onClick={openSettings} src={SettingsIcon} height="28" width="28"></img>
                ]
            }
        })
        return collection
    }


    const classes = useStyles()
    const state = useContext(StateManagementContext)
    const [component, setComponent] = React.useState()
    const [header, setHeader] = React.useState("Brugere")

    useEffect(() => {
        openTable()
        // eslint-disable-next-line
    }, [xs, state])

    return (
        <div className={classes.root}>
            <Grid container className={classes.main}>
                {header === "Brugere" ?
                    <Grid item xs={12} className={classes.addIcon}>
                        <IconButton title="Tilføj" rotate={null} onClick={openAddNewUser} height={25} width={25} icon={AddIcon} hoverIcon={AddIconHover}/>
                    </Grid>
                    : null}
                <Grid item xs={12}>
                    {component}
                </Grid>
            </Grid>
        </div>
    )
}