import React, { useEffect } from 'react'
import Data from '../../../datafacade/datafacade'
import { CircularProgress, Grid, GridList, GridListTile } from '@material-ui/core'
import StateManagementContext from '../../../context/StateManagementContext'
import moment from 'moment'
import { isTablet, isIPad13 } from "react-device-detect";

const WEEK_DAYS = ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"]
const MONTHS = ["januar", "februar", "marts", "april", "maj", "juni", "juli", "august", "september", "oktober", "november", "december"]

export default function DesktopTimeSlots(props) {
    const [slots, setSlots] = React.useState([])
    const [selectedIndex, setSelectedIndex] = React.useState(null)
    const state = React.useContext(StateManagementContext)
    const selectedDate = moment(props.date, "YYYY-MM-DD")
    const [loading, setLoading] = React.useState(true)


    const dateStr = `${WEEK_DAYS[selectedDate.day()]} den ${selectedDate.date()}. ${MONTHS[selectedDate.month()]} ${selectedDate.year()}`

    const shouldFetchOnSelectedDayToday = () => {
        if (state.external) {
            let externalMonthRule = moment().add(30, "days")
            let external24hRuleEnd = moment().add(1, "days")
            const day = props.date.day()
            if (day === 0 || day === 6) return false
            else if (
                props.date.valueOf() <= external24hRuleEnd.valueOf() || 
                props.date.valueOf() > externalMonthRule.valueOf()) 
                return false
            else return true
        } else {
            return true
        }
    }

    const setState = async () => {
        if (shouldFetchOnSelectedDayToday()) {
            if (props.carid !== undefined) {
                setLoading(true)
                let slots = []
                if(state.external) {
                    slots = await Data.getAvailableSlotsExternal(new Date(props.date).getTime(), props.carid)
                } else {
                    slots = await Data.getAvailableSlots(new Date(props.date).getTime(), props.carid)
                }
                
                let timeSlots = Object.keys(slots).map(slot => {
                    return {
                        start: moment(new Date(slots[slot].start).getTime()),
                        end: moment(new Date(slots[slot].end).getTime())
                    };
                });
                setSlots(timeSlots)
                setLoading(false)
            }
        } else {
            setSlots([])
            setLoading(false)
        }
    }


    const handleClickSlot = (id) => {
        state.flowSetTime(slots[id].start.valueOf(), slots[id].end.valueOf())
        state.flowSetSelectedTime(id)
        if (state.chooseNewTime) {
            if (state.flow.toFinish && isTablet || state.flow.toFinish && isIPad13) {
                props.setCurStep(5)
            } else {
                props.setMaxStep(2)
                props.setCurStep(2)
                props.setComponent(2)
            }
        } else {
            props.setMaxStep(1)
            props.setCurStep(1)
            props.setComponent(1)
        }
    }

    const markSelectedTime = () => {
        if (state.flow.selectedTime !== null) {
            setSelectedIndex(state.flow.selectedTime)
        }
    }

    const isNow = (date) => {
        const now = moment().format("YYYY-MM-DD HH:mm")
        const checkDate = date.format("YYYY-MM-DD HH:mm")
        return moment(now).isSame(checkDate)
    }

    useEffect(() => {
        setState()
    }, [props.date])

    useEffect(() => {
        markSelectedTime()
    }, [])


    const Slots = (
        <div>
            <GridList cellHeight={85} cols={isTablet || isIPad13 ? 3 : 5}>
                {slots.map((slot, id) => {
                    const start = moment(new Date(slot.start))
                    const end = moment(new Date(slot.end))
                    return (
                        <GridListTile style={{ width: 88, height: 88 }} key={start}>
                            <div className={state.flow.selectedTime === id ? "desktop-slots desktop-selected" : "desktop-slots"} onClick={() => { handleClickSlot(id) }}>
                                <div className="desktop-slot-start">{isNow(start) ? "Nu" : start.format("HH:mm")}</div>
                                <div className="desktop-slot-middle-text">til</div>
                                <div className="desktop-slot-end">{end.format("HH:mm")}</div>
                            </div>
                        </GridListTile>
                    )
                })}
            </GridList>
        </div>
    )

    return (
        <React.Fragment>
            <div className="desktop-slot-header desktop-flow-header">{dateStr}</div>
            <Grid container style={{ width: isTablet || isIPad13 ? 265 : 440, marginLeft: 50, height: isTablet || isIPad13 ? 260 : 175, overflowY: 'auto', overflowX: 'hidden' }}>
                {loading ? <div style={{ margin: 'auto' }}><CircularProgress /></div> : slots.length > 0 ? Slots :
                    <Grid item xs={12}>
                        <div style={{ fontSize: 16, marginTop: 20, fontFamily: 'bmwnextli' }}>
                            Der er desværre ikke flere tilgængelig tider på den valgte dag. Vælg venligst en anden dag.
                        </div>
                    </Grid>
                }
            </Grid>
        </React.Fragment>
    )
}