import React from "react";
import { connect } from "react-redux";
import { setPageID } from "../../store/actions";
import CustomButton from '../../../customComponents/CustomButton';
import CustomButtonTrans from '../../../customComponents/CustomButtonTrans';
import StateManagementContext from '../../../../context/StateManagementContext';
import TermsStepper from '../subcomponents/calendar/TermsStepper';
import {
  BUTTON_LABELS,
  DESCRIPTIONS,
  PAGE_INDEX,
  PAGE_INDEX_EXTERNAL
} from "../constant";
import { isTablet, isIPad13, isBrowser, isMobileOnly } from "react-device-detect";
import { Grid, Hidden } from "@material-ui/core";

class TermsConditions extends React.Component {
  static contextType = StateManagementContext
  state = {
    fullFilled: false,
    consent: {},
    loading: true
  }
  

  componentDidMount = () => {
    const stateContext = this.context
    if (stateContext.consentFullFilled) {
      this.setState({ fullFilled: true })
    }
  }

  onClick = () => {
    const stateContext = this.context
    if (!stateContext.consentFullFilled) {
      stateContext.flowSetConsent(this.state.consent)
      stateContext.setConsentFullFilled(true)
    }
    if (isTablet || isIPad13 || isBrowser) {
      this.props.setCurStep(2)
      this.props.setMaxStep(2)
    } else {
      if (stateContext.external) {
        this.props.setStepper(2)
        this.props.setCurrentStep(2)
        this.props.onContinueExternal()
      } else {
        this.props.setStepper(2)
        this.props.setCurrentStep(2)
        this.props.onContinue()
      }
    }
  }

  onDone = (consent) => {
    this.setState({ fullFilled: true, consent })
  }

  render() {

    return (
      <React.Fragment>
          <div style={{ paddingLeft: isMobileOnly ? 5 : 45, paddingRight: isMobileOnly ? 0 : 33, paddingTop: isTablet || isIPad13 ? 0 : 13, height: isTablet || isIPad13 ? 600 : '', width: '100%' }}>
          <Grid container spacing={isTablet || isIPad13 ? 4 : 2} style={{ textAlign: "center" }}>
            <Grid item xs={12}>
              <div style={{ overflowX: 'hidden', overflowY: 'auto', height: isMobileOnly ? 325 : '' }}>
                <TermsStepper fullFilled={this.state.fullFilled} onDone={this.onDone} />
                {
                  this.state.fullFilled ?
                    <div style={{ textAlign: 'center', paddingBottom: 70, paddingTop: 20 }}>
                      <CustomButton onClick={this.onClick} disabled={!this.state.fullFilled}>Fortsæt</CustomButton>
                    </div>
                    : null
                }
              </div>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onContinue: () => dispatch(setPageID(PAGE_INDEX.PICTURE)),
    onContinueExternal: () => dispatch(setPageID(PAGE_INDEX_EXTERNAL.LAST_INFORMATIONS)),
    manualCustomer: () => dispatch(setPageID(PAGE_INDEX.SEARCH_CUSTOMER))
  };
};

export default connect(null, mapDispatchToProps)(TermsConditions);
