import React from 'react';
import moment from 'moment'
import ForwardIcon from '../../../assets/icons/ForwardGrey.png'
import BackIcon from '../../../assets/icons/BackGrey.png'
import StateManagementContext from '../../../context/StateManagementContext';

const SHORT_WEEKS = ["Man", "Tir", "Ons", "Tor", "Fre", "Lør", "Søn"];
const MONTHS = ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"];

export default class DesktopCalendar extends React.Component {
    static contextType = StateManagementContext
    constructor(props) {
        super(props)
        this.state = {
            dateObject: moment(),
            monthSelected: 0,
            monthToRender: 0
        }
    }

    componentDidMount = () => {
        this.setState({
            monthSelected: this.month(),
            monthToRender: this.month()
        })
    }

    currentDay = () => {
        return moment();
    };

    month = () => {
        return this.state.dateObject.month();
    };

    handleMonthBackward = () => {
        let dateObject = this.state.dateObject
        let monthNo = this.state.monthSelected

        if (monthNo === 0) {
            monthNo = 11
            dateObject.year(dateObject.year() - 1)
            dateObject.month(11)
        } else {
            monthNo--
            dateObject.month(monthNo)
        }

        this.setState({
            dateObject: dateObject,
            monthSelected: monthNo
        });
    }

    handleMonthForward = () => {
        let dateObject = this.state.dateObject
        let monthNo = this.state.monthSelected

        if (monthNo === 11) {
            monthNo = 0
            dateObject.year(dateObject.year() + 1)
            dateObject.month(0)
        } else {
            monthNo++
            dateObject.month(monthNo)
        }

        this.setState({
            dateObject: dateObject,
            monthSelected: monthNo
        });
    }

    selectDate = (e, d) => {
        let dateObject = this.state.dateObject
        dateObject.date(d)
        this.props.setDate(dateObject.format("YYYY-MM-DD"))
    }

    weekdayshortname = () => {
        const weekdays = SHORT_WEEKS.map(day => {
            return (
                <td key={day} className="desktop-week-day">
                    {day}
                </td>
            );
        });
        return <tr className="week-row">{weekdays}</tr>
    }

    firstDayOfMonth = () => {
        let dateObject = this.state.dateObject;
        let firstDay = moment(dateObject)
            .startOf("month")
            .format("d");
        return firstDay;
    };

    daysInMonth = () => {
        return this.state.dateObject.daysInMonth()
    }

    createDates = () => {
        let blanks = [];
        let firstWeekDay = this.firstDayOfMonth()
        if(firstWeekDay === "0") {
          firstWeekDay = 7
        }
        for (let i = 1; i < firstWeekDay; i++) {
            blanks.push(
                <td key={i * 60} className="desktop-calendar-day desk-cal-empty">{""}</td>
            );
        }
        let daysInMonth = [];
        for (let d = 1; d <= this.daysInMonth(); d++) {
            let currentDay = ""
            let selectedDay = ""
            if (this.state.dateObject.month() == this.currentDay().month() && this.state.dateObject.year() == this.currentDay().year()) {
                if(d == this.currentDay().format("D")) currentDay = "desktop-cal-today"
            } 

            if(d == this.state.dateObject.format("D")) {
                selectedDay = " desktop-calendar-highlight"
            }

            daysInMonth.push(
                <td key={d} onClick={(e) => this.selectDate(e, d)} className="desktop-calendar-day">
                    <div className={currentDay + selectedDay}>
                        {d}
                    </div>
                </td>
            );
        }

        var totalSlots = [...blanks, ...daysInMonth];
        let rows = [];
        let cells = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row); // if index not equal 7 that means not go to next week
            } else {
                rows.push(cells); // when reach next week we contain all td in last week to rows 
                cells = []; // empty container 
                cells.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlots.length - 1) { // when end loop we add remain date
                rows.push(cells);
            }
        });

        return rows.map((d, i) => {
            if (d.length === 0 && i < 2) {
                
            } else {
                return <tr key={i * 80}>{d}</tr>;
            }
        });
    }

    render() {
        const stateContext = this.context
        return (
            <React.Fragment>
                <div className={stateContext.external ? "desktop-month-picker-external" : "desktop-month-picker"}>
                    <img className="desktop-cal-controls" onClick={this.handleMonthBackward} alt="back" src={BackIcon} />
                    <span className="desktop-month-title">{MONTHS[this.state.monthSelected]} {this.state.dateObject.year()}</span>
                    <img className="desktop-cal-controls" onClick={this.handleMonthForward} alt="forward" src={ForwardIcon} />
                </div>
                <table className={stateContext.external ? "desktop-cal-table-external" : "desktop-cal-table"}>
                    <tbody>
                        {this.weekdayshortname()}
                        {this.createDates()}
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}