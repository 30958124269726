import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import moment from 'moment';
import CustomButtonTrans from '../../customComponents/CustomButtonTrans';

export default function SimpleMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [mounted, setMounted] = useState(false)
    const [dates, setDates] = useState({
        start: moment().subtract(7, "days").set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
        end: moment().set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })
    })
    const [state, setState] = useState([
        {
            startDate: new Date(dates.start.valueOf()),
            endDate: new Date(dates.end.valueOf()),
            key: 'selection'
        }
    ]);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (mounted) {
            const start = moment(state[0].startDate.getTime()).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
            const end = moment(state[0].endDate.getTime()).set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })
            setDates({
                start: start,
                end: end
            })
        } else {
            setMounted(true)
        }
    }, [state])

    useEffect(() => {
        props.callback(dates)
    }, [dates])


    return (
        <div>
            <CustomButtonTrans aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                {dates.start.format("DD-MM-YYYY")} - {dates.end.format("DD-MM-YYYY")}
            </CustomButtonTrans>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <DateRange
                    locale={locales.da}
                    editableDateInputs={true}
                    onChange={item => setState([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                />
            </Menu>
        </div>
    );
}
