import React, { createRef } from "react";
import moment from "moment";
import StateManagementContext from '../../../context/StateManagementContext';
import AppointmentView from './AppointmentView';
import { toISOLocal } from '../../../helpers/Helpers';
import {
  SLOT_CONFIG,
  COLOR_SCHEMA,
  MINS_PER_HOUR,
  DATE_FORMAT,
} from "../../calendar/components/constant";

export default class Appointments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openAppView: false,
      clientHeight: 0
    }
  }
  
  appointmentsRef = createRef();
  viewRef = createRef();
  static contextType = StateManagementContext

  componentDidMount() {
    this.setState({ clientHeight: this.viewRef.current.clientHeight })
  }

  eventStartsBeforeToday(event) {
    const { selectedDate } = this.props;
    return moment(moment(new Date(event.startTime), DATE_FORMAT).format("YYYY-MM-DD")).isBefore(moment(selectedDate, DATE_FORMAT))
  }

  eventEndsAfterToday(event) {
    const { selectedDate } = this.props;
    return moment(moment(new Date(event.endTime), DATE_FORMAT).format("YYYY-MM-DD")).isAfter(moment(selectedDate, DATE_FORMAT))
  }

  eventStartsBeforeTodayAndEndsAfterToday(event) {
    return this.eventEndsAfterToday(event) && this.eventStartsBeforeToday(event)
  }

  appView = (openAppView) => {
    this.setState({openAppView})
  }

  getAppointmentsBySelectedDate() {
    const { appointments, selectedDate } = this.props;
    if (!selectedDate) return [];
    if (appointments.length > 0)
      return appointments.filter(e => {
        return  moment(toISOLocal(new Date(e.startTime)).split("T")[0], DATE_FORMAT).date() === moment(selectedDate, DATE_FORMAT).date() || 
                moment(toISOLocal(new Date(e.endTime)).split("T")[0], DATE_FORMAT).date() === moment(selectedDate, DATE_FORMAT).date()
      })
    return [];
  }

  getStartPosFrom(e) {
    if (!e) return 0;
    const mins = this.convertToMins(toISOLocal(new Date(e.startTime)).split("T")[1].split(":"));
    const pxPerMin = SLOT_CONFIG.heightPerThirtyMins / SLOT_CONFIG.nextSlot;
    return pxPerMin * mins;
  }

  getHeightFrom(e) {
    if (!e) return 0;
    const startMins = this.convertToMins(toISOLocal(new Date(e.startTime)).split("T")[1].split(":"));
    const endMins = this.convertToMins(toISOLocal(new Date(e.endTime)).split("T")[1].split(":"));
    const pxPerMin = SLOT_CONFIG.heightPerThirtyMins / SLOT_CONFIG.nextSlot;
    return pxPerMin * Math.abs(endMins - startMins);
  }

  convertToMins(arr) {
    return parseInt(arr[0] * MINS_PER_HOUR) + parseInt(arr[1]);
  }

  onClickAppointment = (event, e) => {
    event.stopPropagation();
    const stateContext = this.context
    stateContext.setCurrentEvent(e)
    this.appView(true)
  };

  calculateTimeFromPos(offsetY) {
    const step = Math.floor(offsetY / SLOT_CONFIG.heightPerThirtyMins);
    const timeObj = {
      startTime: `${
        Math.floor(step / 2) > 9
          ? Math.floor(step / 2)
          : "0" + Math.floor(step / 2)
        }:${step % 2 > 0 ? "30" : "00"}`,
      endTime: `${
        Math.ceil(step / 2) > 9
          ? Math.ceil(step / 2)
          : "0" + Math.ceil(step / 2)
        }:${step % 2 > 0 ? "00" : "30"}`,
    };
    return timeObj;
  }

  render() {
    const slots = () => {
      let slots = [];

      let slotTime = moment(SLOT_CONFIG.startTime, "HH:mm");
      let endTime = moment(SLOT_CONFIG.endTime, "HH:mm");
      while (slotTime < endTime) {
        slots.push(slotTime.format("HH:mm"));
        slotTime = slotTime.add(SLOT_CONFIG.nextSlot, "minutes");
      }
      return slots;
    };

    const styleEvent = (event) => {
      let heightOfEventItem = this.getHeightFrom(event);
      let startPosOfEventItem = this.getStartPosFrom(event);
      if (this.eventStartsBeforeTodayAndEndsAfterToday(event)) {
        startPosOfEventItem = 0
        heightOfEventItem = this.state.clientHeight  - SLOT_CONFIG.heightPerThirtyMins * 1.5
      } else {
        if (heightOfEventItem + startPosOfEventItem > this.state.clientHeight || this.eventEndsAfterToday(event)) {
          heightOfEventItem = this.state.clientHeight - startPosOfEventItem - SLOT_CONFIG.heightPerThirtyMins * 1.5
        }
        if (this.eventStartsBeforeToday(event)) {
          startPosOfEventItem = 0
          const endMins = this.convertToMins(toISOLocal(new Date(event.endTime)).split("T")[1].split(":"));
          const pxPerMin = SLOT_CONFIG.heightPerThirtyMins / SLOT_CONFIG.nextSlot;
          heightOfEventItem = pxPerMin * Math.abs(endMins);
        }
      }
      const backgroundColorOfEvent =
        event.testDrive === true
          ? event.finished ? COLOR_SCHEMA.blueBackgroundFinished : COLOR_SCHEMA.blueBackground
          : COLOR_SCHEMA.greyBackground;
      const paddingTop = 16;
      return {
        transform: `translateY(${startPosOfEventItem + paddingTop}px)`,
        height: `${heightOfEventItem}px`,
        backgroundColor: `${backgroundColorOfEvent}`,
      };
    };

    const styleHrLines = {
      marginBottom: `${SLOT_CONFIG.heightPerThirtyMins - 1}px`,
    };

    const AppointmentsView = (
      <div ref={this.viewRef} className="appointment">
        <ul className="views">
          {this.getAppointmentsBySelectedDate().map((event, i) => (
            <div
              key={i}
              className="event-view"
              style={styleEvent(event)}
              onClick={(e) => this.onClickAppointment(e, event)}
            >
              <span>{event.testDrive ? event.customer.firstName.length + event.customer.lastName.length > 20 ? event.customer.firstName.concat(" ").concat(event.customer.lastName).substring(0, 17) + "..." : event.customer.firstName.concat(" ").concat(event.customer.lastName) : event.subject.length > 20 ? event.subject.substring(0, 17) + "..." : event.subject}</span>
            </div>
          ))}

          {slots().map((i) => (
            <li key={i} className="hr-line" style={styleHrLines}>
              <span>{i}</span>
            </li>
          ))}
        </ul>
      </div>
    );

    return (
      <React.Fragment>
        <div className="appointments" ref={this.appointmentsRef}>
          {AppointmentsView}
        </div>
        <AppointmentView continueFlow={this.props.continueFlow} open={this.state.openAppView} appView={this.appView}/>
      </React.Fragment>
    );
  }
}
