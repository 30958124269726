import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Sorting from '../../assets/icons/bmw_apps_filter.svg';

export default function TableGrid(props) {
    const [sortingIndex, setSortingIndex] = useState(props.sortingIndex)
    

    const filter = (id) => {
        if(props.filterCallback !== undefined) {
            setSortingIndex(id)
            props.filterCallback(id)
        }
    }

    return (
        <TableContainer>
            <Table aria-label={props.aria}>
                {
                    props.headers !== undefined && props.headers.length > 0  ? 
                        <TableHead>
                          <TableRow>
                            {props.headers.map((h, id) => <TableCell key={h} onClick={() => {filter(id)}} style={{fontFamily: 'bmwnextbo', fontSize: 14, cursor: "pointer"}}>{h} {sortingIndex !== undefined && id === sortingIndex ? <img alt="" style={{position: 'relative', top: 5}} src={Sorting} height="20" width="20"></img> : ""}</TableCell>)}
                          </TableRow>
                        </TableHead>
                    : null
                }
                <TableBody>
                    {
                        props.collection.map((row, idx) => (
                            <TableRow key={"row" + idx}>
                                {row.map((item, idx) => {
                                    if (idx === 0) {
                                        return <TableCell style={{fontFamily: 'bmwnextli', fontSize: 14}} key={idx} component="th" scope="row">{item}</TableCell>
                                    } else {
                                        return <TableCell style={{fontFamily: 'bmwnextli', fontSize: 14}} key={idx} align="left">{item}</TableCell>
                                    }
                                })}
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer >
    );
}
