import React, { useEffect, useRef, useContext } from 'react';
import Item from './Item';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NavIcon from '../../assets/icons/menu48.png';
import LogoutIcon from '../../assets/icons/logout48.png';
import ProfileIcon from '../../assets/icons/profile48.png';
import CloseIcon from '../../assets/icons/closegrey48.png';
import BMWLogo from '../../assets/img/BMW_White_Logo.svg';
import LogoutIconOpen from '../../assets/icons/logoutneg48.png';
import ProfileIconOpen from '../../assets/icons/profileneg48.png';
import BMWLogoOpen from '../../assets/img/BMW_Grey_Logo.svg';
import StateManagementContext from '../../context/StateManagementContext';
import { isMobile, isTablet, isIPad13 } from 'react-device-detect';
import { slideInLeft, slideOutLeft } from 'react-animations';
import Radium, { StyleRoot, } from 'radium';
import { slide as Menu } from 'react-burger-menu'
import './header.css';

const styles = {
    slideInLeft: {
        animation: 'x .5s',
        animationName: Radium.keyframes(slideInLeft, 'slideInLeft')
    },
    slideOutLeft: {
        animation: 'x .3s',
        animationName: Radium.keyframes(slideOutLeft, 'slideOutLeft')
    }
}

export default function Header(props) {
    const xs = useMediaQuery(theme => theme.breakpoints.between(320, 767));
    const sm = useMediaQuery(theme => theme.breakpoints.between(768, 1100));
    const md = useMediaQuery(theme => theme.breakpoints.up(1024));
    const [open, setOpen] = React.useState(false)
    const [activeElement, setActiveElement] = React.useState('')
    const navRef = useRef(null);
    const state = useContext(StateManagementContext)

    const handleFullScreenOpen = (e) => {
        // if (sm) {
        //     let toRender = 'driversnote'
        //     if (!isMobile) {
        //         toRender = 'testcars'
        //     }
        //     props.handleOpenWindow(open ? false : true)
        //     setOpen(open ? false : true)
        //     setActiveElement(toRender)
        //     props.render(toRender)
        // }

        if (xs) {
            if (!open) {
                props.handleOpenWindow(true)
                setOpen(true)
            } else {
                setActiveElement('')
                props.handleOpenWindow(false)
                setOpen(false)
                props.render('')
            }
        }
    }

    const handleOpen = (e) => {
        const id = e.target.id
        if (activeElement === '' || activeElement !== id || xs || !props.handleHeaderOpen) {
            setOpen(true)
            props.handleOpenWindow(true)
            setActiveElement(id)
            props.render(id)
        }

        if (id === activeElement && !xs && props.handleHeaderOpen) {
            if (!xs) {
                setOpen(false)
            }

            props.handleOpenWindow(false)
            props.render('')
            setActiveElement('')
        }

    }

    useEffect(() => {
        if (!isMobile) {
            if (!props.handleHeaderOpen) {
                setActiveElement('')
            }
            setOpen(props.handleHeaderOpen)
        }
        if (isTablet) {
            setOpen(props.handleHeaderOpen)

        }
    }, [props])

    if (md || sm) {
        return (
            <React.Fragment>
                <div ref={navRef} className={open ? "navbar navbar-open" : "navbar"}>
                    {isMobile || isTablet || isIPad13 ?
                        <Item id="driversnote" active={activeElement} open={open} onClick={handleOpen} label="Opret køreseddel" />
                        : null}
                    <Item id="testcars" active={activeElement} open={open} onClick={handleOpen} label="BMW" />
                    <Item id="testcarsMini" active={activeElement} open={open} onClick={handleOpen} label="MINI" />
                    {state.currentUser.role === 'admin' && !isTablet && !isIPad13 ?
                        <Item id="admin" active={activeElement} open={open} onClick={handleOpen} label="Administrator" />
                        : null}
                    <div className="icongroup">
                        <Item onClick={props.logout} icon={true} label={open ? LogoutIconOpen : LogoutIcon} height={28} width={28} alt="logout" />
                        <Item id="profile" onClick={handleOpen} icon={true} label={open ? ProfileIconOpen : ProfileIcon} height={28} width={28} alt="logout" />
                    </div>
                </div>
                <div className="logo">
                    <a href="/workspace"><img className="bmwlogo" src={open ? BMWLogoOpen : BMWLogo} alt="logo" /></a>
                </div>

            </React.Fragment>
        )
    } 
    // else if (sm) {
    //     return (
    //         <React.Fragment>
    //             <div className={open ? "navbar navbar-open" : "navbar"}>
    //                 <Item onClick={handleFullScreenOpen} icon={true} label={open ? CloseIcon : NavIcon} height={28} width={28} alt="nav" />
    //                 {open ?
    //                     <div className="navbar-center">
    //                         {isMobile || isTablet || isIPad13 ?
    //                             <Item id="driversnote" active={activeElement} open={open} onClick={handleOpen} label="Opret køreseddel" />
    //                             : null}
    //                         <Item id="testcars" active={activeElement} open={open} onClick={handleOpen} label="BMW" />
    //                         <Item id="testcarsMini" active={activeElement} open={open} onClick={handleOpen} label="MINI" />
    //                         {state.currentUser.role === 'admin' && !isTablet ?
    //                             <Item id="admin" active={activeElement} open={open} onClick={handleOpen} label="Administrator" />
    //                             : null}
    //                         <Item id="profile" active={activeElement} open={open} onClick={handleOpen} label="Profil" />
    //                     </div>
    //                     :
    //                     <div className="icongroup">
    //                         <Item onClick={props.logout} icon={true} label={open ? LogoutIconOpen : LogoutIcon} height={28} width={28} alt="logout" />
    //                         <Item id="profile" onClick={handleOpen} icon={true} label={open ? ProfileIconOpen : ProfileIcon} height={28} width={28} alt="logout" />
    //                     </div>
    //                 }
    //             </div>
    //             <div className="logo">
    //                 <a href="/workspace"><img src={BMWLogo} alt="logo" /></a>
    //             </div>
    //         </React.Fragment>
    //     )
    // } 
    else {
        return (
            <React.Fragment>
                <Menu width={'95vw'} {...props}>
                    <Item id="testcars" endArrow={true} active={activeElement} open={open} onClick={handleOpen} label="BMW" />
                    <Item id="testcarsMini" endArrow={true} active={activeElement} open={open} onClick={handleOpen} label="MINI" />
                    {isMobile || isTablet || isIPad13 ?
                        <Item id="driversnote" endArrow={true} active={activeElement} open={open} onClick={handleOpen} label="Opret køreseddel" />
                        : null}
                    <Item style={{ width: 35, position: 'fixed', left: 0, bottom: 0, marginLeft: 40}} id="logout" icon={true} active={activeElement} label={LogoutIconOpen} height={28} width={28} open={open} onClick={props.logout} />
                    <Item style={{ width: 35, position: 'fixed', left: 0, bottom: 0, marginLeft: 90}} id="profile" icon={true} active={activeElement} label={ProfileIconOpen} height={28} width={28} open={open} onClick={handleOpen} />
                </Menu>
                <div className="logo">
                    <a href="/workspace"><img src={BMWLogo} alt="logo" height="40px" width="40px" /></a>
                </div>
            </React.Fragment>
        )
        // return (
        //     <React.Fragment>
        //         {!open ?
        //             <div style={{ marginTop: 10, marginLeft: 20, position: "relative", zIndex: 10 }}>
        //                 <Item onClick={handleFullScreenOpen} icon={true} label={open ? CloseIcon : NavIcon} height={28} width={28} alt="nav" />
        //             </div> : null}
        //         <StyleRoot>
        //             <div ref={navRef} style={open ? styles.slideInLeft : styles.slideOutLeft} className={open ? "navbar navbar-open" : "navbar"}>
        //                 <div style={{ marginTop: 10 }}>
        //                     <Item onClick={handleFullScreenOpen} icon={true} label={open ? CloseIcon : NavIcon} height={28} width={28} alt="nav" />
        //                 </div>
        //                 {open ?
        //                     <div>
        //                         <div className="item-mobile">
        //                             <Item id="testcars" endArrow={true} active={activeElement} open={open} onClick={handleOpen} label="BMW" />
        //                             <Item id="testcarsMini" endArrow={true} active={activeElement} open={open} onClick={handleOpen} label="MINI" />
        //                             {isMobile || isTablet ?
        //                                 <div><Item id="driversnote" endArrow={true} active={activeElement} open={open} onClick={handleOpen} label="Opret køreseddel" /></div>
        //                                 : null}
        //                         </div>
        //                         <div className="mobile-footer">
        //                             <Item style={{ width: 35 }} id="logout" icon={true} active={activeElement} label={open ? LogoutIconOpen : LogoutIcon} height={28} width={28} open={open} onClick={props.logout} />
        //                             <Item style={{ width: 35 }} id="profile" icon={true} active={activeElement} label={open ? ProfileIconOpen : ProfileIcon} height={28} width={28} open={open} onClick={handleOpen} />
        //                         </div>
        //                     </div>
        //                     : null}


        //             </div>
        //         </StyleRoot>
        //         <div className="logo">
        //             <a href="/workspace"><img src={BMWLogo} alt="logo" height="40px" width="40px" /></a>
        //         </div>
        //     </React.Fragment>
        // )
    }
}