import React from 'react'
import './views.css'

export default function Terms(props) {
    return (
        <div id="main-terms">
            <div id="terms-container">
                <div className="terms-header">
                    SAMTYKKE:
                <hr />
                </div>
                <div className="terms-content">
                Jan Nygaard A/S vil bruge de oplysninger, du giver på denne formular, til at holde kontakt med dig og til at levere nyheder og opdateringer omkring vores produkter og tjenester.
                <br/><br/>
                Vi bruger følgende kanaler til at sende information; Email, Brev, Telefon & Beskedtjenester.
                <br/><br/>
                Du kan når som helst skifte mening ved at klikke på afmeldingslinket i sidefoden på enhver e-mail, du modtager fra os, eller ved at kontakte os på info@jan-nygaard.dk. Vi behandler dine oplysninger med respekt.
                <br/><br/>
                For mere information om vores privatlivspraksis, besøg vores websted. Ved at klikke på ovenstående accepterer du, at vi kan behandle dine oplysninger i overensstemmelse med disse vilkår. 
                </div>
                <div className="terms-header">
                    INDHENTNING AF PERSONDATA:
                <hr />
                </div>
                <div className="terms-content">
                    Vil du give samtykke til indhentning af adresse- og navneoplysninger fra CPR-registreret til brug for udarbejdelse af den lovpligtige dokumentation* i forbindelse med din prøvekørsel?
                    <br /><br />
                    De indhentede adresse- og navneoplysninger vil blive opbevaret i henhold til følgende bestemmelser:
                    <br />
                    <ul>
                        <li>
                            Hvis køretøjet er monteret med prøveskilte; 90 dage jf. LBK nr. 223 af 27/02/2019 § 7 d. Stk. 3.
                        </li>
                        <li>
                            Hvis køretøjet er indregistreret i Danmark; 5 år jf. BEK nr. 463 af 02/05/2013 § 6.
                        </li>
                    </ul>
                    Det anvendte CPR-nummer vil udelukkende blive brugt med henblik på indhentning af oplysninger fra CPR-registreret og vil ikke blive opbevaret efter indhentningen har fundet sted eller senest 3 min. efter denne.
                    <br/><br/>
                    *jf. § 7 d. Stk. 3 i LBK nr. 223 af 27/02/2019 eller jf. § 5 i BEK nr. 463 af 02/05/2013.
                </div>
                <div className="terms-header">
                    BRUGSBETINGELSER:
                <hr />
                </div>
                <div className="terms-content">
                1) føreren (herefter kaldet låner) er forpligtet til at tilbagelevere demobilen (herefter kaldet køretøjet) samt alt dertilhørende udstyr til aftalt tid og sted, som anført på køresedlen. Køresedlen omfatter kun det anførte tidsrum og kan kun forlænges ved skriftlig bekræftelse fra Jan Nygaard AS (herefter kaldet udlåner). Undladelse af at aflevere køretøjet til aftalt tid og sted falder ind under straffelovens § 293 (tyveri af køretøjet).
                 <br/><br/>
                2) Køretøjet må kun føres af låner og ekstra førere, som er påført køresedlen.
                 <br/><br/>
                3) Udlåner er ikke ansvarlig for private genstande, som bliver beskadiget, tabt eller glemt under eller efter prøvekørslen.
                 <br/><br/>
                5) Låner er bekendt og indforstået med, at der kan være monteret sporingsudstyr i køretøjet.
                 <br/><br/>
                6) Låner anerkender udtrykkeligt sit personlige ansvar for på begæring, til udlejer at betale:
                 <br/><br/>
                a. Alle bøder, afgifter, inkasso- og rets omkostninger i forbindelse med parkerings- eller lovgivningsmæssige overtrædelser, som pålægges køretøjet eller dennes fører indenfor låneperioden. Låner er fuldt ansvarlig for betaling til den pågældende myndighed eller virksomhed hvis eller når de forespørger betaling. For at undgå risiko for at sådanne bøder eller afgifter eskalerer, kan udlåner vælge at betale myndigheden eller selskabet. I nogle tilfælde bliver bøder eller afgifter fremsendt direkte til udlåner, som er forpligtet til betaling af disse og udlåner vil i sådanne tilfælde viderefakturere omkostningen direkte til låner. Låner er i sådanne tilfælde ansvarlig for bødens omkostning samt et administrationsgebyr på 250 kr. inkl. moms.
                 <br/><br/>
                7) Rygning er forbudt i alle Jan Nygaard A/S biler og såfremt der er brug for luftfjernelse på grund af rygning, opkræves låner et gebyr på DKK 2.500 inkl. moms.
                <br/><br/>
                8) Låner skal altid føre køretøjet ansvars- og hensynsfuldt og følge alle regler, love og anbefalinger fra myndighederne. Udlåner kan drage låner til ansvar for eventuelle omkostninger forbundet med ekstraordinært slid eller direkte beskadigelse af køretøjet, som følge af vanvidskørsel eller væsentlig tilsidesættelse af færdselslovens bestemmelser.
                 <br/><br/>
                9)  Låner vil blive opkrævet selvrisikoen ved skader opstået i forbindelse med prøvekørsel og afregnes iht. gældende lov (maksimalt DKK 5.000). Selvrisikoen beregnes pr. forsikringsbegivenhed. 
                </div>
            </div>
        </div>
    )
}