import { Grid, CircularProgress } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import StateManagementContext from '../../context/StateManagementContext'
import './overview.css'
import CustomTextField from '../customComponents/CustomTextField';
import SearchIcon from '../../assets/icons/bmw_apps_search.svg';
import TableGrid from '../utilComponents/TableGrid';
import facade from '../../datafacade/datafacade';
import Mobile from '../../assets/icons/mobile.svg';
import NoMobile from '../../assets/icons/nomobile.svg';
import License from '../../assets/icons/bmw_car_driving-license.svg';
import NoLicense from '../../assets/icons/bmw_apps_close.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import { isIPad13, isTablet } from 'react-device-detect';
import Menu from '../utilComponents/Menu';
import { NOTE_URL } from '../../helpers/Helpers';
import PDFIcon from '../../assets/icons/pdf48grey.png';



function formattedDate(d1, d2) {
    let month = String(d1.getMonth() + 1);
    let day = String(d1.getDate());
    const year = String(d1.getFullYear());
    const start = d1.toLocaleTimeString().substr(0, d1.toLocaleTimeString().lastIndexOf("."))
    const end = d2.toLocaleTimeString().substr(0, d2.toLocaleTimeString().lastIndexOf("."))
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return `${day}/${month}/${year} kl ${start} - kl. ${end}`;
}

function formattedSingleDate(d1) {
    let month = String(d1.getMonth() + 1);
    let day = String(d1.getDate());
    const year = String(d1.getFullYear());
    const start = d1.toLocaleTimeString().substr(0, d1.toLocaleTimeString().lastIndexOf("."))
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return `${day}/${month}/${year} kl ${start}`;
}

export default function Overview(props) {
    const state = useContext(StateManagementContext)
    const [curUserDep, setCurUserDep] = useState(null)
    const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"))
    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState(false)
    const [timerId, setTimerId] = useState(null)
    const [data, setData] = useState([])
    const TABLE_HEADERS = ["Oprettet", "", "Status", "Sælger", "Kunde", "Tidspunkt", "Model", "Nummerplade", "Telefon", ""]
    const TABLET_TABLE_HEADERS = ["Oprettet", "", "Status", "Sælger", "Kunde", "Tidspunkt", "Model", "Nummerplade", "Telefon", ""]
    const STATUSES = ["Aktiv", "Afsluttet", "Afventer", "Annulleret"]
    const STATUSES_COLORS = { "Aktiv": "blue", "Afsluttet": "green", "Afventer": "yellow", "Annulleret": "red" }
    const [bookings, setBookings] = React.useState([])
    const tablet = useMediaQuery(theme => theme.breakpoints.between(768, 1100));

    const getDepartmentNameForCurrentUser = () => {
        if (state.currentUser.id !== undefined) {
            setCurUserDep(state.currentUser)
        }
    }

    function sortByDate(a, b) {
        var dateA = new Date(a.startTime).getTime();
        var dateB = new Date(b.startTime).getTime();
        return dateA < dateB ? -1 : 1;
    };

    function sortByCreatedAt(a, b) {
        var dateA = new Date(a.createdAt).getTime();
        var dateB = new Date(b.createdAt).getTime();
        return dateA < dateB ? -1 : 1;
    };

    const fetchBookings = async () => {
        if (curUserDep !== null) {
            let b = await facade.getFutureBookingsFromDate(moment().set({ hour: 0, minute: 0, second: 0 }).subtract(1, 'days').valueOf(), curUserDep.departmentId)
            b.sort(sortByDate)
            setBookings(b)
        }
    }

    const bookingsBySelectedDate = (bookings) => {
        const sortedByDate = bookings.filter(b => {
            const startTime = moment(new Date(b.startTime))
            const end = moment(selectedDate, "YYYY-MM-DD").add(1, "days")
            if (startTime.isAfter(moment(selectedDate, "YYYY-MM-DD")) && startTime.isBefore(end)) return b
        })
        return sortedByDate
    }

    const handleDateChange = (e) => {
        const s = moment(e.target.value, "YYYY-MM-DD")
        const today = moment().set({ hour: 0, minute: 0, second: 0 })
        if (s.isAfter(today)) {
            setSelectedDate(e.target.value)
        }
        else {
            setSelectedDate(moment().format("YYYY-MM-DD"))
        }
    };

    const handleStatusChange = async (status, id) => {
        const res = await facade.updateBookingStatus(id, status)
        return res.status
    }

    const onChange = (e) => {
        setLoading(true)
        setSearch(e.target.value)
        let val = e.target.value
        if (val.length === 0) {
            setData(generateData(bookingsBySelectedDate(bookings)))
            setLoading(false)
        } else {
            let collection = bookings.filter(v => {
                if (v.customer !== undefined) {
                    if (
                        String(v.subject).toLowerCase().includes(val.toLowerCase()) ||
                        String(v.customer.firstName + " " + v.customer.lastName).toLowerCase().includes(val.toLowerCase()) ||
                        String(formattedDate(new Date(v.startTime), new Date(v.endTime))).toLowerCase().includes(val.toLowerCase()) ||
                        String(v.car.manufacturer + " " + v.car.model + " " + v.car.variant + " " + v.car.equipment).toLowerCase().includes(val.toLowerCase()) ||
                        String(v.car.licensePlate).toLowerCase().includes(val.toLowerCase())
                    ) {
                        return v
                    }
                } else if (v.user !== undefined) {
                    if (
                        String(v.subject).toLowerCase().includes(val.toLowerCase()) ||
                        String(formattedDate(new Date(v.startTime), new Date(v.endTime))).toLowerCase().includes(val.toLowerCase()) ||
                        String(v.car.manufacturer + " " + v.car.model + " " + v.car.variant + " " + v.car.equipment).toLowerCase().includes(val.toLowerCase()) ||
                        String(v.car.licensePlate).toLowerCase().includes(val.toLowerCase()) ||
                        String(v.user.firstName + " " + v.user.lastName).toLowerCase().includes(val.toLowerCase())
                    ) {
                        return v
                    }
                } else {
                    if (
                        String(v.subject).toLowerCase().includes(val.toLowerCase()) ||
                        String(formattedDate(new Date(v.startTime), new Date(v.endTime))).toLowerCase().includes(val.toLowerCase()) ||
                        String(v.car.manufacturer + " " + v.car.model + " " + v.car.variant + " " + v.car.equipment).toLowerCase().includes(val.toLowerCase()) ||
                        String(v.car.licensePlate).toLowerCase().includes(val.toLowerCase())
                    ) {
                        return v
                    }
                }
            });
            setData(generateData(collection))
            setLoading(false)
        }
    }

    const generateData = (bookings) => {
        let collection = bookings.map(b => {
            if (isIPad13 || isTablet) {
                return [
                    <div className={`circle ${STATUSES_COLORS[b.status]}`} />,
                    <Menu key={b.status} statuses={STATUSES} label={b.status} callback={handleStatusChange} id={b.id} />,
                    b.user !== undefined ? b.external ? `(Eksternt) ${b.user.initials}` : `${b.user.initials}` : 'Eksternt',
                    b.customer !== undefined ? b.customer.firstName + " " + b.customer.lastName : b.subject === "" ? "" : b.subject,
                    `${formattedDate(new Date(b.startTime), new Date(b.endTime))}`,
                    `${b.car.manufacturer === undefined ? "" : b.car.manufacturer} ${b.car.model === undefined ? "" : b.car.model} ${b.car.variant === undefined ? "" : b.car.variant} ${b.car.equipment === undefined ? "" : b.car.equipment}`,
                    b.car.licensePlate === undefined || b.car.licensePlate === "" ? b.testPlate : b.car.licensePlate,
                    b.customer !== undefined ? b.customer.phone : "",
                    <a href={NOTE_URL + b.path} target="_blank" rel="noopener noreferrer"><img id={b.id} alt="" style={{ cursor: 'pointer' }} src={PDFIcon} height="28" width="28"></img></a>
                ]
            } else {
                return [
                    `${formattedSingleDate(new Date(b.createdAt))}`,
                    <div className={`circle ${STATUSES_COLORS[b.status]}`} />,
                    <Menu key={b.status} statuses={STATUSES} label={b.status} callback={handleStatusChange} id={b.id} />,
                    b.user !== undefined ? b.external ? `(Eksternt) ${b.user.firstName} ${b.user.lastName}` : `${b.user.firstName} ${b.user.lastName}` : 'Eksternt',
                    b.customer !== undefined ? b.customer.firstName + " " + b.customer.lastName : b.subject === "" ? "" : b.subject,
                    `${formattedDate(new Date(b.startTime), new Date(b.endTime))}`,
                    `${b.car.manufacturer === undefined ? "" : b.car.manufacturer} ${b.car.model === undefined ? "" : b.car.model} ${b.car.variant === undefined ? "" : b.car.variant} ${b.car.equipment === undefined ? "" : b.car.equipment}`,
                    b.car.licensePlate === undefined || b.car.licensePlate === "" ? b.testPlate : b.car.licensePlate,
                    b.customer !== undefined ? b.customer.phone : "",
                    <a href={NOTE_URL + b.path} target="_blank" rel="noopener noreferrer"><img id={b.id} alt="" style={{ cursor: 'pointer' }} src={PDFIcon} height="28" width="28"></img></a>
                ]
            }
        })
        return collection
    }

    const dataBasedOnDate = () => {
        setLoading(true)
        setData(generateData(bookingsBySelectedDate(bookings)))
        setLoading(false)
    }

    const filterCallback = (id) => {
        let bookingsBeforeSort = bookingsBySelectedDate(bookings)
        switch (id) {
            case 0:
                bookingsBeforeSort.sort(sortByCreatedAt)
                break;
            case 1:
                bookingsBeforeSort.sort((a, b) => {
                    return a.status < b.status ? -1 : 1
                })
                break;
            case 2:
                bookingsBeforeSort.sort((a, b) => {
                    return a.status < b.status ? -1 : 1
                })
                break;
            case 3:
                bookingsBeforeSort.sort((a, b) => {
                    if (a.user !== undefined && b.user !== undefined) {
                        return a.user.firstName < b.user.firstName ? -1 : 1
                    } else {
                        return (a.user === undefined) - (b.user === undefined)
                    }
                })
                break;
            case 4:
                bookingsBeforeSort.sort((a, b) => {
                    if (a.customer !== undefined && b.customer !== undefined) {
                        return a.customer.firstName < b.customer.firstName ? -1 : 1
                    } else {
                        return (a.customer === undefined) - (b.customer === undefined)
                    }
                })
                break;
            case 5:
                bookingsBeforeSort.sort(sortByDate)
                break;
            case 6:
                bookingsBeforeSort.sort((a, b) => {
                    return a.car.model < b.car.model ? -1 : 1
                })
                break;
            case 7:
                bookingsBeforeSort.sort((a, b) => {
                    if (a.car.licensePlate !== "" && b.car.licensePlate !== "") {
                        return a.car.licensePlate < b.car.licensePlate ? -1 : 1
                    } else {
                        return (a.car.licensePlate === "") - (b.car.licensePlate === "")
                    }
                })
                break;
            case 8:
                bookingsBeforeSort.sort((a, b) => {
                    if (a.customer !== undefined && b.customer !== undefined) {
                        return a.customer.isSubscribed > b.customer.isSubscribed ? -1 : 1
                    } else {
                        return (a.customer === undefined) - (b.customer === undefined)
                    }
                })
                break;
            case 9:
                bookingsBeforeSort.sort((a, b) => {
                    if (a.customer !== undefined && b.customer !== undefined) {
                        return a.customer.personalData > b.customer.personalData ? -1 : 1
                    } else {
                        return (a.customer === undefined) - (b.customer === undefined)
                    }
                })
                break;
            default:
        }
        setData(generateData(bookingsBeforeSort))
    }

    useEffect(() => {
        if (curUserDep === null) {
            getDepartmentNameForCurrentUser()
        }
    }, [state.currentUser])

    useEffect(() => {
        fetchBookings()
        if (timerId === null && curUserDep !== null) {
            setTimerId(setInterval(() => {
                fetchBookings()
            }, 10000))
        }
    }, [curUserDep])

    useEffect(() => {
        dataBasedOnDate()
    }, [selectedDate, bookings])


    return (
        <div>
            {curUserDep === null ? null :
                <Grid container>
                    <Grid item xs={12}><div id="overview-title">Fremtidige prøvekørsler</div></Grid>
                    <Grid item xs={6}><div id="overview-dep">{curUserDep.city}</div></Grid>
                    <Grid item xs={3} style={{ textAlign: 'right' }}><CustomTextField style={{ width: '180px', marginTop: -20 }} name="search" onChange={onChange} value={search} label={<img alt="search" height="20" width="20" src={SearchIcon}></img>} /></Grid>
                    <Grid item xs={3} style={{ textAlign: 'right' }}><CustomTextField style={{ width: '180px', marginTop: -20 }} type="date" value={selectedDate} onChange={handleDateChange} label="Dato" /></Grid>
                    {loading ?
                        <Grid item xs={12} style={{ marginTop: 50, textAlign: "center" }}>
                            <CircularProgress />
                        </Grid>
                        :
                        <Grid item xs={12} style={{ paddingTop: 32 }}>
                            <div id="data-table">
                                {data.length > 0 ? <TableGrid aria="bookings table" sortingIndex={2} filterCallback={filterCallback} headers={tablet ? TABLET_TABLE_HEADERS : TABLE_HEADERS} collection={data} /> : null}
                            </div>
                        </Grid>}
                </Grid>}
        </div>
    )
}