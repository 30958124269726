import React from 'react'

export default function Field(props) {
    return (
        <div className="field" style={{ backgroundColor: props.backgroundColor }}>
            <table className="field-table">
                <tbody>
                    <tr>
                        <td><div className="field-icon" style={{paddingTop: props.padding, paddingBottom: props.padding}}><img src={props.icon} alt="icon" height="24" width="24" /></div></td>
                        <td><div className="field-children">{props.children}</div></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}