import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import BackIcon from '../../assets/icons/BackWhite.svg';
import Logo from '../../assets/img/BMW_White_Logo.svg';
import '../css/mainCss.css';
import TestCars from '../components/TestCarsExternal';
import { useLocation } from 'react-router-dom';
import { fadeIn } from 'react-animations';
import Radium, { StyleRoot } from 'radium';


const styles = {
    fadeIn: {
        animation: 'x 2s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn')
    },
}


const useStyles = makeStyles((theme) => ({
    '@global': {
        body: {
            'background': `url(https://tds.jan-nygaard.dk/td/images/external.png) no-repeat center center fixed`,
            '-webkit-background-size': 'cover',
            '-moz-background-size': 'cover',
            '-o-background-size': 'cover',
            'background-size': 'cover',
            'overflow': 'hidden'
        }
    },
    backLinkText: {
        position: 'relative',
        top: -6,
        left: 10,
    },
}));


export default function ExternalMain(props) {
    const [animationDone, setAnimationDone] = useState(false)

    const location = useLocation();
    const [mounted, setMounted] = useState(false)

    const classes = useStyles(props)

    

    useEffect(() => {
        if (mounted) {
            setTimeout(() => {
                setAnimationDone(true)
            }, 3000)
        }
    }, [mounted])

    useEffect(() => {
        window.addEventListener('load', handleLoad);
        setTimeout(() => {
            if (!mounted) setMounted(true)
        }, 500)
    }, [])

    const handleLoad = () => {
        setMounted(true)
    }



    const goBack = () => {
        window.open("https://www.jan-nygaard.dk/", "_self");
    }


    return (
        <React.Fragment>
            <div id="back-link" onClick={goBack}>
                <img id="back-icon" src={BackIcon} alt="back-icon" height="25" width="25" />
                <span id="back-text" className={classes.backLinkText}>Jan Nygaard AS</span>
            </div>
            <div id="logo-external">
                <img id="logo-icon-external" src={Logo} alt="back-icon" />
            </div>
            {mounted ?
                <div style={{ animation: "mainAnimation 3s ease 0s normal none" }} id="main">
                    <div id="tc">
                        {animationDone ?
                            <StyleRoot>
                                <div style={styles.fadeIn}>
                                    <TestCars departments={props.departments} cars={props.cars} seriesIndex={props.seriesIndex} external={true} />
                                </div>
                            </StyleRoot>
                            :
                            null}
                    </div>
                </div>
                : null}
        </React.Fragment>
    )
}