import React, { useState, useEffect, useRef } from 'react';
import './utilCss.css'

export default function IconButton(props) {
    const [hover, setHover] = useState(false)
    const iconRef = useRef(null)

    const onHover = e => {
        setHover(true)
    }

    const onLeave = e => {
        setHover(false)
    }


    return (
        <div ref={iconRef} onClick={props.onClick}>
            <div><img onMouseEnter={onHover} onMouseLeave={onLeave} style={Object.assign({ cursor: 'pointer' })} alt="button" src={hover ? props.hoverIcon : props.icon} width={props.width} height={props.height} /><span style={{ position: 'relative', top: '-.5em', left: '.7em', fontFamily: 'bmwnextbo', cursor: "pointer" }}>{props.title}</span></div>
        </div>
    )
}