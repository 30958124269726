import React from 'react';
import '../admin/adminpanel.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Users from './users/Users';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Departments from './departments/Departments';
import Cars from './cars/Cars';
import Bookings from './bookings/Bookings';
import Statistic from './stats/Statistic';

const useStyles = makeStyles(theme => ({
    divider: {
        marginTop: -16,
        marginBottom: 30
    }
}));

export default function (props) {
    const xs = useMediaQuery(theme => theme.breakpoints.between(320, 767));
    const childComponents = {
        'users': <Users />,
        'departments': <Departments />,
        'cars': <Cars />,
        'customers': <Bookings />,
        'stats': <Statistic />
    }
    const classes = useStyles();
    const [componentToRender, setComponentToRender] = React.useState(childComponents.users)
    const [activeElement, setActiveElement] = React.useState('users')

    const handleClick = (e) => {
        const id = e.target.id
        setActiveElement(id)
        setComponentToRender(childComponents[id])
    }

    return (

        <React.Fragment>
            {xs ?
                <div className="admin-content">
                    {componentToRender}
                </div>
                :
                <div>
                    <div className="adminpanel-nav">
                        <div id="users" style={{fontFamily: 'bmwnextbo'}} onClick={handleClick} className={activeElement === 'users' ? 'admin-item admin-item-selected' : 'admin-item'}>Brugere</div>
                        <div id="departments" style={{fontFamily: 'bmwnextbo'}} onClick={handleClick} className={activeElement === 'departments' ? 'admin-item admin-item-selected' : 'admin-item'}>Afdelinger</div>
                        <div id="cars" style={{fontFamily: 'bmwnextbo'}} onClick={handleClick} className={activeElement === 'cars' ? 'admin-item admin-item-selected' : 'admin-item'}>Flåde</div>
                        <div id="customers" style={{fontFamily: 'bmwnextbo'}} onClick={handleClick} className={activeElement === 'customers' ? 'admin-item admin-item-selected' : 'admin-item'}>Historik</div>
                        {
                            <div id="stats" style={{fontFamily: 'bmwnextbo'}} onClick={handleClick} className={activeElement === 'stats' ? 'admin-item admin-item-selected' : 'admin-item'}>Statistik</div>
                        }
                    </div>
                    <div className="admin-content">
                        <Divider className={classes.divider} />{componentToRender}
                    </div>
                </div>
            }
        </React.Fragment>
    )
}