import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import datafacade from '../../datafacade/datafacade';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
  },
  select: {
    '&:before': {
      borderColor: 'var(--galaxy-blue)',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderColor: 'var(--galaxy-blue)',
    }
  },
}));

export default function DepartmentSelector(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [departmentId, setDepartmentId] = React.useState("")
  const [data, setData] = React.useState([])

  async function fetchDepartments() {
    const response = await datafacade.getAllDepartments()
    setData(response)
  }

  useEffect(() => {
    fetchDepartments()
  }, []);

  const handleChange = e => {
    const dep = e.target.value
    setDepartmentId(dep)
    props.setDep(dep)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <FormControl className={classes.formControl} error={props.departmentError}>
        <InputLabel>Afdeling</InputLabel>
        <Select
          className={classes.select}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={departmentId}
          onChange={handleChange}
          required
          fullWidth
        >
          {data.map(d =>
            <MenuItem value={d.id} key={d.id}>{d.id} ({d.departmentName})</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
}
