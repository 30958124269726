import React, { useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { Portal } from '@material-ui/core';
import StateManagementContext from '../../context/StateManagementContext';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function CustomizedSnackbars() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const state = useContext(StateManagementContext)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        if (state.messageBox.show) {
            setOpen(true)
            setTimeout(() => {state.createMessageBox({show: false, msg: "", variant: ''})}, 6000)
        }
    }, [state.messageBox])

    return (
        <Portal>
            <div className={classes.root}>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={state.messageBox.variant}>
                        {state.messageBox.msg}
                    </Alert>
                </Snackbar>
            </div>
        </Portal>
    );
}
