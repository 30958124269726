import React from "react";
import CarIcon from '../../../../assets/icons/viewAppointmentIcons/car.svg'
import CustomerIcon from '../../../../assets/icons/viewAppointmentIcons/customer.svg'
import SourceIcon from '../../../../assets/icons/viewAppointmentIcons/source.svg'
import CalendarIcon from '../../../../assets/icons/viewAppointmentIcons/calendar.svg'
import BackCalendar from "../subcomponents/button/BackCalendar";
import moment from "moment";
import { connect } from "react-redux";
import { setPageID } from "../../store/actions";
import StateManagementContext from '../../../../context/StateManagementContext';
import { toISOLocal, NOTE_URL } from '../../../../helpers/Helpers';
import { isMobile, isTablet, isIPad13 } from 'react-device-detect';
import { formatDate } from '../utils/index';
import Facade from '../../../../datafacade/datafacade';
import {
  PAGE_INDEX,
  DATE_FORMAT,
} from "../constant";
import CustomButton from '../../../customComponents/CustomButton';
import CustomButtonTrans from '../../../customComponents/CustomButtonTrans';
import Field from "../subcomponents/calendar/Field";

class ViewAppointment extends React.Component {
  static contextType = StateManagementContext
  state = {
    inputType: "text",
    viewTimeChange: false,
    start: "",
    end: "",
    subject: "",
    pickerError: false,
    subjectError: false,
    noHeightDiffer: true,
    fetchError: false,
    editingMode: false
  };

  convertInputType = () => {
    this.setState({ inputType: "datetime-local" });
  };

  onClickChangeTime = () => {
    this.setState({ viewTimeChange: true });
  };

  onClickCloseTime = async () => {
    const stateContext = this.context
    let event = stateContext.currentEvent
    let start = moment(this.state.start, "YYYY-MM-DDTHH:mm").valueOf() + 1
    let end = moment(this.state.end, "YYYY-MM-DDTHH:mm").valueOf()
    delete event.signature
    if (start >= end) {
      this.setState({ pickerError: true })
    } else if (!event.testDrive && this.state.subject === "") {
      this.setState({ subjectError: true })
    } else {
      event.start = start
      event.end = end
      if (!event.testDrive) {
        event.subject = this.state.subject
      }
      event.user.department = { id: event.user.departmentId }
      event.car.department = { id: event.car.departmentId }
      event.car = { id: event.car.id }
      try {
        const booking = await Facade.updateBooking(event)
        stateContext.updateBooking(booking)
        stateContext.setCurrentEvent(booking)
        this.setState({ viewTimeChange: false, inputType: "text", editingMode: false });
      } catch (err) {
        this.setState({ fetchError: true })
      }
    }
  };

  componentDidMount = () => {
    const stateContext = this.context
    const event = stateContext.currentEvent
    const start = toISOLocal(new Date(event.startTime)).substr(0, 16)
    const end = toISOLocal(new Date(event.endTime)).substr(0, 16)
    const subject = event.subject
    this.setState({ start, end, subject })
  }

  onChangeDate = (e) => {
    const { name, value } = e.target
    if (this.state.pickerError || this.state.subjectError) this.setState({ pickerError: false, subjectError: false })
    this.setState({ [name]: value })
  }

  openChangeBooking = (e) => {
    this.onClickChangeTime()
  }

  deleteEvent = async (e) => {
    const stateContext = this.context
    const event = stateContext.currentEvent
    const b = { id: event.id }
    try {
      stateContext.removeEvent(event)
      if (this.props.desktop) {
        this.props.onClose()
      } else {
        this.props.onBackDayView()
      }
      await Facade.deleteBooking(b)
    } catch (err) {
    }
  }

  cancelBooking = async (e) => {
    const stateContext = this.context
    const event = stateContext.currentEvent
    try {
      stateContext.removeEvent(event)
      const e = await Facade.cancelBooking(event.id)
      stateContext.updateBooking(e)
      if (this.props.desktop) {
        this.props.onClose()
      } else {
        this.props.onBackDayView()
      }
    } catch (err) {
    }
  }

  continueFlow = () => {
    const stateContext = this.context
    stateContext.flowSetDepartment({ id: stateContext.currentEvent.department.id })
    stateContext.flowSetCustomer(stateContext.currentEvent.customer)
    stateContext.flowSetCar({ id: stateContext.currentEvent.car.id })
    stateContext.flowSetTestDrive(true)
    stateContext.flowSetSignature(stateContext.currentEvent.signature)
    stateContext.flowSetTime(new Date(this.state.start).getTime(), new Date(this.state.end).getTime())
    stateContext.flowSetId(stateContext.currentEvent.id)
    stateContext.flowSetToFinish(true)
    const car = stateContext.flow.car
    let passTerms = false
    if(stateContext.flow.customer.termsOfUse !== undefined) {
      if(stateContext.flow.customer.termsOfUse === true) {
        passTerms = true
      }
    }
    if (isTablet || isIPad13) {
      this.props.continueFlow()
    } else {
      this.props.showStepper(true)
      if(passTerms) {
        this.props.setCurrentStep(2)
        this.props.setStepper(2)
        this.props.onContinueFlowNoTerms(car.licensePlate === "" || !car.isRegistered && car.licensePlate === "")
      } else {
        this.props.setCurrentStep(1)
        this.props.setStepper(1)
        this.props.onContinueFlow(car.licensePlate === "" || !car.isRegistered && car.licensePlate === "")
      }
    }
  }

  onEditing = (edit) => {
    this.setState({ editingMode: edit })
  }

  onChange = (e) => {
    const { name, value } = e.target
    this.setState({ fetchError: false })
    this.setState({ [name]: value })
  }




  render() {
    const stateContext = this.context
    const event = stateContext.currentEvent

    const date = new Date(event.startTime)
    const end = new Date(event.endTime)
    const mon = moment(date, DATE_FORMAT).month();
    let wk = moment(date, DATE_FORMAT).day();
    const day = moment(date, DATE_FORMAT).date();
    const yr = moment(date, DATE_FORMAT).year();
    const from = toISOLocal(date).split("T")[1].substr(0, 5)
    const to = toISOLocal(end).split("T")[1].substr(0, 5)
    const now = moment().valueOf()
    let beforeNow = false
    let isNow = true

    if (end.getTime() <= now) {
      beforeNow = true
    }

    if (now >= date.getTime() && now <= end.getTime()) {
      isNow = false
    }


    const HeaderNotFinished = (
      <>
        {event.testDrive ? <BackCalendar onClick={() => { this.props.desktop ? this.props.onClose() : this.props.onBackDayView() }} background="linear-gradient(to right, #0066B1, #0066B1 33.33%, #003D78 33.33%, #003D78 66.66%, transparent 66.66%)" title={event.customer.firstName.length + event.customer.lastName.length > 20 ? event.customer.firstName.concat(" ").concat(event.customer.lastName).substring(0, 17) + "..." : `${event.customer.firstName} ${event.customer.lastName}`} /> : null}
      </>
    )

    const HeaderFinished = (
      <>
        {event.testDrive ? <BackCalendar onClick={() => { this.props.desktop ? this.props.onClose() : this.props.onBackDayView() }} background="linear-gradient(to right, #0066B1, #0066B1 33.33%, #003D78 33.33%, #003D78 66.66%, #E22718 66.66%)" title={event.customer.firstName.length + event.customer.lastName.length > 20 ? event.customer.firstName.concat(" ").concat(event.customer.lastName).substring(0, 17) + "..." : `${event.customer.firstName} ${event.customer.lastName}`} /> : null}
      </>
    )

    const HeaderNotTestDrive = (
      <>
        {!event.testDrive && this.state.subject !== undefined && event.subject !== undefined ? <BackCalendar onClick={() => { this.props.desktop ? this.props.onClose() : this.props.onBackDayView() }} background="#E22718" title={this.state.editingMode ? this.state.subject === "" ? "Emne" : this.state.subject.length > 20 ? this.state.subject.substring(0, 17) + "..." : this.state.subject : event.subject.length > 20 ? event.subject.substring(0, 17) + "..." : event.subject} /> : null}
      </>
    )

    const MainViewTestDrive = (
      event.testDrive ? <>
        <div style={{ marginTop: 10 }}>
          <Field icon={CustomerIcon} backgroundColor="#FFFFFF">
            <div>{`${event.customer.firstName} ${event.customer.lastName}`}</div>
            <div>{event.customer.phone}</div>
          </Field>
          <Field icon={CalendarIcon} backgroundColor="#E6E6E6">
            {this.state.editingMode ?
              <div>
                <div style={this.state.fetchError ? { color: '#E22718' } : null}>Start: <input style={this.state.fetchError ? { color: '#E22718' } : null} onBlur={() => window.scroll(0, 0)} name="start" className="calendar-datepicker" type="datetime-local" onChange={this.onChange} value={this.state.start} /></div>
                <div style={this.state.fetchError ? { color: '#E22718' } : null}>Slut: <input style={this.state.fetchError ? { color: '#E22718' } : null} onBlur={() => window.scroll(0, 0)} name="end" className="calendar-datepicker" type="datetime-local" onChange={this.onChange} value={this.state.end} /></div>
              </div>
              :
              <div>
                <div>{formatDate(yr, mon, wk, day)}</div>
                <div>{`Fra kl. ${from} til kl. ${to}`}</div>
              </div>
            }
          </Field>
          <Field icon={CarIcon} backgroundColor="#FFFFFF">
            <div>{`${event.car.manufacturer} ${event.car.model} ${event.car.variant} ${event.car.equipment}`}</div>
            <div>{event.car.licensePlate}</div>
          </Field>
          <Field icon={SourceIcon} backgroundColor="#E6E6E6" padding={9}>
            {event.user !== undefined ? event.external ? <div>{`(Eksternt) ${event.user.firstName} ${event.user.lastName}`}</div> : <div>{`${event.user.firstName} ${event.user.lastName}`}</div> : <div>Eksternt</div>}
          </Field>
        </div>
      </> : null
    );

    const MainViewNotTestDrive = (
      !event.testDrive && event.car !== undefined ? <>
        <div style={{ marginTop: 25 }}>
          {this.state.editingMode ?
            <textarea style={{ marginBottom: 10 }} maxLength={145} placeholder="Emne tekst" onBlur={() => window.scroll(0, 0)} name="subject" onChange={this.onChange} value={this.state.subject} className="subject-textarea" rows={3} cols={35} />
            : <div style={{ fontFamily: 'bmwnextbo', width: 310, marginLeft: 5 }}>
              {event.subject}
            </div>
          }
          <Field icon={CalendarIcon} backgroundColor="#FFFFFF">
            {this.state.editingMode ?
              <div>
                <div style={this.state.fetchError ? { color: '#E22718' } : null}>Start: <input style={this.state.fetchError ? { color: '#E22718' } : null} onBlur={() => window.scroll(0, 0)} name="start" className="calendar-datepicker" type="datetime-local" onChange={this.onChange} value={this.state.start} /></div>
                <div style={this.state.fetchError ? { color: '#E22718' } : null}>Slut: <input style={this.state.fetchError ? { color: '#E22718' } : null} onBlur={() => window.scroll(0, 0)} name="end" className="calendar-datepicker" type="datetime-local" onChange={this.onChange} value={this.state.end} /></div>
              </div>
              :
              <div>
                <div>{formatDate(yr, mon, wk, day)}</div>
                <div>{`Fra kl. ${from} til kl. ${to}`}</div>
              </div>
            }
          </Field>
          <Field icon={CarIcon} backgroundColor="#E6E6E6">
            <div>{`${event.car.manufacturer} ${event.car.model} ${event.car.variant} ${event.car.equipment}`}</div>
            <div>{event.car.licensePlate}</div>
          </Field>
          <Field icon={SourceIcon} backgroundColor="#FFFFFF" padding={9}>
            <div>{`${event.user.firstName} ${event.user.lastName}`}</div>
          </Field>
        </div>
      </> : null
    );

    const ButtonViewNotFinished = (
      <>
        <div className="calendar-btn-group">
          {isMobile || isTablet || isIPad13 ?
            <div style={{ marginTop: 40 }} className="calendar-btn top-btn">
              <CustomButton style={{ width: 155, height: 70 }} disabled={beforeNow} onClick={this.continueFlow}>Færdiggør oprettelse</CustomButton>
              <CustomButtonTrans style={{ width: 155, height: 70, marginLeft: 15, border: '2px solid' }} disabled={beforeNow} onClick={this.deleteEvent}>Slet prøvekørsel</CustomButtonTrans>
            </div>
            : <div className="calendar-btn"><CustomButtonTrans style={{ width: 327, border: '2px solid' }} disabled={beforeNow} onClick={this.deleteEvent}>Slet prøvekørsel</CustomButtonTrans></div>}
        </div>
      </>
    )

    const ButtonViewFinished = (
      <>
        <div className="calendar-btn-group">
          <div style={{ marginTop: 20 }} className="calendar-btn top-btn">
            <CustomButtonTrans href={NOTE_URL + event.path} target="_blank" rel="noopener noreferrer" style={{ width: 155, height: 70, marginRight: 7, border: '2px solid' }}>Åben køreseddel</CustomButtonTrans>
            <CustomButtonTrans disabled={beforeNow} style={{ width: 155, height: 70, marginLeft: 7, border: '2px solid' }}
              onClick={() => {
                if (this.state.editingMode) {
                  this.onClickCloseTime()
                } else {
                  this.onEditing(true)
                }
              }
              }>{this.state.editingMode ? "Gem" : "Skift tidspunkt"}</CustomButtonTrans>
          </div>
          <div className="calendar-btn"><CustomButtonTrans disabled={isNow} style={{ width: 327, border: '2px solid' }} onClick={this.cancelBooking}>Afslut prøvekørsel</CustomButtonTrans></div>
          <div className="calendar-btn"><CustomButtonTrans disabled={beforeNow} style={{ width: 327, border: '2px solid' }} onClick={this.deleteEvent}>Slet prøvekørsel</CustomButtonTrans></div>
        </div>
      </>
    )

    const ButtonViewNotTestDrive = (
      <>
        <div className="calendar-btn-group">
          <div className="calendar-btn top-btn">
            {this.state.editingMode ?
              <div>
                <div style={{ marginTop: 40 }} className="calendar-btn top-btn">
                  <CustomButton
                    style={{ width: 155, height: 70 }}
                    onClick={() => {
                      this.onClickCloseTime()
                    }
                    }>Gem ændring</CustomButton>
                  <CustomButtonTrans style={{ width: 155, height: 70, marginLeft: 15, border: '2px solid' }} onClick={() => { this.onEditing(false) }}>Annuller</CustomButtonTrans></div>
              </div>
              :
              <div>
                <CustomButtonTrans disabled={beforeNow} style={{ width: 155, height: 70, marginRight: 7, border: '2px solid' }} onClick={() => { this.onEditing(true) }}>Rediger aftale</CustomButtonTrans>
                <CustomButtonTrans disabled={beforeNow} style={{ width: 155, height: 70, marginLeft: 7, border: '2px solid' }} onClick={this.deleteEvent}>Slet aftale</CustomButtonTrans>
              </div>
            }
          </div>
        </div>
      </>
    )

    return (
      <div className="p-4">
        {event.testDrive  ? (event.finished ? HeaderFinished : HeaderNotFinished) : HeaderNotTestDrive
        }
        <div className="testdrive-main-view">
          {event.testDrive ? MainViewTestDrive : MainViewNotTestDrive
          }
          {event.testDrive  ? (event.finished ? ButtonViewFinished : ButtonViewNotFinished) : ButtonViewNotTestDrive
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedDate: state.selectedDate,
    pageID: state.pageID
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onBackDayView: () => dispatch(setPageID(PAGE_INDEX.DAY_VIEW_1_2)),
    onContinueFlow: (testPlate) => {
      if (testPlate) {
        dispatch(setPageID(PAGE_INDEX.CHOOSE_LICENSE_PLATE))
      } else {
        dispatch(setPageID(PAGE_INDEX.TERMS_AND_CONDITIONS))
      }

    },
    onContinueFlowNoTerms: (testPlate) => {
      if (testPlate) {
        dispatch(setPageID(PAGE_INDEX.CHOOSE_LICENSE_PLATE))
      } else {
        dispatch(setPageID(PAGE_INDEX.PICTURE))
      }

    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAppointment);
