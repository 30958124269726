import React from "react";
import CustomButton from '../../../customComponents/CustomButton';
import Facade from '../../../../datafacade/datafacade';
import StateManagementContext from '../../../../context/StateManagementContext';
import { setPageID } from "../../store/actions";
import { connect } from "react-redux";
import {
  PAGE_INDEX,
  DESCRIPTIONS
} from "../constant";
import { CircularProgress } from "@material-ui/core";
import CustomButtonTrans from "../../../customComponents/CustomButtonTrans";
import ReactGA from 'react-ga';

class Success extends React.Component {
  static contextType = StateManagementContext
  state = {
    mode: "",
    success: null
  }

  componentDidMount = async () => {
    this.props.setHideNav(true)
    const stateContext = this.context
    if (stateContext.external) stateContext.flowSetFinished(false)
    else stateContext.flowSetFinished(true)
    if (stateContext.flow.toFinish) {
      try {
        delete stateContext.flow.selectedTime
        delete stateContext.flow.toFinish
        // delete stateContext.flow.user
        const booking = await Facade.finishBooking(stateContext.flow)
        stateContext.addBooking(booking)
        stateContext.flowClear()
        this.props.setTempHeader("Bekræftelse")
        this.setState({ mode: "car" })
        this.setState({ success: true })
        stateContext.setChooseNewTime(false)
      } catch (err) {
        this.props.setTempHeader("Beklager")
        this.setState({ mode: "carfailure" })
        this.setState({ success: false })
        stateContext.setChooseNewTime(true)
      }
    } else {
      try {
        const booking = await Facade.addBooking(stateContext.flow)
        stateContext.addBooking(booking)
        stateContext.flowClear()
        this.props.setTempHeader("Bekræftelse")
        this.setState({ mode: "car" })
        this.setState({ success: true })
        stateContext.setChooseNewTime(false)
        this.sendGAEvent()
      } catch (err) {
        this.props.setTempHeader("Beklager")
        this.setState({ mode: "carfailure" })
        this.setState({ success: false })
        stateContext.setChooseNewTime(true)
      }
    }
  }

  onNewTime = () => {
    this.props.setHideNav(false)
    this.props.onClickTimeFail()
    this.props.setStepper(0)
    this.props.setCurrentStep(0)
  }

  sendGAEvent = () => {
    try {
      //GAEvent
      ReactGA.initialize('GTM-KNXFV2Z');
      ReactGA.event({
        category: 'TD',
        action: 'New booking action',
        label: 'New booking label',
        nonInteraction: true
      });
    } catch (e) {}
  }

  render() {
    const stateContext = this.context
    const DescriptionView = (
      <div className="p-5 main-cal text-center" style={{ marginTop: 30 }}>
        {this.state.success ?
          <div style={{ fontSize: 16, fontFamily: "bmwnextli" }}>{stateContext.external ? ["Alt er klar og det sidste vi mangler er, at aflæse dit kørekort.", <br />, "Alt det finder vi ud af når du ankommer til prøvekørslen.", <br />, <br />, "Vi glæder os til at tage imod dig!"].map((t, id) => <React.Fragment key={t + id}>{t}</React.Fragment>) : DESCRIPTIONS.success}</div>
          :
          <div>
            <div style={{ fontSize: 16, fontFamily: 'bmwnextli' }}>{DESCRIPTIONS.error}</div>
            <div style={{ fontSize: 16, fontFamily: 'bmwnextbo', marginTop: 30 }}>{DESCRIPTIONS.chooseNewTime}</div>
          </div>
        }

      </div>
    );

    return (
      <div className="p-4 done-view" style={{ textAlign: 'center' }}>
        {this.state.success === null ?
          <div style={{ textAlign: "center", marginTop: 150 }}>
            <CircularProgress color="secondary" />
          </div>
          :
          <div>
            <div className="main-cal">
              {DescriptionView}
              {
                this.state.success ?
                  <React.Fragment>
                    <div style={{ marginTop: stateContext.external ? 40 : 130 }}>
                      <CustomButton onClick={this.props.close}>Luk</CustomButton>
                    </div>
                  </React.Fragment>
                  :
                  <div style={{ marginTop: 30 }}>
                    <CustomButton onClick={this.onNewTime}>Nyt tidspunkt</CustomButton>
                    <div style={{ marginTop: 15 }}>
                      <CustomButtonTrans onClick={this.props.close}>Luk</CustomButtonTrans>
                    </div>
                  </div>
              }
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClickTimeFail: () => dispatch(setPageID(PAGE_INDEX.DAY_VIEW_2_2))
  };
};

export default connect(null, mapDispatchToProps)(Success);
