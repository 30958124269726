import React, { useContext } from 'react';
import CustomButton from '../../customComponents/CustomButton'
import CustomButtonTrans from '../../customComponents/CustomButtonTrans'
import Dialog from '@material-ui/core/Dialog';
import datafacade from '../../../datafacade/datafacade';
import StateManagementContext from '../../../context/StateManagementContext';

export default function DeleteCarDialog(props) {
  const [open, setOpen] = React.useState(false);
  const state = useContext(StateManagementContext)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    datafacade.deleteDepartment(props.dep)
    props.handleClose()
    state.deleteDepartment(props.dep)
    handleClose()
  }

  return (
    <div>
      <CustomButton disabled={props.disable} fullWidth onClick={handleClickOpen}>
        Slet
      </CustomButton>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <div style={{ textAlign: "center", padding: 50, height: 460, width: 350 }}>
          <div style={{ fontFamily: 'bmwnextbo', fontSize: 22, padding: 10 }}>ADVARSEL!</div>
          <div style={{ padding: 8 }}>Du er ved at slette:</div>
          <div style={{ fontFamily: 'bmwnextbo', paddingBottom: 18 }}>{props.dep.departmentName}, {props.dep.city} afdelingen</div>
          <div style={{ paddingBottom: 22 }}>Alle brugere, biler og bookinger i denne afdeling vil gå tabt. Vil du fortsætte handlingen?</div>
          <div style={{ marginTop: 34 }}>
            <CustomButtonTrans onClick={handleClose} autoFocus>
              Fortryd
      </CustomButtonTrans>
            <div style={{ marginTop: 18 }}>
              <CustomButton onClick={handleDelete}>
                Slet
      </CustomButton>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
