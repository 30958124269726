import React, { useEffect } from 'react';
import CustomButton from '../../customComponents/CustomButton';
import CustomTextField from '../../customComponents/CustomTextField';
import { CircularProgress, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import DescriptionIcon from '@material-ui/icons/Description';
import moment from 'moment';
import facade from '../../../datafacade/datafacade';
import { CSVLink } from "react-csv";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
    const [open, setOpen] = React.useState(false);
    const [dates, setDates] = React.useState({
        startDate: moment().subtract(7, 'days').format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD")
    })
    const [data, setData] = React.useState([])
    const [disabled, setDisabled] = React.useState(true)
    const [fileName, setFileName] = React.useState(`TD-${dates.startDate}-${dates.endDate}.csv`)


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDownload = async () => {
        setDisabled(true)
        const csv = await facade.getTestdrivesAsCSV(moment(dates.startDate).valueOf(), moment(dates.endDate).add(1, 'days').valueOf())
        const d = csv.split('\n').map(e => {
            return e.replaceAll("\"", "").split(",")
        })
        setData(d)
        setFileName(`TD-${dates.startDate}-${dates.endDate}.csv`)
        setDisabled(false)
    }

    useEffect(() => {
        setDisabled(true)
        const start = moment(dates.startDate)
        const end = moment(dates.endDate).add(1, 'days')
        if(start.isBefore(end)) {
            handleDownload()
        }
    }, [dates])

    const handleDateChange = (e) => {
        const { name, value } = e.target
        setDates({
            ...dates,
            [name]: value
        })
    }

    return (
        <div>
            <IconButton aria-label="download" onClick={handleClickOpen}>
                <DescriptionIcon />
            </IconButton>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Exporter prøvekørsler til CSV"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={4}>
                        <Grid item xs={6}><CustomTextField style={{ width: '250px' }} type="date" name="startDate" value={dates.startDate} onChange={handleDateChange} label="Startdato" /></Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}><CustomTextField style={{ width: '250px' }} type="date" name="endDate" value={dates.endDate} onChange={handleDateChange} label="Slutdato" /></Grid>
                        <Grid item xs={6}></Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <CSVLink data={data} filename={fileName}>
                        <CustomButton disabled={disabled} width={200} height={40} color="primary">
                            Download
                        </CustomButton>
                    </CSVLink>
                </DialogActions>
            </Dialog>
        </div>
    );
}
