import React from "react";
import { connect } from "react-redux";
import { setPageID, setSignature } from "../../store/actions";
import { PAGE_INDEX, PAGE_INDEX_EXTERNAL } from "../constant";
import SignatureCanvas from "react-signature-canvas";
import CustomButton from '../../../customComponents/CustomButton';
import StateManagementContext from '../../../../context/StateManagementContext';
import { isTablet, isIPad13, isMobileOnly } from "react-device-detect";
import { Grid } from "@material-ui/core";

class Signature extends React.Component {
  static contextType = StateManagementContext
  state = {
    clientWidth: 0,
    disButton: true
  };

  componentDidMount() {
    this.updateClientRect();
    window.addEventListener("resize", this.updateClientRect);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateClientRect);
  }

  updateClientRect = () => {
    this.setState({
      clientWidth: window.innerWidth
    });
  };

  sigPad = {};

  clear = () => {
    this.sigPad.clear();
  };

  onBeginSign = () => {
    this.setState({ disButton: false })
  }

  onClickContinue = () => {
    const stateContext = this.context
    stateContext.flowSetSignature(this.sigPad.getTrimmedCanvas().toDataURL("image/png"))
    stateContext.flowSetFinished(true)
    if (isTablet || isIPad13) {
      if (stateContext.external) {
        this.props.setCurStep(4)
        this.props.setMaxStep(4)
      } else {
        this.props.setCurStep(5)
      }
    } else {
      if (stateContext.external) {
        this.props.setStepper(4)
        this.props.setCurrentStep(4)
        this.props.onClickContinueExternal();
      } else {
        this.props.setStepper(5)
        this.props.setCurrentStep(5)
        this.props.onClickContinue();
      }
    }
  };

  render() {

    const { clientWidth } = this.state;
    const stateContext = this.context
    const SignatureView = (
      <div className="signature-canvas">
        <SignatureCanvas
          penColor="black"
          canvasProps={{ width: isTablet || isIPad13 ? clientWidth - 200 : clientWidth, height: 260 }}
          onBegin={this.onBeginSign}
          ref={ref => {
            this.sigPad = ref;
          }}
        />
        <div style={{paddingLeft: 20, paddingRight: 20, paddingBottom: 5 }}>
          <hr/>
        </div>
      </div>
    );

    return (
      <div style={{ paddingTop: isTablet || isIPad13 ? 33 : 0 }}>
        <Grid container spacing={isTablet || isIPad13 ? 4 : 2} style={{ textAlign: "center" }}>
          <Grid item xs={12}>
            {SignatureView}
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: isIPad13 || isTablet ? 50 : "" }}>
            <CustomButton className={stateContext.iFrameLead ? "submit-testdrive-form" : ""} disabled={this.state.disButton} fullWidth onClick={this.onClickContinue}>
              Fortsæt
          </CustomButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

// <div className="w-100 vh-100 signature">
// {SignatureView}
// {ButtonView}
// </div>

const mapDispatchToProps = dispatch => {
  return {
    onClickContinue: () => dispatch(setPageID(PAGE_INDEX.DONE)),
    onClickContinueExternal: () => dispatch(setPageID(PAGE_INDEX_EXTERNAL.DONE))
  };
};

export default connect(null, mapDispatchToProps)(Signature);
